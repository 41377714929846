import React, { Component } from 'react';
import { Menu, Icon } from 'antd';
import Lang from '../global/language';
import { Router, Route, Link, withRouter, Redirect, Switch} from 'react-router-dom'
import Account from '../containers/system/account'
import Log from '../containers/system/log'
import Netif from '../containers/system/netif'
import Info from '../containers/system/info'
import AppGateway from '../containers/system/appGateway'
import License from '../containers/system/license'

class Main extends Component {
  componentDidMount() {}
  componentWillUnmount() {}
  state = {
    events: (
      this.props.history.location.pathname === '/controller/system/license'
      && this.props.history.location.hash === '#input'
    ) ? 'none' : 'auto',
  }
  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props.history
    if (location.pathname === '/controller/system/license' && location.hash === '#input') {
      if (this.state.events !== 'none') {
        this.setState({
          events: 'none',
        })
      }
    } else {
      if (this.state.events !== 'auto') {
        this.setState({
          events: 'auto',
        })
      }
    }
  }
  render () {
    const {match} = this.props
    const getHostname = () => {
      let path = this.props.location.pathname
      return path
    }
    return(
      <div style={{width: '100%',height: '100%',display:'flex',}}>
        <div style={{width: 250,}}>
          <Menu
            mode="inline"
            selectedKeys={[getHostname()]}
            style={{
              width: '240px',
              height: '100%',
              paddingTop: '10px',
              background: '#fff',
              pointerEvents: this.state.events
            }}
          >
            <Menu.ItemGroup title={
              <span style={{fontSize:'16px',}}>
                <Icon type="setting" style={{marginRight:'10px'}}/>
                <span>System</span>
              </span>
            }>
              <Menu.Item key={`${match.url}/info`} >
                <Link style={{display:'inline-block',fontSize:'14px',paddingLeft:'20px',}} to={`${match.url}/info`}>{Lang.system.l123}</Link>
              </Menu.Item>
              <Menu.Item key={`${match.url}/network`}>
                <Link style={{display:'inline-block',fontSize:'14px',paddingLeft:'20px',}} to={`${match.url}/network`}>{Lang.system.l124}</Link>
              </Menu.Item>
              <Menu.Item key={`${match.url}/account`}>
                <Link style={{display:'inline-block',fontSize:'14px',paddingLeft:'20px',}} to={`${match.url}/account`}>{Lang.system.l130}</Link>
              </Menu.Item>
              <Menu.Item key={`${match.url}/app-gateway`}>
                <Link style={{display:'inline-block',fontSize:'14px',paddingLeft:'20px',}} to={`${match.url}/app-gateway`}>App Gateway</Link>
              </Menu.Item>
              <Menu.Item key={`${match.url}/log`}>
                <Link style={{display:'inline-block',fontSize:'14px',paddingLeft:'20px',}} to={`${match.url}/log`}>
                  {Lang.system.l132}
                </Link>
              </Menu.Item>
              <Menu.Item key={`${match.url}/license`}>
                <Link style={{display:'inline-block',fontSize:'14px',paddingLeft:'20px',}} to={`${match.url}/license`}>
                  License
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            {/* <Menu.ItemGroup title={
              <span style={{fontSize: '16px'}}>
                <Icon style={{marginRight: '10px'}} type="apartment" />
                <span>{Lang.system.l125}</span>
              </span>
            }>
            </Menu.ItemGroup>
            <Menu.ItemGroup title={
              <span style={{fontSize:'16px',}}>
                <Icon  style={{marginRight:'10px'}} type="bar-chart"/>
                <span>{Lang.system.l129}</span>
              </span>
            }>
            </Menu.ItemGroup> */}
          </Menu>
        </div>
        <div className="system" style={{height: '100%',flexGrow:1, padding:'20px',position: 'relative',}}>
          <Switch>
            <Route path={`${match.url}/account`} component={Account}/>
            <Route path={`${match.url}/log`} component={Log}/>
            <Route path={`${match.url}/network`} component={Netif}/>
            <Route path={`${match.url}/info`} component={Info}/>
            <Route path={`${match.url}/app-gateway`} component={AppGateway}/>
            <Route path={`${match.url}/license`} component={License}/>
          </Switch>
        </div>
      </div>
    )
  }
}

export default Main;