import React, { Component } from 'react';
import { Layout, Button, Table, Popconfirm } from 'antd'
import Api from '../../global/api'
import SetSecurityGroup from '../../components/setSecurityGroup'
import Lang from '../../global/language'

class Main extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    group: null,
    groups: null,
    default: null,
  }
  cancel = () => {
    this.setState({
      group: null,
    })
  }
  save = (data) => {
    if (data === 'success') {
      this.securityGroups()
      this.setState({
        group: null,
      })
    }
  }
  securityGroups = () => {
    Api.get.securityGroups(response => {
      let defaultGroups = []
      this.setState({
        groups: response.security_groups.filter((ele, index) => {
          ele.key = index
          if (ele.id === '0') {
            defaultGroups = ele
          }
          return true
        }),
        default: defaultGroups,
      })
    })
  }
  componentDidMount() {
    this.securityGroups()
  }
  componentWillUnmount() {}
  setGroup = (type, edit) => {
    if (type === 'new') {
      this.setState({
        group: {
          edit: true,
          name: '',
          description: '',
          inbound: this.state.default.inbound_rules ? this.state.default.inbound_rules : [],
          outbound: this.state.default.outbound_rules ? this.state.default.outbound_rules : [],
        }
      })
    } else {
      const group = type
      this.setState({
        group: {
          edit: edit === undefined ? true : edit,
          id: group.id,
          name: group.name,
          description: group.description,
          inbound: group.inbound_rules ? group.inbound_rules : [],
          outbound: group.outbound_rules ? group.outbound_rules : [],
        }
      })
    }
  }
  delete = (id) => {
    Api.delete.securityGroups(id, response => {
      if (!response.err) {
        this.securityGroups()
      }
    })
  }
  render () {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        width: 160,
        render: (text, record) => {
          if (record.id === '0') {
            return <a href="javascript:;" onClick={() => this.setGroup(record, false)}>{Lang.system.l144}</a>
          } else {
            return <div>
              <a href="javascript:;" style={{marginRight: '20px',}} onClick={() => this.setGroup(record)}>Edit</a>
              <Popconfirm
                placement="topRight"
                title="Are you sure to delete this group?"
                onConfirm={() => this.delete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <a href="javascript:;">Delete</a>
              </Popconfirm>
            </div>
          }
        }
      }
    ]
    return(
      <Layout>
        <div style={{padding: "20px 10px 10px 10px"}}>
          <Button onClick={() => this.setGroup('new')}>Create Security Group</Button>
        </div>
          <Table 
            style = {{
              margin: '10px',
              background: '#fff',
            }}
            // bordered={true}
            columns={columns} 
            dataSource={this.state.groups} 
            pagination={false}
          />
        {this.state.group ? <SetSecurityGroup save={this.save} cancel={this.cancel} data={this.state.group}/>: null}
      </Layout>
    )
  }
}

export default Main;