import axios from './http'

const login = (params, callback) => {
  axios.post('/login', {
    username: params.username,
    password: params.password
  })
  .then(response => {
    const data = response.data
    callback(data)
  })
}
const logout = (params, callback) => {
  axios.post('/logout', {})
  .then(response => {
    const data = response.data
    callback(data)
  })
}
const version = (callback) => {
  axios.get('/version')
  .then(response => {
    const data = response.data
    callback(data)
  })
}
const edges = (callback) => {
  axios.get('/edges')
  .then(response => {
    const data = response.data
    callback(data)
  })
}
const tunnels = (params, callback) => {
  axios.get('/edges/'+params.id+'/tunnels', {params: {
    status: true
  }})
  .then(response => {
    const data = response.data
    callback(data)
  })
}
const delTunnel = (params, callback) => {
  axios.delete('/edges/'+params.edgeId+'/tunnels/' + params.id)
  .then(response => {
    const data = response.data
    callback(data)
  })
}
const newTunnel = (params, callback) => {
  axios.post('/edges/'+params.edgeId+'/tunnels', {
    name: params.name,
    password: params.password,
    ip: params.ip,
    port: params.port,
    enable: params.enable
  })
  .then(response => {
    const data = response.data
    callback(data)
  })
}
const setTunnel = (params, callback) => {
  axios.put('/edges/'+params.edgeId+'/tunnels/' + params.id, {
    name: params.name,
    password: params.password,
    ip: params.ip,
    port: params.port,
    enable: params.enable
  })
  .then(response => {
    const data = response.data
    callback(data)
  })
}
const strategy = (params, callback) => {
  axios.get('/edges/' + params.id + '/strategy/' + params.app)
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const setStrategy = (params, callback) => {
  axios.put('/edges/' + params.id + '/strategy/' + params.app, {
    strategy: params.strategy,
    time_schedule: params.time_schedule
  }).then(
    response => callback(response)
  ).catch(
    error => callback(error.response)
  )
}
const accounts = (callback) => {
  axios.get('/accounts')
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const traffic  = (params, callback) => {
  let body = {
    beg: params.beg,
    end: params.end,
    dir: params.dir,
    indicator: params.indicator,
    timegroup: params.timegroup
  }
  if (params.edge) {
    body.edge = params.edge
  }
  axios.get('/traffic/stat', {
    params: body,
    paramsSerializer: function(params) {
      const indicator = params.indicator.map(_=>`indicator=${_}`).join('&')
      let data = 'beg='+ params.beg + '&' + 'end=' + params.end + '&' + 'dir=' + params.dir + '&' + 'timegroup=' + params.timegroup + '&' + indicator
      if (params.edge) {
        data = data + '&' + 'edge=' + params.edge
      }
      return data
    },
  })
  .then(response => {
    let data = response.data
    callback(data)
  })
  .catch(error => {
    if (error.response) {
      let data = null
      callback(data)
    }
  })
}
const tunnelMode = (callback) => {
  axios.get('/tunnelmode')
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const setTunnelMode = (params, callback) => {
  axios.put('/tunnelmode', {
    tunnelmode: params.tunnelmode
  })
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const setEdge = (params, callback) => {
  const id = params.id
  delete params.id
  axios.put('/edges/' + id, params)
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const delAccount = (id, callback) => {
  axios.delete('/accounts/' + id)
  .then(response => {
    const data = response.data
    callback(data)
  })
}
const setAccount = (id, params, callback) => {
  axios.put('/accounts/' + id, params)
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const newAccount = (params, callback) => {
  axios.post('/accounts', {
    username: params.username,
    enable: params.enable,
    password: params.password,
    role: params.role,
    is_super: params.is_super
  })
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const logs = (params, callback) => {
  axios.get('/op_logs', {params: params})
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const logs2 = (params, callback) => {
  axios.get('/monitor_logs', {params: params})
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const time = (callback) => {
  axios.get('/time')
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const netif = (callback) => {
  axios.get('/mng_netif')
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const setNetif = (params, callback) => {
  axios.put('/mng_netif', params)
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const setPassword = (params, callback) => {
  axios.put('/password', params)
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const nvrmode = (callback) => {
  axios.get('/nvrmode/')
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const nvrmodeStatus = (id, callback) => {
  axios.get('/nvrmode/' + id)
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const setNvrmode = (params, callback) => {
  axios.put('/nvrmode', params)
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const bandwidth = (id, callback) => {
  axios.get('/edges/' + id + '/output_bandwidth')
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const setBandwidth = (params, callback) => {
  axios.put('/edges/' + params.id + '/output_bandwidth', {
    bandwidth: params.bandwidth,
  }).then(
    response => callback(response)
  ).catch(
    error => callback(error.response)
  )
}
const state = (callback) => {
  axios.get('/sys_stat')
  .then(response => {
    let data = response.data
    callback(data)
  })
}
const license = (callback) => {
  axios.get('/license').then(
    response => {
      callback(response.data)
    }
  )
}
const checkLicense = (params, callback) => {
  axios.post('/license_check', params).then(
    response => {
      callback(response.data)
    }
  ).catch(error => {
    callback(error.response)
  })
}
const setLicense = (params, callback) => {
  axios.put('/license', params).then(
    response => {
      callback(response.data)
    }
  )
}
const setWorkmode = (id, params, callback) => {
  axios.put('/edges/' + id + '/work_mode', params).then(
    response => {
      callback(response.data)
    }
  )
}
export default {
  login,
  logout,
  version,
  edges,
  tunnels,
  delTunnel,
  newTunnel,
  setTunnel,
  strategy,
  setStrategy,
  accounts,
  traffic,
  tunnelMode,
  setTunnelMode,
  setEdge,
  delAccount,
  setAccount,
  newAccount,
  logs,
  logs2,
  time,
  netif,
  setNetif,
  setPassword,
  nvrmode,
  nvrmodeStatus,
  setNvrmode,
  bandwidth,
  setBandwidth,
  state,
  license,
  checkLicense,
  setLicense,
  setWorkmode,
}
