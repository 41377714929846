import React, { Component } from 'react';
import withContext from '../../../common/context/withContext'

const defaultStyle = {
  position: 'relative',
  width: 140,
  minHeight: 48,
  borderRadius: 4,
  border: '1px solid #2088ff',
  background: 'rgba(32, 136, 255, 0.3)',
  cursor: 'pointer',
  userSelect: 'none',
  marginBottom: '10px'
}
const disableStyle = {
  position: 'relative',
  width: 140,
  minHeight: 48,
  borderRadius: 4,
  border: '1px solid #bfbfbf',
  background: 'rgba(191, 191, 191, 0.3)',
  cursor: 'no-drop',
  userSelect: 'none',
  marginBottom: '10px'
}
class Main extends Component {
  componentDidMount() {
  }
  componentWillUnmount() {}
  handleMouseDown = () => {
    if (this.props.data.disable) {
      return
    }
    this.props.setAddNode({
      id: this.props.data.id,
      sn: this.props.data.sn,
      name: this.props.data.name,
      data: {
        local_subnets: this.props.data.local_subnets,
        dns_server: this.props.data.dns_server,
        sg_id: this.props.data.sg_id,
      }
    })
    const fn = () => {
      setTimeout(()=>{
        this.props.setAddNode(null)
      }, 10)
      document.body.removeEventListener('mouseup', fn, false)
    }
    document.body.removeEventListener('mouseup', fn, false)
    document.body.addEventListener('mouseup', fn, false)
  }
  bindEvent() {
  }
  render () {
    const img = this.props.data.online 
      ? <img style={{width: 30,}} src={require('../../../assets/images/spoke-blue.svg')} alt=""/> 
      : <img style={{width: 30,}} src={require('../../../assets/images/spoke-gray.svg')} alt=""/> 
    return(
      <div
        style={this.props.data.disable ? disableStyle : defaultStyle}
        onMouseDown={this.handleMouseDown}
      >
        <div style={{
          position: "absolute",
          display: 'flex',
          alignItems: 'center',
          left: 10,
          right: 10,
          top: 0,
          bottom: 0,
        }}>
          {img}
          <div style={{flexGrow: 1,textAlign: 'center'}}>
            {this.props.data.name}
          </div>
        </div>
      </div>
    )
  }
}

export default withContext(Main);