import React, { Component } from 'react';
import { Modal, Tabs, Form, Spin, Input, Table, Button, Divider, Popover, Switch, Tag, Icon } from 'antd'
import SetRule from './setRule'
import Api from '../../../global/api'

const TabPhane = Tabs.TabPane
const EgressType = ['Local', 'Remote', 'Custom']
class Main extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.setting) {
    }
    if (this.props.topology.id === nextProps.topology.id && JSON.stringify(nextState) === JSON.stringify(this.state)) {
      return false
    }
    return true
  }
  componentDidMount() {
    this.init()
  }
  componentWillUnmount() {}
  handleOk = () => {
    this.props.form.validateFields(async (errors, values) => {
      if (!!errors) {
        return
      }
      const {setting} = this.state
      let response = null
      response = await Api.put.internet_access(this.props.topology.id, setting)
      if (!!response) {
        this.init(response)
      }
    })
  }
  handleCancel = () => {
    this.props.cancel()
  }
  apply = () => {
    Api.post.internet_access(this.props.topology.id)
  }
  ruleSetting = (index) => {
    const { polices } = this.state.setting
    if (index === null) {
      index = polices.length
    }
    let i = index
    let data = polices[index] || {}
    data.index = i
    this.setState({
      rule: {
        data: data,
        visible: true,
      }
    })
  }
  onRef = (ref) => {
    this.child =ref
  }
  init = async (value) => {
    let data = {}
    if (value) {
      data = value
    } else {
      data = await Api.get.internet_access(this.props.topology.id) || {}
    }
    let setting = {
      enable: data.enable || false,
      polices: data.polices || []
    }
    let edgesTotal = {}
    let edges = {} 
    this.props.edges.map(edge => {
      edgesTotal[edge.sn] = edge
    })
    this.props.topology.nodes.map(edge => {
      if (edge.kind === 0) {
        edges[edge.edge_sn] = {
          sn: edge.edge_sn,
          name: edgesTotal[edge.edge_sn].name,
        }
      }
    })
    let settingOld = {
      enable: setting.enable,
      polices: {},
    }
    setting.polices.map(ele => {
      settingOld.polices[ele.id] = ele
    })
    this.edges = edges
    this.valueChange = {
      enable: false,
      table: false,
    } 
    this.setState({
      setting: setting,
      settingOld: settingOld,
      buttonType: 'apply',
    })
  }
  onChangeEnable = (value) => {
    const setting = { ...this.state.setting }
    setting.enable = value
    this.setState({
      setting: setting,
    }, () => {
      this.changeButtomMode('enable', null)
    })
  }
  changeEdge = (type, index, edges) => {
    if (type === 'delete') {
    }
    if (type === 'change') {
      const { polices } = this.state.setting
      const edgesObject = {}
      edges.map(edge => {
        edgesObject[edge] = edge
      })
      const newPolices = polices.map((strategy, i) => {
        if (i !== index) {
          strategy.nodes = strategy.nodes || []
          strategy.nodes = strategy.nodes.filter(id => {
            if (edgesObject[id]) {
              return false
            }
            return true
          })
        }
        return strategy
      })
      this.setState({
        setting: {
          ...this.state.setting,
          polices: newPolices,
        }
      })
    }
  }
  state = {
    rule: {
      visible: false,
      data: null,
    },
    buttonType: 'apply',
  }
  saveRule = (data) => {
    const { polices } = this.state.setting
    const index = data.index 
    delete data.index
    polices[index] = data
    this.setState({
      setting: {
        ...this.state.setting,
        polices: polices,
      }
    }, () => {
      this.changeEdge('change', index, data.nodes)
      this.changeButtomMode('table')
      this.closeRule()
    })
  }
  closeRule = () => {
    this.setState({
      rule: {
        visible: false,
        data: null,
      }
    })
  }
  deleteRlue = (index) => {
    const polices = [...this.state.setting.polices]
    polices.splice(index, 1)
    this.setState({
      setting: {
        ...this.state.setting,
        polices: polices,
      }
    }, () => {
      this.changeButtomMode('table')
    })
  }
  changeButtomMode = (type) => {
    const { setting } = this.state
    const { settingOld } = this.state
    if (type === 'enable') {
      if (setting.enable !== settingOld.enable) {
        this.valueChange.enable = true
      } else {
        this.valueChange.enable = false
      }
    }
    if (type === 'table') {
      if (setting.polices.length !== Object.keys(settingOld.polices).length) {
        this.valueChange.table = true
      } else {
        this.valueChange.table = false
        for (let index = 0; index < setting.polices.length; index++) {
          const strategy = setting.polices[index];
          const strategyOld = settingOld.polices[strategy.id]
          if (strategy.name !== strategyOld.name) {
            this.valueChange.table = true
            break
          }
          if (strategy.default_egress.type !== strategyOld.default_egress.type) {
            this.valueChange.table = true
            break
          }
          if (this.isDiffArray(strategy.nodes, strategyOld.nodes)) {
            this.valueChange.table = true
            break
          }
          if (!strategy.specific_egresses && !strategyOld.specific_egresses) {
            continue
          } else if (!strategy.specific_egresses || !strategy.specific_egresses) {
            this.valueChange.table = true
            break
          } else if (!strategy.specific_egresses[0].enable && !strategyOld.specific_egresses[0].enable) {
            continue
          } else if (!strategy.specific_egresses[0].enable || !strategyOld.specific_egresses[0].enable) {
            this.valueChange.table = true
            break
          }
          if (strategy.specific_egresses[0].dns !== strategyOld.specific_egresses[0].dns) {
            this.valueChange.table = true
            break
          }
          if (this.isDiffArray(strategy.specific_egresses[0].apps, strategyOld.specific_egresses[0].apps)) {
            this.valueChange.table = true
            break
          }
        }
      }
    }
    this.setState({
      buttonType: this.valueChange.enable || this.valueChange.table ? 'save' : 'apply',
    })
  }
  isDiffArray = (arr1, arr2) => {
    arr1 = arr1 || []
    arr2 = arr2 || []
    let isDiff = false
    if (arr1.length !== arr2.length) {
      isDiff = true
    } else {
      for (let index = 0; index < arr1.length; index++) {
        const ele = arr1[index];
        if (arr2.indexOf(ele) === -1) {
          isDiff = true
          break
        }
      }
    }
    return isDiff
  }
  render () {
    const formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 20 },
        sm: { span: 10 },
      },
    };
    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        editable: true,
        textWrap: 'ellipsis',
      },
      {
        title: 'Nodes',
        dataIndex: 'nodes',
        editable: true,
        render: (text, record, index) => {
          const nodes = text || []
          return(
            nodes.map((node, i) => {
              return <Tag color="blue" key={i}>
                {this.edges[node].name}
              </Tag>
            })
          )
        }
      },
      {
        title: 'Default Egress',
        dataIndex: 'default_egress',
        textWrap: 'ellipsis',
        editable: true,
        render: text => <span>{EgressType[text.type]}</span> 
      },
      {
        title: 'Specific Egresses',
        dataIndex: 'specific_egresses',
        textWrap: 'ellipsis',
        editable: true,
        render: value => {
          const str = !value || !value[0].enable ? '' : (<Icon style={{color:"#26ae85"}} type="check" />)
          return(str)
        }
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        width: 120,
        render: (text, record, index) => {
          return (
            <div>
              <a href="javascript:;" onClick={() => this.ruleSetting(index)}>Edit</a>
              <Divider type="vertical" />
              <a href="javascript:;" onClick={() => this.deleteRlue(index)}>Delete</a>
            </div>
          )
        }
      },
    ]
    return(
      <Modal
        title="Internet Access Setting"
        maskClosable={false}
        width={800}
        visible={true}
        destroyOnClose={true}
        footer={null}
        onCancel={this.handleCancel}
      >
        <Form {...formItemLayout}>
          {/* {this.state.id ? <Form.Item label="ID">
            {this.state.id}
          </Form.Item> : null} */}
          <Form.Item label="Enable">
            {this.state.setting ? <Switch defaultChecked={this.state.setting.enable} onChange={value => this.onChangeEnable(value)}/> : null }
          </Form.Item>
        </Form>
        <div style={{position:'relative',}}>
          <Button disabled={this.state.setting ? !this.state.setting.enable : true} onClick={() => this.ruleSetting(null)} type="primary" style={{zIndex:3,position:'absolute',top:'2px',left: '100px'}}>Add Strategy</Button>
          <Tabs type="card">
            <TabPhane key="1" tab="Strategy" forceRender={true}>
              <Table
                style={{margin: '-16px 0 16px 0',minHeight: 300,}}
                columns={this.columns}
                dataSource={this.state.setting ? this.state.setting.polices : []}
                size="middle"
                loading={{
                  indicator: <Spin style={{display: 'none'}}/>,
                  spinning: this.state.setting ? !this.state.setting.enable : true,
                }}
                pagination={false}
                onHeaderRow={()=>{
                  return {
                    style: {
                      background: '#fff',
                    }
                  }
                }}
              >
              </Table>
            </TabPhane >
          </Tabs>
        </div>
        <div style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }}>
            <Button onClick={this.handleCancel} >Cancel</Button>
            <Button style={{marginLeft: 20}} onClick={this.handleOk} disabled={!(this.state.buttonType ==='save')} type="primary" >Save</Button>
            <Button style={{marginLeft: 20}} onClick={this.apply} disabled={!(this.state.buttonType ==='apply')} type="primary" >Apply</Button>
        </div>
        {this.state.rule.visible ? <SetRule edges={this.edges} data={this.state.rule.data} save={this.saveRule} cancel={this.closeRule}/> : null}
      </Modal>
    )
  }
}
const MainForm = Form.create()(Main)
export default MainForm;