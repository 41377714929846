import '../../style/system.css';
import React from 'react';
import { Input, Form, Modal, Row, Col, Button, InputNumber, Switch } from 'antd'
import Api from '../../global/systemApi'
import NewApi from '../../global/api'
import Method from '../../global/method'

const Component = React.Component
const createForm = Form.create;
const FormItem = Form.Item;
class Main extends Component {
  state = {
    read: {},
    edit: {
      loading: false,
    },
    visible: false,
    edgesNum: null,
  }
  componentDidMount() {
    this.init()
  }
  init = () => {
    this.license()
    this.edges()
  }
  license = () => {
    Api.license(response => {
      this.setState({
        visible: false,
        read: {
          machineId: response.machine_id,
          license: response.license,
          expire: response.expire,
          edgesCnt: response.edges_cnt,
          r2tpSupport: response.r2tp_support,
          realMachineId: response.real_machine_id,
        }
      })
      this.props.form.setFields({
        readLicense: {value: response.license},
      }) 
    })
  }
  edges = () => {
    NewApi.get.edges(response => {
      let num = 0
      response.edges.map(ele => {
        if (ele.is_managed) {
          num += 1
        }
      })
      this.setState({
        edgesNum: num,
      })
    })
  }
  showAddLicense = () => {
    this.setState({
      edit: {
        step: 0,
        loading: false,
        license: '',
      },
      visible: true,
    })
  }
  setNet = () => {
    this.props.form.validateFields((errors, values) => {
      if (!!errors) {
        return;
      }
      Api.put.gateway({
        url: values.protocol + '://' + values.host
      }, response=>{
        this.props.location.changeURL()
      })
    })
  }
  Modal = () => {
    return(
      <Modal
          visible={this.state.visible}
          title="Add New License Key"
          onCancel={()=>this.setState({visible: false})}
          footer={[
            (this.state.edit.step === 0
              ? <Button key="0" type="primary" disabled={!this.state.edit.license} loading={this.state.edit.loading} onClick={this.next}>
                  Next
                </Button>
              : <Button key="0" type="primary" onClick={this.warning}>
                  Add
                </Button>
            ),
            <Button key="1" onClick={()=>this.setState({visible: false})}>
              Cancel
            </Button>,
          ]}
        >
          {this.state.edit.step === 0 ? this.Step() : null}
          {this.state.edit.step === 1 ? this.Step1() : null}
        </Modal>
    )
  }
  Step = () => {
    const { getFieldProps, getFieldValue } = this.props.form;
    const editLicense = getFieldProps('editLicense', {
      rules: [
        { required: true, message: '' },
      ],
    })
    return(
      <div>
        <Form>
          <Input.TextArea {...editLicense} rows={6} onChange={this.editLicenseValue} value={this.state.edit.license}/>
        </Form>
      </div>
    )
  }
  Step1 = () => {
    const {edit} = this.state
    return(
      <Row gutter={24}>
        <div style={{padding: '8px 0',overflow:'hidden'}}>
          <Col style={{textAlign:'right'}} span={8}>Machine Code</Col>
          <Col span={16}>{edit.machineId}</Col>
        </div>
        <div style={{padding: '8px 0',overflow:'hidden'}}>
          <Col style={{textAlign:'right'}} span={8}>Expiration</Col>
          <Col span={16}>{Method.timeSwitch(edit.expire)}</Col>
        </div>
        <div style={{padding: '8px 0',overflow:'hidden'}}>
          <Col style={{textAlign:'right'}} span={8}>Edges Number</Col>
          <Col span={16}>{edit.edgesCnt}</Col>
        </div>
        <div style={{padding: '8px 0',overflow:'hidden'}}>
          <Col style={{textAlign:'right'}} span={8}>Enable R2TP</Col>
          <Col span={16}>{edit.r2tpSupport ? 'Yes' : 'No'}</Col>
        </div>
      </Row>
    )
  }
  editLicenseValue = (e) => {
    const {edit} = this.state
    this.setState({
      edit: {
        ...edit,
        license: e.target.value
      }
    })
  }
  next = () => {
    const {edit} = this.state
    this.setState({
      edit: {
        ...edit,
        loading: true,
      }
    })
    Api.checkLicense({license: edit.license}, response => {
      this.setState({
        edit: {
          ...edit,
          machineId: response.machine_id || '',
          license: response.license || edit.license,
          expire: response.expire || '',
          edgesCnt: response.edges_cnt || '',
          r2tpSupport: response.r2tp_support || '',
          realMachineId: response.real_machine_id || '',
          loading: false,
          step: response.machine_id ? 1 : 0,
        }
      })
    })
  }
  add = () => {
    const { edit } = this.state
    Api.setLicense({license: edit.license}, response=>{
      this.props.history.push('/controller/system/license')
      this.init()
    })
  }
  warning = () => {
    const {read, edit, edgesNum} = this.state
    const that = this
    let content = false
    let expireWarning = null
    let edgesWarning = null
    let r2tpWarning = null
    let warning = null
    if (edit.expire < read.expire) {
      expireWarning = <li>
        The expiration time in License is before original.
      </li>
      content = true
    }
    if (edit.edgesCnt < edgesNum) {
      edgesWarning = <li>
        Edge number in License is less than the current managed edge number in system.
      </li>
      content = true
    }
    if (!edit.r2tpSupport) {
      r2tpWarning = <li>
       R2tp support in License is false.
      </li>
      content = true
    }
    if (edgesWarning || r2tpWarning) {
      warning = <div style={{marginTop: '15px',color:'#f5222d'}}>
        If you enable this license, the sytem will:
        <ol style={{marginLeft: '-10px'}}>
          {edgesWarning ? <li>Set all edge to be unmanaged, delete all network topologies.</li> : null }
          {r2tpWarning ? <li>Delete all network links of topology which are "Ipsec over R2tp"</li> : null}
        </ol>
      </div>
    }
    if (!content) {
      this.add()
      return
    } else {
      content = <ul style={{marginLeft: '-45px'}}>
        {expireWarning || null}{edgesWarning || null}{r2tpWarning || null}{warning || null}
      </ul>
    }
    Modal.confirm({
      title: 'Do you want to add the license?',
      content: content,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk() {
        that.add()
      },
      onCancel() {
        that.setState({visible: false})
      },
    });
  }
  render() {
    const { getFieldProps, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 12 },
    };
    const {read} = this.state
    return (
      <div className="strategy">
        <div className="box normal">
          <div className="title">License</div>
          <div className="main">
            <div className="line-right">
              <Button type="primary" onClick={this.showAddLicense}>Add New License</Button>
            </div>
            <Form
              {...formItemLayout}
              style={{border:'1px solid #e8e8e8',borderRadius:'4px',paddingTop:'20px',marginBottom:'10px'}}
            >
              <FormItem
                label="Machine Code"
              >
                {read.machineId === read.realMachineId ? read.machineId : read.realMachineId}
              </FormItem>
              <FormItem
                label="Expiration"
              >
                {read.expire ? Method.timeSwitch(read.expire) : ''}
              </FormItem>
              <FormItem
                label="Edges Number"
              >
                {read.edgesCnt}
              </FormItem>
              <FormItem
                label="Enable R2TP"
              >
                {read.r2tpSupport ? 'Yes' : 'No'}
              </FormItem>
              <FormItem
                label="License Key"
              >
                <Input.TextArea {...getFieldProps('readLicense')} readOnly rows={12}/>
              </FormItem>
            </Form>
            {this.Modal()}
          </div>
        </div>
      </div>
    )
  }
}
Main = createForm()(Main);
export default Form.create()(Main)
