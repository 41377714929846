import React, { Component } from 'react';
import { Empty, Radio, Select } from 'antd'

const Option = Select.Option
const time = [
  {
    name: '1 hour',
    value: 0,
  },
  {
    name: '24 hours',
    value: 1,
  },
  {
    name: '7 days',
    value: 2,
  },
  {
    name: '30 days',
    value: 3,
  },
]
// const format = {
//   label: {
//     width: 100,
//     textAlign: 'right',
//     float: 'left',
//     paddingRight: '5px',
//   },
//   col: {
//     width: 'calc(100% - format.label.width)',
//     textAlign: 'left',
//     float: 'left',
//     paddingLeft: '5px',
//   }
// }
class Main extends Component {
  constructor(props) {
    super(props);
    let setting = {}
    if (this.props.operation) {
      for (let key of Object.keys(this.props.operation)) {
        setting[key] = this.props.operation[key].defaultValue
      }
    }
    this.state = {
      display: false,
      setting: setting,
    }
  }
  componentDidMount() {
  }
  componentWillUnmount() {}
  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  getContainer = () => {
    return this.container
  }
  saveContainer = (container) => {
    this.container = container
  }
  change = (type ,value) => {
    this.setState({
      setting: {
        ...this.state.setting,
        [type]: value,
      },
    },() => {
      this.props.set(this.state.setting)
      this.setLocalStorage(this.state.setting)
    })
  }
  setLocalStorage = (value) => {
    let config = JSON.parse(localStorage.getItem('dashboard_config') || '{}')
    config[sessionStorage.getItem('name')] = {
      ...config[sessionStorage.getItem('name')],
      [this.props.type]: value,
    }
    config = JSON.stringify(config)
    localStorage.setItem('dashboard_config', config)
  }
  render () {
    const {display} = this.state
    const subtitle = typeof this.props.subtitle  !== 'number' ? this.props.subtitle : time[this.state.setting.time].name
    return(
      <div
        ref={this.saveContainer}
        onMouseOver={
          ()=>{
            if (this.props.onMouseOver) {
              this.props.onMouseOver()
            };
            this.setState({display: true})
          }
        }
        onMouseOut={
          ()=>{
            if (this.props.onMouseOut) {
              this.props.onMouseOut()
            };
            this.setState({display: false})
          }
        }
        style={{
          position: 'relative',
          display: 'flex',
          width: 'calc(100% - 10px)',
          height: 'calc(100% - 10px)',
          justifyContent: 'center', 
          flexWrap: 'wrap',
          background: '#fff',
          marginTop: '5px',
          marginLeft: '5px',
          borderRadius: '3px',
          border: '3px transparent solid',
          boxShadow: '0 1px 3px rgba(0,0,0,0.4)',
        }}
      >
        {/* <div style={{
          zIndex: 3,
          position: 'absolute',
          background: '#fff',
          right: 0,
          top: 0,
          bottom: 0,
          width: this.state.visible ? '240px' : '0px',
          overflow: 'hidden',
          boxShadow: '-2px 0 8px rgba(0,0,0,0.15)',
          transition: ' width .3s',
        }}>
          <div style={{display:'flex',alignItems:'center',width: '100%',left:'0',paddingTop:'5px',height: '40px',}}>
            <div style={format.label}>
              Range: 
            </div>
            <div style={format.col}>
              <Select onChange={this.change} defaultValue={2} size="small" style={{width: 100}}>
                <Option value={0}>1 hour</Option>
                <Option value={1}>24 hours</Option>
                <Option value={2}>7 days</Option>
                <Option value={3}>30 days</Option>
              </Select>
            </div>
          </div>
          <div style={{width: '100%',textAlign:'center',paddingTop:'5px',}}>
            <Button type="primary" size="small" onClick={this.save}>Save</Button>
              &nbsp;&nbsp;&nbsp; 
            <Button size="small" onClick={()=>{this.setState({visible: false})}}>Cancel</Button>
          </div>
        </div> */}
        {this.props.operation ?
          <div style={{position:'absolute', height: 30,left: 0, right: 0, top: 30, background: '#fff', display: display ? 'block' : 'none'}}>
            {this.props.operation.time ? 
              <Select onChange={(value) => this.change('time', value)} defaultValue={this.state.setting.time} size="small" style={{width: 100,float:'left',marginLeft:'10px'}}>
                <Option value={0}>1 hour</Option>
                <Option value={1}>24 hours</Option>
                <Option value={2}>7 days</Option>
                <Option value={3}>30 days</Option>
              </Select> : null
            }
            {this.props.operation.transferType ?
              <Radio.Group size="small" value={this.state.setting.transferType} onChange={(e) => this.change('transferType', e.target.value)} style={{float:'left',marginLeft:'10px'}}>
                <Radio.Button value="upload">
                  Upload
                </Radio.Button>
                <Radio.Button value="download">
                  Download
                </Radio.Button>
              </Radio.Group> : null
            }
          </div> : null
        }
        <div className="title"
          style={{
            position: 'relative',
            display: 'flex',
            height: 32,
            width: '100%',
            padding: '0 10px',
            justifyContent: 'space-between', 
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontWeight: 600,
              fontSize: 18,
            }} 
          >{this.props.title}</div>
          {/* {this.props.operation ?
            <div
              style={{
                zIndex: 2,
                display: display,
                alignItems: 'center',
                fontSize: 18,
                position: 'absolute',
                height: 32,
                right: 5,
                top: 0,
              }} 
            >
              {this.props.operation.refresh ? 
                <span>
                  <Icon type="undo" style={{cursor: 'pointer'}}/>
                </span> : null
              }
              {this.props.operation.menu ? 
                <span
                  style={{
                    marginLeft: '10px',
                  }} 
                >
                  <Icon onClick={()=>this.setState({visible: true})} type="menu" style={{cursor: 'pointer'}}/>
                </span> : null
              }
            </div> : null
          } */}
        </div>
        <div style={{
            display: 'flex',
            height: 28,
            lineHeight: '10px',
            width: '100%',
            padding: '0 12px',
            fontSize: '14px',
            fontWeight: 600,
            color: '#999999',
            justifyContent: 'space-between', 
        }}>
          {subtitle}
        </div>
        <div style={{
          position: 'relative',
          width: 'calc(100% - 10px)',
          height: 'calc(100% - 60px)'
        }}>
          {this.props.show ? this.props.children 
            : <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center', alignItems:'center',}}>
                <Empty style={{height: 100}} image={Empty.PRESENTED_IMAGE_SIMPLE}/>
              </div>}
        </div> 
      </div>
    )
  }
}

export default Main;