import React, { Component } from 'react';
import { Modal, Switch, Form, Select, Input, Icon, Radio, Divider, Row, Col, Checkbox } from 'antd'
import Api from '../../../global/api'
import SetAppRules from '../../../components/setAppRules'
import { Ip } from '../../../common/validator';

const Option = Select.Option;
const Img = <img style={{width: 20, marginRight: 5}} src={require('../../../assets/images/spoke-blue.svg')} />
class Main extends Component {
  componentDidMount() {
    this.init()
  }
  componentWillUnmount() {}
  handleOk = () => {
    this.props.form.validateFields((errors, values) => {
      if (!!errors) {
        return
      }
      const enable = this.state.specific_egresses.enable
      const data = {
        index: this.state.strategy.index,
        name: values.name,
        nodes: values.nodes,
        default_egress: {
          type: values.default_egress_type,
        },
        specific_egresses: [{
          enable: enable,
          egress: {
            type: enable ? values.specific_egresses_type : this.state.specific_egresses.type,
          },
          // dns: enable ? values.specific_egresses_dns : this.state.specific_egresses.dns,
          apps: enable ? values.specific_egresses_apps : this.state.specific_egresses.apps,
        }],
      }
      if (this.state.strategy.id) {
        data.id = this.state.strategy.id
        if (this.state.strategy.specific_egresses) {
          data.specific_egresses[0].id = this.state.strategy.specific_egresses[0].id
        }
      }
      this.props.save(data)
    })
  }
  handleCancel = () => {
    this.props.cancel()
  }
  onRef = (ref) => {
    this.child =ref
  }
  init = () => {
    let data = {}
    if (this.props.data.id || this.props.data.name) {
      data = this.props.data
    } else {
      data = {
        index: this.props.data.index,
        name: '',
        nodes: [],
        default_egress: {
          type: 0,
        },
        specific_egresses: [{
          enable: false,
          type: 1,
          // dns: '',
          apps: [],
        }],
      }
    }
    if (!data.specific_egresses) {
      data.specific_egresses = [{
        enable: false,
        type: data.default_egress.type === 0 ? 1 : 0,
        // dns: '',
        apps: [],
      }]
    }
    this.setState({
      id: this.props.data.id || null,
      strategy: {
        ...data,
      },
      specific_egresses: {
        enable: data.specific_egresses[0].enable,
        type: data.default_egress.type === 0 ? 1 : 0,
        // dns: data.specific_egresses[0].dns,
        apps: data.specific_egresses[0].apps,
      },
    }, () => {
      let form = {
        name: data.name,
        nodes: data.nodes || [],
        default_egress_type: data.default_egress.type,
      }
      if (this.state.specific_egresses.enable) {
        form = {
          ...form,
          specific_egresses_type: data.default_egress.type === 0 ? 1 : 0,
          // specific_egresses_dns: data.specific_egresses[0].dns,
          specific_egresses_apps: data.specific_egresses[0].apps,
        }
      }
      this.props.form.setFieldsValue({
        ...form,
      })
      this.apps()
    })
  }
  state = {
    id: null,
    apps: [],
    rules: {
      show: false,
    },
    specific_egresses: {
      enable: false,
      type: 0,
    }
  }
  apps = async () => {
    const {specific_egresses} = this.state
    const predefined = await Api.get.apps_predefined(null)
    const custom = await Api.get.apps_custom(null)
    const apps = [...(predefined.items || []), ...(custom.items || [])]
    const { getFieldValue, setFieldsValue } = this.props.form;
    const selectValue = getFieldValue('specific_egresses_apps') || []
    const newSpecificEgressesApps = this.newSpecificEgressesApps(apps, selectValue)
    setFieldsValue({
      specific_egresses_apps: newSpecificEgressesApps,
    })
    this.setState({
      specific_egresses: {
        ...specific_egresses,
        apps: newSpecificEgressesApps,
      },
      apps: apps,
    })
  }
  newSpecificEgressesApps = (apps, selectValue) => {
    const appsIds = apps.map(ele => {
      return ele.id
    })
    const selectNewValue = selectValue.filter(ele => {
      return appsIds.indexOf(ele) !== -1
    })
    return selectNewValue
  }
  createApp = () => {
    this.setState({
      rules: {
        show: true,
      }
    })
  }
  cancelRules = () => {
    this.setState({
      rules: {
        show: false,
      },
    })
  }
  saveRules = (data) => {
    if (data === 'success') {
      this.apps()
      this.cancelRules()
    }
  }
  onChangeEnable = (value) => {
    const {specific_egresses} = this.state
    this.setState({
      specific_egresses: {
        ...specific_egresses,
        enable: value,
      }
    }, () => {
      if (value) {
        this.props.form.setFieldsValue({
          specific_egresses_type: specific_egresses.type,
          specific_egresses_apps: specific_egresses.apps,
        })
      }
    })
  }
  onChangeRadio = (value) => {
    const {specific_egresses} = this.state
    this.setState({
      specific_egresses: {
        ...specific_egresses, 
        type: value === 0 ? 1 : 0,
      }
    },() => {
      if (specific_egresses.enable) {
        this.props.form.setFieldsValue({
          specific_egresses_type: value === 0 ? 1 : 0,
        })
      }
    })
  }
  render () {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };
    const { getFieldDecorator } = this.props.form;
    return(
      <Modal
        title = {this.state.id || this.props.data.name ? 'Edit Strategy' : 'New Strategy'}
        maskClosable={false}
        width={800}
        visible={true}
        onOk={this.handleOk}
        okText="Save"
        onCancel={this.handleCancel}
      >
        {this.state.rules.show ? <SetAppRules save={this.saveRules} cancel={this.cancelRules} /> : null}
        <Form {...formItemLayout} style={{margin: '0 80px'}}>
          {this.state.id ? <Form.Item label="ID">
            {this.state.id}
          </Form.Item> : null}
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please enter a name!',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Nodes">
            {getFieldDecorator('nodes', {
              rules: [
                {
                  required: false,
                  message: 'Please input hosts!',
                },
              ],
            })(
              <Checkbox.Group
                style={{width:'100%',paddingTop:'10px'}} 
              >
                <Row style={{maxHeight:'200px',overflowY:'scroll'}}>
                  {Object.keys(this.props.edges).map((key, index) => {
                    let edge = this.props.edges[key]
                    return <Col span={24} key={index}>
                      <Checkbox value={edge.sn}>
                        {Img}{edge.name}
                      </Checkbox>
                    </Col>
                  })}
                </Row>
              </Checkbox.Group>
            )}
          </Form.Item>
          <div style={{background: '#fafafa', borderRadius: '4px', overflow: 'hidden',marginBottom:'20px',paddingTop:'20px'}}>
            <Form.Item label="Default Egress">
            </Form.Item>
            <Form.Item label="Type">
              {getFieldDecorator('default_egress_type', {
                initialValue: 0,
              })(
                <Radio.Group style={{width: '100%',}} onChange={e => this.onChangeRadio(e.target.value)}>
                  <Radio.Button style={{width: '50%',textAlign: 'center',}} value={0}>Local</Radio.Button>
                  <Radio.Button style={{width: '50%',textAlign: 'center',}} value={1}>Remote</Radio.Button>
                  {/* <Radio.Button style={{width: '33.33%',textAlign: 'center',}} value={2}>Custom</Radio.Button> */}
                </Radio.Group>
              )}
            </Form.Item>
          </div>
          <div style={{background: '#fafafa', borderRadius: '4px', overflow: 'hidden',paddingTop:'20px'}}>
            <Form.Item label="Specific Egress">
              <Switch defaultChecked={this.state.specific_egresses.enable} onChange={value => this.onChangeEnable(value)}/>
            </Form.Item>
            {
              this.state.specific_egresses.enable ? 
              <div>
                <Form.Item label="Type">
                  {getFieldDecorator('specific_egresses_type', {
                    initialValue: 0,
                  })(
                    <Radio.Group style={{width: '100%',}} >
                      <Radio.Button disabled={this.state.specific_egresses.type === 1} style={{width: '50%',textAlign: 'center',}} value={0}>Local</Radio.Button>
                      <Radio.Button disabled={this.state.specific_egresses.type === 0} style={{width: '50%',textAlign: 'center',}} value={1}>Remote</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
                {/* <Form.Item label="DNS">
                  {getFieldDecorator('specific_egresses_dns', {
                    rules: [
                      {required: true, message: 'Please input DNS!'},
                      {validator: Ip},
                    ],
                    initialValue: '',
                  })(
                    <Input />
                  )}
                </Form.Item> */}
                <Form.Item label="Apps">
                  {getFieldDecorator('specific_egresses_apps', {
                    rules: [],
                    filterOption: (value, option) => {
                    }
                  })(
                    <Select
                      mode="multiple"
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <div style={{ padding: '8px', cursor: 'pointer' }} onClick={this.createApp}>
                            <Icon type="plus" /> Create Application
                          </div>
                        </div>
                      )}
                    >
                      {this.state.apps.map((app, index) => {
                        return <Option key={index} value={app.id} title={app.name}>{app.name}</Option>
                      })}
                    </Select>
                  )}
                </Form.Item> 
              </div> : null
            }
          </div>
        </Form>
      </Modal>
    )
  }
}
const MainForm = Form.create()(Main)
export default MainForm;