import React from 'react';
import {Form, Icon, Col} from 'antd'
import Api from '../global/api'

const img = {
  display: "block",
  width: "260px",
  margin: "0 auto",
}
const title = {
  fontSize: 25,
}
const icon = {
  fontSize: 100,
  marginBottom: 20,
  fontWeight: 500,
  // color: '#fa8c16',
}
class Entrance extends React.Component {
  componentDidMount() {
    this.init()
  }
  state = {
    url: window.location.origin + '/controller/login',
    controller_url: '',
    analytics_url: '',
  }
  toUrl = (type) => {
    if (this.state[type]) {
      window.open(this.state[type])
    }
  }
  init = () => {
    Api.get.gateway(response => {
      if (response == null) return
      this.setState({
        url: window.location.origin + '/controller/login',
        controller_url: response.controller_url,
        analytics_url: response.analytics_url,
      })
    })
  }
  render() {
    const cell = {
      width: '240px',
      height: '200px',
      textAlign: 'center',
      paddingTop: '25px',
      cursor: 'pointer',
      borderRadius: '4px',
      border: '1px solid #ccc',
      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 1px 3px',
    }
    return (
      <div style={{overflow:'hidden'}}>
        <div style={{width: "100%",height: "85px",marginTop:"12%",marginBottom:"100px"}}>
          <img style={img} src={require('../assets/images/logo.png')} alt=""/>
        </div>
        <div className="box login-box">
          <div className="main" style={{display:'flex',justifyContent:'space-around',margin: '0 auto', padding:'0 40px',maxWidth: '1240px'}}>
            <div style={{...cell, color: this.state.controller_url ? 'rgba(0, 0, 0, 0.65)' : '#ccc'}} onClick={()=>this.toUrl('controller_url')}>
              <Icon style={icon} type="control" />
              <div style={title}>Gateway Controller</div>
            </div>
            <div style={{...cell, color: this.state.url ? 'rgba(0, 0, 0, 0.65)' : '#ccc'}} onClick={()=>this.toUrl('url')}>
              <Icon style={icon} type="share-alt" />
              <div style={title}>Edge Controller</div>
            </div>
            <div style={{...cell, color: this.state.analytics_url ? 'rgba(0, 0, 0, 0.65)' : '#ccc'}} onClick={()=>this.toUrl('analytics_url')}>
              <Icon style={icon} type="bar-chart" />
              <div style={title}>Analytics</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Entrance
