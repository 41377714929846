import React, { Component } from 'react';
import Box from '../box'
import echarts from 'echarts'
import Api from '../../../global/api'
import Method from '../../../global/method'

const UsagesName = {
  bypass: 'Internet',
  vpn: 'VPN',
  blocked: 'Blocked',
}
const Color = ['#61a0a8' ,'#7ebd9d', '#c23531']
const Time = [
  1 * 60 * 60 * 1000,
  24 * 60 * 60 * 1000,
  7 * 24 * 60 * 60 * 1000,
  30 * 24 * 60 * 60 * 1000,
]
class Main extends Component {
  state = {
    data: [],
    display: 'none',
    setting: this.props.setting,
  }
  componentDidMount() {
    this.init()
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  init = () => {
    this.getData('init')
    this.intervalUsages()
  }
  getData = (type) => {
    let beg = Method.timeModelSwitch(Date.now() - Time[this.state.setting.time])
    let end = Method.timeModelSwitch(Date.now())
    let params = {
      beg: beg,
      end: end,
      dir: this.state.setting.transferType === 'upload' ? 'output' : 'input',
      indicators: 'bypass,vpn,blocked',
    }
    Api.get.usages(params, response=> {
      let data = []
      response.indicators.map(
        (ele, index) => {
          data[index] = {
            name: UsagesName[ele]
          }
        }
      )
      response.usages.map(
        (ele, index) => {
          data[index].value = ele
          data[index].itemStyle = {
            color: Color[index],
          }
        }
      )
      this.setState({
        data: data,
      },() => {
        if (type === 'init') {
          this.drawChart()
        } else {
          this.updateChart()
        }
      })
    })
  }
  intervalUsages = () => {
    clearInterval(this.interval)
    this.interval = null
    this.interval = setTimeout(()=> {
      this.getData()
      this.intervalUsages()
    }, 10 * 6000)
  }
  charts = () => {
    let main = document.getElementById('chart-usages')
    let existInstance = echarts.getInstanceByDom(main)
    if (existInstance !== undefined) {
      return existInstance
    }
    let myChart = echarts.init(main)
    return myChart 
  }
  drawChart = () => {
    var myChart = this.charts()
    myChart.hideLoading()
    const option = {
      tooltip : {
        trigger: 'item',
        formatter: (value) => {
          return `<div>${Method.trafficSwitch(value.value)} ${value.percent}%</div>`
        }
      },
      legend: {
        type: 'scroll',
        orient: 'vertical',
        right: 8,
        bottom: 50,
        // data: data.legendData,
        // selected: data.selected
      },
      series : [
        {
          name: 'Traffic',
          type: 'pie',
          radius : '60%',
          center: ['36%', '50%'],
          data: this.state.data,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    }
    myChart.setOption(option)
  }
  updateChart = () => {
    var myChart = this.charts()
    myChart.setOption({
      series: [{
        data: this.state.data,
      }]
    })
  }
  set = (value) => {
    this.setState({
      setting: value,
    }, () => {
      this.init()
    })
  }
  render () {
    const {transferType} = this.state.setting
    const title = `Total Underlay Traffics - ${transferType === 'upload' ? 'Upload' : 'Download'}`
    return(
      <Box 
        subtitle = {this.state.setting.time}
        title = {title}
        show = {true}
        set = {this.set}
        operation = {{
          transferType: {defaultValue: this.state.setting.transferType},
          time: {defaultValue: this.state.setting.time},
        }}
        type={'usages'}
      >
        <div
          id="chart-usages"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }} 
        >
        </div>
      </Box>
    )
  }
}

export default Main;