import React, { Component } from 'react';
import { Empty, Select, Radio, Tabs, DatePicker } from 'antd';
import Charts from './chart'
import Api from '../../../global/api';
import moment, { now } from 'moment';

const Option = Select.Option;

class Main extends Component {
  state = {
    edges: {},
    edges1List: [],
    edges2List: [],
    edge1: '',
    edge2: '',
    view: {
      time: '',
      aggregate: '',
    },
    timeRange: [null, null],
    config: {
      beg: null,
      end: null,
      sn1: null,
      sn2: null,
      timegroup: null,
      aggregate: 'avg',
    },
    charts: [],
  }

  componentDidMount() {
    this.viewInit()
    this.data = {}
  }

  viewInit = () => {
    const {view} = this.state
    this.setState({
      view: {
        ...view,
        time: 3600,
        aggregate: 'avg',
      }
    },() => {
      this.setTimeRangeModel(3600)
    })
  }

  aggregateChange = (value) => {
    const {view} = this.state
    this.setState({
      view: {
        ...view,
        aggregate: value, 
      }
    }, () => {
      this.search()
    })
  }

  timeChange = (e) => {
    const {view} = this.state
    this.setState({
      view: {
        ...view,
        time: e.target.value,
      }
    }, () => {
      this.setTimeRangeModel(e.target.value)
    })
  }

  timeRangeChange = (value) => {
    let timeRangeSeconds = value[0].diff(value[1], 'days')
    if (Math.abs(timeRangeSeconds) > 31) {
      return
    }
    this.setState({
      timeRange: value,
    })
  }

  setTimeRange = () => {
    const {view} = this.state
    this.setState({
      view: {
        ...view,
        time: '',
      }
    }, () => {
      this.search()
    })
  }

  setTimeRangeModel = (value) => {
    let timeRange = [null, null]
    switch (true) {
      case value <= 24 * 60 * 60:
        timeRange = [moment().subtract(value / 3600, 'hours'), moment()]
        break;
      default:
        timeRange = [moment().subtract(value / (3600 * 24), 'days'), moment()]
        break;
    }
    this.setState({
      timeRange: timeRange,
    }, () => {
      this.search()
    })
  }

  search = async() => {
    const {edge1, edge2} = this.props
    const {view, timeRange} = this.state
    const params = {}
    params.beg = moment(timeRange[0]).utc().startOf('minute').valueOf() / 1000
    params.end = moment(timeRange[1]).utc().startOf('minute').valueOf() / 1000
    const timegroup = this.getTimegroup(params.beg, params.end)
    params.timegroup = timegroup.time
    params.aggregate = view.aggregate
    const data1 = await this.getDataAsync(params, edge1.sn, edge2.sn)
    const data2 = await this.getDataAsync(params, edge2.sn, edge1.sn)
    const getChartsData = this.getChartsDataFunc()
    let chartsData = getChartsData(1, data1, edge1, params.beg, params.end, timegroup.value)
    chartsData = getChartsData(2, data2, edge2)
    this.setState({
      date: chartsData.dateList,
      charts: chartsData.valueMap,
      timegroup: chartsData.timegroup,
    }, () => {
      // console.log(chartsData)
    })
  }
  
  getDataAsync = async(params, src, dst) => {
    params.src = src
    params.dst = dst
    const response = await Api.get.links_stats(params)
    if (response.status !== 200) { return [] }
    return response.data
  }

  getTimegroup = (beg, end) => {
    const time = (end - beg) / 60
    switch (true) {
      case time <= 12 * 60:
        return {
          time: '1m',
          value: 60,
        }
      case time <= 24 * 60:
        return {
          time: '5m',
          value: 300,
        }
      case time <= 24 * 60 * 3:
        return {
          time: '10m',
          value: 600,
        }
      case time <= 24 * 60 * 7:
        return {
          time: '15m',
          value: 900,
        }
      case time <= 24 * 60 * 14:
        return {
          time: '30m',
          value: 1800,
        }
      default:
        return {
          time: '2h',
          value: 7200,
        }
    }
  }

  getChartsDataFunc = () => {
    const chartsData = {
      dateList: [],
      timegroup: null,
      valueMap: {},
      beg: null,
      end: null,
    }
    return (index, data, edge, begValue, endValue, timegroupValue) => {
      if (begValue && endValue) {
        chartsData.timegroup = timegroupValue
        const beg = begValue - begValue % timegroupValue
        const end = endValue - endValue % timegroupValue
        chartsData.beg = beg
        chartsData.end = end
        chartsData.dateList = Array.from({length: (endValue - begValue)/timegroupValue + 1}, (_, i) => beg + i * timegroupValue)
      }
      const {dateList, timegroup, valueMap, beg, end} = chartsData
      const {indicators, aggregations, series} = data
      if (!series) {
        return chartsData
      }
      indicators.map((ele, i) => {
        const value = valueMap[ele] || {}
        valueMap[ele] = {
          ...value,
          ['edge' + index]: edge.name,
          indicator: ele,
          aggregate: aggregations[i],
          ['series' + index]: Array.from(dateList, x => null),
        }
      })
      series.map(list => {
        let seriesIndex = [list[0] - beg] / timegroup
        list.map((ele, i, array) => {
          if (i > 0) {
            let seriesName = indicators[i - 1]
            if (seriesName === 'loss_rate') {
              ele = Math.floor(ele * 1000) / 1000
            }
            // ele = 10000.132312312312312
            valueMap[seriesName]['series' + index][seriesIndex] = ele
          }
        })
      })
      return chartsData
    }
  }

  disabledDate = (current) => {
    return current && current > moment().endOf('day')
  }
  componentWillUnmount() {}

  render () {
    return(
      <div style={{width: '100%'}}>
        <div style={{marginBottom: 20,}}>
          <div style={{display: 'inline-block', marginTop: 10,marginRight: 20}}>
            <span style={{marginRight:'10px',}}>
              Method:
            </span>
            <Select value={this.state.view.aggregate} onChange={this.aggregateChange} style={{width: 120}} defaultValue='avg'>
              <Option key='0' value='max'>Maximum</Option>
              <Option key='1' value='min'>Minimum</Option>
              <Option key='2' value='avg'>Average</Option>
            </Select>
          </div>
          <Radio.Group value={this.state.view.time} style={{marginRight: 20, marginTop: 10}} onChange={this.timeChange}>
            <Radio.Button value={3600}>1 Hour</Radio.Button>
            <Radio.Button value={3600 * 6}>6 Hours</Radio.Button>
            <Radio.Button value={3600 * 12}>12 Hours</Radio.Button>
            <Radio.Button value={3600 * 24}>1 Day</Radio.Button>
            <Radio.Button value={3600 * 24 * 7}>7 Days</Radio.Button>
            <Radio.Button value={3600 * 24 * 14}>14 Days</Radio.Button>
            <Radio.Button value={3600 * 24 * 31}>31 Days</Radio.Button>
          </Radio.Group>
          <div style={{display:'inline-block',marginTop: 10}}>
            <span style={{marginRight:'10px'}}>
              From:
            </span>
            <DatePicker.RangePicker
              value={this.state.timeRange}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              placeholder={['Start Time', 'End Time']}
              onChange={this.timeRangeChange}
              onOk={this.setTimeRange}
              disabledDate={this.disabledDate}
              renderExtraFooter={() => <span>* You can request the monitoring data for the 31 days.</span>}
            />
          </div>
        </div>
        {
          Object.keys(this.state.charts).length === 0 ?
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            :<div style={{display:'flex',flexDirection:'row',justifyContent:'left',flexWrap:'wrap',}}>
              {Object.keys(this.state.charts).map((ele, index) =>{
              const value = this.state.charts[ele]
              return <div key={Math.random()} style={{width: '33.33%'}}>
                <div style={{position:'relative', paddingTop:'78%'}}>
                  <div style={{position: 'absolute', top:10, right:10, bottom:10, left:10, borderRadius:'4px',}}>
                    <Charts index={index} date={this.state.date} dataList={value} timegroup={this.state.timegroup}/>
                  </div>
                </div>
              </div>})}
          </div>
        }
      </div>          
    )
  }
}

export default Main;