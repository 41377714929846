import React from 'react';
import Context from '../context/';

export default function (WrappedComponent) {
  class InjectContext extends React.Component {
    render() {
      const { forwardRef, ...rest } = this.props;

      return (
        <Context.Consumer>
          {context => <WrappedComponent ref={forwardRef} {...rest} {...context} />}
        </Context.Consumer>
      );
    }
  }

  return React.forwardRef((props, ref) => <InjectContext {...props} forwardRef={ref} />);
}
