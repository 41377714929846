import React, { Component } from 'react';
import { Button, Icon, Popconfirm, Tooltip, Divider, Table } from 'antd'
import G6 from '@antv/g6'
import {View, GetGraphData,} from './graph/config'
import Global from '../../global/method'
import {Link} from 'react-router-dom'

const IconSwapLeft = require('../../assets/images/swap-left.svg')
const IconSwapRight = require('../../assets/images/swap-right.svg')
class Main extends Component {
  componentDidMount() {
    this.init()
    this.tooltipShow = false
  }
  componentWillUnmount() {
    this.graph = null
  }
  componentDidUpdate(prevProps) {
    if (this.graph === 'delete') {
      return
    }
    if (this.props.isRefresh) {
      this.props.refreshComplete(()=>{
        this.draw()
      })
    }
    if (this.props.isUpdate) {
      this.props.updateComplete(()=>{
        this.init()
      })
    }
    if (
      JSON.stringify(this.props.linksState) !== JSON.stringify(prevProps.linksState)
    ) {
      this.setTooltipData()
      let links = this.state.links
      let keys = Object.keys(links)
      keys.forEach(key => {
        let link = links[key]
        let defaultData = {
          server_stats: null,
          client_stats: null,
          ...link,
          link_id: link.link_id,
          state: 0,
        }
        let data = this.props.linksState[link.link_id] ? {
          server_stats: null,
          client_stats: null,
          ...link,
          ...this.props.linksState[link.link_id],
        } : defaultData
        let prevData = prevProps.linksState[link.link_id] ? {
          server_stats: null,
          client_stats: null,
          ...link,
          ...prevProps.linksState[link.link_id],
        } : defaultData
        if (
          (JSON.stringify(data) !== JSON.stringify(prevData))
          || (this.props.linksState[link.link_id] && !prevProps.linksState[link.link_id])
        ) {
          links = {
            ...links,
            [link.link_id]: data,
          }
          if (this.graph) {
            this.updateLinkState(link.link_id, data.state)
          }
        }
      })
      this.setState({
        links: {
          ...links,
        }
      })
    }
  }
  state = {
    toolShow: false,
    popconfirmShow: false,
    links: null,
    tooltip: {
      visible: false,
      target: null,
      offset: [0, 0],
      data: {
        server: {},
        client: {},
      },
    }
  }
  init = () => {
    if (this.graph === 'delete') {
      return
    }
    this.draw()
    this.linksState()
  }
  isLinkUpdate = (prevState, state) => {
    return false
  }
  draw = () => {
    let Id = 'organization' + this.props.organization.data.id
    const { clientWidth, clientHeight } = document.getElementById(Id)
    if (this.graph && this.graph !== 'delete') {
      this.graph.destroy() 
    }
    const that = this
    G6.registerBehavior('view', {
      getEvents() {
        return {
          'edge:mousemove': 'onEdgeMousemove',
          'edge:mouseout': 'onEdgeMouseout',
        }
      },
      onEdgeMousemove(e) {
        const id = e.item._cfg.id
        that.tooltip('show', id, e)
      },
      onEdgeMouseout(e) {
        const id = e.item._cfg.id
        setTimeout(()=>{
          if (!that.tooltipShow) {
            that.tooltip('hidden', id, e)
          }
        }, 1000)
      },
    })
    this.graph = new G6.Graph({
      container: Id,
      width: clientWidth,
      height: clientHeight,
      modes: {
        default: ['view']
      },
    })
    let data = this.data({w: clientWidth, h: clientHeight});
    let nodes = data.nodes.map((node, index) => {
      node.label = this.props.edges.find(edge => {
        return node.id === edge.sn
      }).name
      node.online = this.props.edges.find(edge => {
        return node.id === edge.sn
      }).online
      if (node.kind === 0) {
        node = node.online ? {...node, ...View.spoke.view, ...View.spoke.state[1]} : {...node, ...View.spoke.view, ...View.spoke.state[0]}
      }
      if (node.kind === 1) {
        node = node.online ? {...node, ...View.hub.view, ...View.hub.state[1]} : {...node, ...View.hub.view, ...View.hub.state[0]}
      }
      return node
    })
    data.edges.filter(edge => {
      edge.style = {...View.line.view.style, ...View.line.state[edge.setting.data.state].style}
      return true
    })
    data.nodes = nodes
    this.graph.read(data)
  }
  linksState = () => {
    let links = {}
    this.props.organization.data.links.forEach(link => {
      links[link.id] = {
        kind: link.kind,
        link_id: link.id,
        client_sn: link.client_sn,
        server_sn: link.server_sn,
        state: link.state,
      }
    })
    this.setState({
      links: links,
    })
  }
  updateLinkState = (id, state) => {
    const edge = this.graph.findById(id)
    this.graph.update(edge, {
      style: {...View.line.view.style, ...View.line.state[state].style},
    })
  }
  getTooltipData = (id) => {
    const link = this.state.links[id]
    const server = this.props.edges.find(edge => {
      return link.server_sn === edge.sn
    })
    const client = this.props.edges.find(edge => {
      return link.client_sn === edge.sn
    })
    const data = {
      id: id,
      kind: link.kind || 0,
      server: server,
      client: client,
      server_stats: link.server_stats || null,
      client_stats: link.client_stats || null,
    }
    return data
  }
  setTooltipData = () =>{
    const tooltip = this.state.tooltip
    if (!tooltip.visible) {
      return
    }
    const data = this.getTooltipData(tooltip.data.id)
    this.setState({
      tooltip: {
        ...tooltip,
        data: data,
      }
    })
  }
  tooltip = (type, id, e) => {
    const isShow = type === 'show'
    let data = null
    if (isShow) {
      data = this.getTooltipData(id) 
    } else {
      e = {
        event: {},
      }
      this.tooltipShow = false
    }
    const element = e.event.toElement || e.event.srcElement || {}
    const tooltip = {
      visible: isShow,
      offset: isShow ? [e.event.offsetX, e.event.offsetY + 3]: [0, 0],
      canvas: {
        clientWidth: element.clientWidth || 0,
        clientHeight: element.clientHeight || 0,
      },
      data: data,
    }
    this.setState({
      tooltip: tooltip,
    })
  }
  data = (size) => {
    let data = GetGraphData(size, this.props.organization.data, 'view')
    return data
  }
  edit = () => {
    this.props.editTopology(this.props.organization.index)
  }
  internet = () => {
    this.props.internetSetting(this.props.organization.index)
  }
  delete = () => {
    this.setState({
      popconfirmShow: false,
    })
    this.props.delTopology(this.props.organization.data.id, () => {
      this.graph.destroy()
      this.graph = 'delete'
    })
  }
  showPopconfirm = () => {
    this.setState({popconfirmShow: true})
  }
  tooltipTable = () => {
    const data = this.state.tooltip.data
    // const x = this.state.tooltip.offset[0]
    // const centerX = this.state.tooltip.canvas.clientWidth / 2 + 6
    // const type = x < centerX ? 'server' : 'client'
    // const type = 'server'
    const server = data.server_stats ? this.tooltipData(data.server_stats) : {}
    const client = data.client_stats ? this.tooltipData(data.client_stats) : {}
    return(
      <div 
        onMouseEnter={()=>{this.tooltipShow = true}} onMouseLeave={() => {this.tooltip('hidden')}}
      >
        {this.tooltipTitle(data)}
        {
          data.server_stats || data.client_stats ? 
          <table style={{width:'100%',}}>
            <thead>
              <tr>
                <th style={{paddingLeft: '10px'}}></th>
                <th>
                  <img src={IconSwapRight} />
                </th>
                <th>
                  <img src={IconSwapLeft} />
                </th>
              </tr>
            </thead>
            <tbody>
              {data.kind === 1 ? null
                : <tr>
                  <td>Bitrate:</td>
                  <td>{server.bitrate}</td>
                  <td>{client.bitrate}</td>
                </tr>
              }
              <tr>
                <td>RTT:</td>
                <td>{server.rtt}</td>
                <td>{client.rtt}</td>
              </tr>
              <tr>
                <td>Packet Loss:</td>
                <td>{server.loss_rate}</td>
                <td>{client.loss_rate}</td>
              </tr>
              {data.kind === 1 ? null
                : <tr>
                  <td>Jitter:</td>
                  <td>{server.jitter}</td>
                  <td>{client.jitter}</td>
                </tr>
              }
              {data.kind === 1 ? null
                : <tr>
                  <td>Max Delay:</td>
                  <td>{server.max_delay}</td>
                  <td>{client.max_delay}</td>
                </tr>
              }
            </tbody> 
          </table>
          : <span style={{color:'rgba(255, 255, 255, 0.5)',fontSize:'14px',paddingLeft:'5px'}}>No Data</span>
        }
        <div style={{width: '100%',textAlign:'right'}}>
          <Link 
            to={{
              pathname:"/controller/statistics",
              state:{
                sn1:data.server.sn,
                sn2:data.client.sn,
              }
            }}
            >More Data</Link>
        </div> 
      </div>
    )
  }
  tooltipData = (data) => {
    return {
      bitrate: Global.bandwidthSwitch(data.bitrate),
      rtt: (data.rtt / 1000).toFixed(0) + ' ms',
      loss_rate: (data.loss_rate * 100).toFixed(2) + ' %',
      jitter: (data.jitter / 1000).toFixed(0) + ' ms',
      max_delay: (data.max_delay / 1000).toFixed(0) + ' ms',
    }
  } 
  tooltipTitle = (data) => {
    let Server = () => <span>{data.server.name}<span style={{color: 'rgba(255, 255, 255, 0.5)',fontSize:'14px',paddingLeft:'5px'}}>Hub</span></span>
    let Client = () => <span>{data.client.name}</span>
    return(
      <div style={{width: '100%',fontSize: '16px',marginBottom: '10px'}}>
          <div style={{float: 'left',width:'calc(50% - 20px)',textAlign:'right',}}>
            <Client/>
          </div>
          <div
            style={{float: 'left',width:'40px', textAlign: 'center'}}
          >
            <Icon type="swap" />
          </div>
          <div style={{float: 'left',width:'calc(50% - 20px)',textAlign:'left',}}>
            <Server/>
          </div>
      </div>
    )
  }
  render () {
    return(
      <div onMouseEnter={() =>this.setState({toolShow: true})} onMouseLeave={() =>this.setState({toolShow: false, popconfirmShow: false})} 
        style={{
          border: '1px transparent solid',
          boxShadow: '0 1px 2px rgba(0,0,0,0.3)',
          top: '10px', bottom: '10px', left: '10px', right: '10px', background: '#fff', borderRadius: '4px',position: 'absolute'}}
      >
        {this.state.tooltip.visible ? 
          <Tooltip 
            visible={this.state.tooltip.visible}
            mouseEnterDelay={0.8}
            align={{offset: [this.state.tooltip.offset[0], this.state.tooltip.offset[1]]}}
            placement={this.state.tooltip.offset[0] < (this.state.tooltip.canvas.clientWidth - 180)? 'top' : 'topRight'}
            getPopupContainer={() => 
              document.getElementsByClassName('deployment')[0]
            }
            title={
              this.tooltipTable()
            }
          >
          </Tooltip> : null
        }
        <div style={{zIndex: 3,display: this.state.toolShow? 'flex' : 'none',height: '50px',width: '100%',padding: '0 10px',position: 'absolute', top: '0px',justifyContent: 'flex-end',alignItems: 'center'}}>
          <Button.Group>
            <Popconfirm 
              title="Are you sure？"
              placement="bottom"
              okText="Yes"
              cancelText="No"
              visible={this.state.popconfirmShow}
              onCancel={()=>{this.setState({popconfirmShow: false})}}
              onConfirm={this.delete}
            >
              <Button onClick={this.showPopconfirm} type="danger"> 
                <Icon type="delete" /> 
                  Delete
              </Button>
            </Popconfirm>
            {this.props.internetSetting ? 
              <Button onClick={this.internet} > 
                <Icon type="cloud" />
                Internet
              </Button>
              : null
            }
            <Button onClick={this.edit} > 
              <Icon type="form" />
              Edit
            </Button>
          </Button.Group>
        </div>
        <div style={{
            zIndex: 2,
            display:'flex',
            height: '50px',
            left: '10px',
            right: '10px',
            fontWeight:'600',
            fontSize:'18px',
            position: 'absolute',
            top: '5px',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}>
          {this.props.organization.data.name}
        </div>
        <div
          style={{
            position: 'absolute',
            top: 10,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div 
            id={'organization'+ this.props.organization.data.id} 
            style={{width: '100%', height: '100%', overflow: 'hidden'}}
          >
          </div>
        </div>
      </div>
    )
  }
}

export default Main;