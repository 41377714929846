import React, { Component } from 'react';
import { Modal, Tabs, Form, Select, Input, Icon, Radio, Divider, message } from 'antd'
import '../../style/components/setRules.css'
import { Ports, HostRegex } from '../../common/validator';
import Method from '../setAppRules/../../global/method'

const Option = Select.Option;
const { Search } = Input;
class Main extends Component {
  componentDidMount() {
    this.init()
  }
  componentWillUnmount() {}
  handleOk = () => {
    this.props.form.validateFields((errors, values) => {
      if (!!errors) {
        return
      }
      const data = {
        ...this.state,
        name: values.name,
        ports: values.ports,
        protocol: values.protocol,
      }
      this.props.save(data)
    })
    return
  }
  handleCancel = () => {
    this.props.cancel()
  }
  onRef = (ref) => {
    this.child =ref
  }
  init = () => {
    let data = {}
    if (this.props.data.name) {
      data = this.props.data
      data.host = ''
      data.dst_hosts = data.dst_hosts || []
    } else {
      data = {
        index: this.props.data.index,
        name: '',
        dst_hosts: [],
        host: '',
        ports: '',
        protocol: 0,
      }
    }
    this.setState({
      ...data
    }, () => {
      this.props.form.setFieldsValue({
        name: data.name,
        dst_hosts: data.dst_hosts || [],
        ports: data.ports,
        protocol: data.protocol,
      })
    })
  }
  state = {
    name: '',
    dst_hosts: [],
    hostError: '',
    ports: '',
    protocol: '',
  }
  deBonceCheckHost = (e) => {
    const value = e.target.value
    if (!this.debounceCheckHostFunc) {
      this.debounceCheckHostFunc = Method.debounce(this.checkHost)
    }
    this.debounceCheckHostFunc(value)
  }
  checkHost = (value) => {
    const {hostError} = this.state
    let errorText = ''
    if (!value.match(HostRegex) && value !== '') {
      errorText = 'Format error'
    }
    this.setState({
      hostError: errorText,
    })
  }
  addHost = async (value) => {
    const isError = this.state.hostError !== ''
    if (!value || isError) {
      return
    }
    this.props.form.setFieldsValue({
      host: '',
    })
    const { dst_hosts } = this.state
    dst_hosts.push(value)
    this.setState({
      dst_hosts: dst_hosts,
    })
  }
  removeHost = (index) => {
    const { dst_hosts } = this.state
    dst_hosts.splice(index, 1)
    this.setState({
      dst_hosts: dst_hosts,
    })
  }
  render () {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const { getFieldDecorator } = this.props.form;
    return(
      <Modal
        title={this.state.id ? 'Edit Rule' : 'New Rule'}
        maskClosable={false}
        width={800}
        visible={true}
        onOk={this.handleOk}
        okText="Save"
        onCancel={this.handleCancel}
      >
        <Form {...formItemLayout}>
          {this.state.id ? <Form.Item label="ID">
            {this.state.id}
          </Form.Item> : null}
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please enter a name!',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Hosts" help={this.state.hostError} validateStatus={this.state.hostError? 'error' : ''} style={{marginBottom: 0}}>
            {getFieldDecorator('host', {
              rules: [
                {
                  required: false,
                  message: 'Please input hosts!',
                },
              ],
            })(
              <Search
                placeholder="Input a host"
                enterButton="Add"
                onChange={(e => this.deBonceCheckHost(e))}
                onSearch={value => this.addHost(value)}
              />
            )}
          </Form.Item>
          <Form.Item colon={false} label={` `}>
            <div style={{width: '100%', height: '200px',lineHeight:'0',border:'1px solid #eee',borderRadius:'4px',padding:'6px 2px',overflowY:'scroll',}}>
              {this.state.dst_hosts.map((host, index) => {
                return <span className="rulesList" key={index} style={{width:'100%',display:'inline-block',padding:'0 6px',lineHeight:'24px',fontSize:'14px',cursor:'pointer',}}>
                  <div style={{float: 'left',}}>{host}</div>
                  <a href="javascript:;" className="remove" onClick={()=>{this.removeHost(index)}} style={{float: 'right',display: 'none'}}>
                    <Icon type="close" />
                  </a>
                </span>
              })} 
            </div>
          </Form.Item>
          <Form.Item label="Ports">
            {getFieldDecorator('ports', {
              rules: [
                {validator: Ports},
              ],
            })(<Input placeholder="65535,1-65535"/>)}
          </Form.Item>
          <Form.Item label="Protocol">
            {getFieldDecorator('protocol', {
              initialValue: 0,
            })(
              <Radio.Group style={{width: '100%',}}>
                <Radio.Button style={{width: '33.33%',textAlign: 'center',}} value={0}>All</Radio.Button>
                <Radio.Button style={{width: '33.33%',textAlign: 'center',}} value={6}>TCP</Radio.Button>
                <Radio.Button style={{width: '33.33%',textAlign: 'center',}} value={17}>UDP</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}
const MainForm = Form.create()(Main)
export default MainForm;