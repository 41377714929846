import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Icon, Switch, Popover } from 'antd'
import { Router, Route, Link, withRouter, Redirect, Switch as SwitchRouter } from 'react-router-dom'
import Deployment from './deployment'
import Edges from './edges'
import Policies from './policies'
import Dashboard from './dashboard'
import System from './system'
import Statistics from './statistics'

class Main extends Component {
  componentDidMount() {
  }
  componentWillUnmount() {}
  logout = () => {
    sessionStorage.removeItem('token')
    this.props.history.push('/controller/login')
  }
  state = {
    url: '',
    user: {
      name: sessionStorage.getItem('name'),
    },
  }
  render () {
    const {match} = this.props
    const { Header, Content, Sider } = Layout;
    const getHostname = () => {
      let path = this.props.location.pathname
      if (path.indexOf('system') !== -1) {
        path = '/system'
      }
      if (path.indexOf('policies') !== -1) {
        path = '/policies'
      }
      return path
    }
    const content = (<div>
      <p style={{cursor:'pointer'}} onClick={this.logout}>Logout</p>
    </div>)
    return(
      <div style={{width: '100%', minHeight: '100%',display:"flex",}}>
        <Layout style={{width: '100%',}}>
          <Header style={{
            display: 'flex',
            alignItems: 'center',
            height: "54px",
            padding: "0 30px",
            background: "rgb(66,66,66)",
          }}>
            <div className="logo" style={{width: 120, float: "left",height: 54,overflow: "hidden"}}>
              <img style={{width: 100,marginBottom: 12}} src={require('../assets/images/logo_white.png')} alt=""/> 
            </div>
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={[getHostname()]}
              style={{
                lineHeight: "54px",
                background: "rgb(66,66,66)",
                fontWeight: 600,
                pointerEvents: this.state.events
              }}
            >
              <Menu.Item key={`${match.url}/dashboard`}>
                <Icon type="bar-chart" />
                <Link  style={{display:'inline-block'}} to={`${match.url}/dashboard`}>Dashboard</Link>
              </Menu.Item>
              <Menu.Item key={`${match.url}/edges`}>
                <Icon type="hdd" />
                <Link style={{display:'inline-block'}} to={`${match.url}/edges`}>Edges</Link>
              </Menu.Item>
              <Menu.Item key={`${match.url}/deployment`}>
                <Icon type="cluster" />
                <Link style={{display:'inline-block'}} to={`${match.url}/deployment`}>Deployment</Link>
              </Menu.Item>
              <Menu.Item key={`/policies`}>
                <Icon type="deployment-unit" />
                <Link style={{display:'inline-block'}} to={`${match.url}/policies/security`}>Policies</Link>
              </Menu.Item>
              <Menu.Item key={`${match.url}/statistics`}>
                <Icon type="line-chart" />
                <Link style={{display:'inline-block'}} to={`${match.url}/statistics`}>Statistics</Link>
              </Menu.Item>
              <Menu.Item key={`/system`}>
                <Icon type="appstore" />
                <Link style={{display:'inline-block'}} to={`${match.url}/system/info`}>System</Link>
              </Menu.Item>
            </Menu>
            <div style={{position:'absolute',right: 60,}}>
              <Popover content={content} title={this.state.user.name} placement="bottomRight">
                <Icon type="user" onMouseEnter={()=>{if (!this.state.user.name) {this.setState({user:{name: sessionStorage.getItem('name')}})}}} style={{color: 'rgba(255, 255, 255, 0.65)',fontSize:'18px',float:'right',cursor:'pointer'}}/>
              </Popover>
            </div>
          </Header>
          <Layout>
            <SwitchRouter>
              <Route path={`${match.url}/dashboard`} component={Dashboard}/>
              <Route path={`${match.url}/edges`} component={Edges}/>
              <Route path={`${match.url}/deployment`} component={Deployment}/>
              <Route path={`${match.url}/policies`} component={Policies}/>
              <Route path={`${match.url}/system`} component={System}/>
              <Route path={`${match.url}/statistics`} component={Statistics}/>
              <Redirect to={`${match.url}/dashboard`}/>
            </SwitchRouter>
          </Layout>
        </Layout>
      </div>
    )
  }
}

export default Main;