const View = {
  line: {
    base: {
      style: {
        lineWidth: 8,
        strokeOpacity: 0.8,
        lineDash: [ 5, 5 ],
        cursor: 'grab',
      }
    },
    logic: {
      sourceAnchor: 0,
      targetAnchor: 0,
      style: {
        lineWidth: 2,
        stroke: '#25b864',
        strokeOpacity: 0.5,
        lineDash: [ 3, 3 ],
        cursor: 'grab',
      }
    },
    edit: {
      style: {
        lineWidth: 8,
        stroke: '#1890FF',
        strokeOpacity: 0.6,
        lineDash: [ 5, 5 ],
        cursor: 'grab',
      }
    },
    state: [
      {
        style: {
          stroke: '#BFBFBF',
        },
      },
      {
        style: {
          stroke: '1890FF',
        },
      },
      {
        style: {
          stroke: '#1890FF',
          lineDash: null,
        },
      },
      {
        style: {
          stroke: '#f44336',
          strokeOpacity: 0.5,
        },
      },
    ],
  },
  spoke: {
    base: {
      size: [60, 90],
      shape: 'image',
      img: require('../../../assets/images/spoke-gray.svg'),
    },
    view: {},
    edit: {
      style: {
        cursor: 'pointer',
      },
    },
    state: [
      {
        img: require('../../../assets/images/spoke-gray.svg'),
        stroke: '#BFBFBF',
      },
      {
        img: require('../../../assets/images/spoke-blue.svg'),
        stroke: '1890FF',
      },
      {
        stroke: '#1890FF',
      },
      {
        stroke: '#f44336',
      },
    ],
  },
  hub: {
    base: {
      size: [65, 60],
      shape: 'image',
      img: require('../../../assets/images/hub-gray.svg'),
    },
    view: {
    },
    edit: {
      style: {
        cursor: 'pointer',
      },
    },
    state: [
      {
        img: require('../../../assets/images/hub-gray.svg'),
        stroke: '#BFBFBF',
      },
      {
        img: require('../../../assets/images/hub-blue.svg'),
        stroke: '1890FF',
      },
      {
        stroke: '#1890FF',
      },
      {
        stroke: '#f44336',
      },
    ],
  },
  delegateNode: {
    shape: 'rect',
    size: [80, 36],
    style: {
      fill: '#F3F9FF',
      fillOpacity: 0.5,
      stroke: '#1890FF',
      strokeOpacity: 0.9,
      lineDash: [ 5, 5 ],
      cursor: 'pointer',
    }
  },
}

View.line.view = {...View.line.base, ...View.line.view}
View.line.edit = {...View.line.base, ...View.line.edit}
View.spoke.view = {...View.spoke.base, ...View.spoke.view}
View.spoke.edit = {...View.spoke.base, ...View.spoke.edit}
View.hub.view = {...View.hub.base, ...View.hub.view}
View.hub.edit = {...View.hub.base, ...View.hub.edit}
const GetGraphData = (Size, Data, Model) => {
  let data = {
    nodes: [],
    edges: [],
  }
  let size = []
  let size_a = []
  let size_b = []
  for (let index = 0; index < Data.nodes.length; index++) {
    let node = Data.nodes[index];
    let id = node.edge_sn
    let x = node.coordinate ? node.coordinate[0] : index + 1
    let y = node.coordinate ? node.coordinate[1] : 0
    let object = {
      id: id,
      x: x,
      y: y,
    }
    if (node.kind === 1) {
      size.splice(0, 0, object)
    } else {
      if (y === 0) {
        let i = size_a.findIndex(array => {
            return x < array.x
        })
        if (i === -1) {
          size_a.splice(size_a.length, 0, object)
        } else {
          size_a.splice(i, 0, object)
        }
      }
      if (y === 1) {
        let i = size_b.findIndex(array => {
            return x < array.x
        })
        if (i === -1) {
          size_b.splice(size_b.length, 0, object)
        } else {
          size_b.splice(i, 0, object)
        }
      }
    } 
  }
  size_b = size_b.reverse()
  size = [...size, ...size_a, ...size_b]
  Data.nodes.map(node => {
    const length = size.length
    let index = size.findIndex(element => {
      return element.id === node.edge_sn
    })
    let local_subnets = node.local_subnets ? node.local_subnets : null
    let xy = GetXY(Size, [index, length], Model)
    let bypass = {}
    let dns = {}
    let extra_routes = {}
    let snat = {}
    let tunnnelSources = {}
    if (node.hasOwnProperty('bypass_deploy')) {
      bypass.bypass_deploy = node.bypass_deploy
    }
    if (node.hasOwnProperty('dns_server')) {
      dns.dns_server = node.dns_server
    }
    if (node.hasOwnProperty('extra_routes')) {
      extra_routes.extra_routes = node.extra_routes
    }
    if (node.hasOwnProperty('snat')) {
      snat.snat = node.snat
    }
    if (node.hasOwnProperty('tunnel_sources')) {
      tunnnelSources.tunnel_sources = node.tunnel_sources
    }
    data.nodes.push({
      id: node.edge_sn,
      kind: node.kind,
      setting: local_subnets ? {
        local_subnets: {
          addr: local_subnets[0].addr,
          addr2: local_subnets[0].addr2,
        },
        kind: node.kind,
        sg_id: node.sg_id,
        ...dns,
        ...bypass,
        ...extra_routes,
        ...snat,
        ...tunnnelSources,
        data: {
          ...node,
        },
      } : {},
      x: xy[0],
      y: xy[1],
    })
  })
  Data.links.map((link, index) => {
    let id = link.id || Date.now() + index
    let tcpOptimize = {}
    if (link.hasOwnProperty('tcp_optimize')) {
      tcpOptimize.tcp_optimize = link.tcp_optimize
    }
    data.edges.push({
      id: id,
      target: link.client_sn,
      source: link.server_sn,
      setting: {
        kind: link.kind,
        ...tcpOptimize,
        data: {
          ...link,
        },
      }
    })
  })
  return data
}
const GetXY = (size,  format, model) => {
  let Padding = 60
  switch (model) {
    case 'view':
      Padding = 35
      break;
  }
  if (format[1] === 2) {
    const x = format[0] === 0 ? Padding * 3 : size.w - Padding * 3
    return [x, size.h / 2]
  }
  const radius = (size.w > size.h ? size.h : size.w) / 2 - 60
  const center = [size.w / 2, size.h / 2]
  const angle = 360 / (format[1] - 1)
  if (format[0] === 0) {
    return center
  }
  const x = center[0] - radius * Math.cos(format[0] * angle * Math.PI / 180).toFixed(3)
  const y = center[1] - radius * Math.sin(format[0] * angle * Math.PI / 180).toFixed(3)
  return [x, y]
}
const LogicLinks = {
  newLinks: (type, links, id, selectedArray) => {
    let newLinks = null
    switch (type) {
      case 'delete':
        newLinks = links.filter(link => {
          if (link.node1 === id || link.node2 === id) {
            return false
          } else {
            return true
          }  
        })
        return newLinks
      case 'edit':
        newLinks = links.filter(link => {
          if (link.node1 === id || link.node2 === id) {
            let target = link.node1 === id ? link.node2 : link.node1
            let index = selectedArray.indexOf(target) 
            if (index === -1) {
              return false
            } else {
              selectedArray.splice(index, 1)
              return true
            }
          } else {
            return true
          }
        })
        selectedArray.map(e => {
          newLinks.push({
            node1: id,
            node2: e,
          })
        })
        return newLinks
      case 'add':
        newLinks = links
        selectedArray.map(ele => {
          if (ele.id !== id) {
            newLinks.push({
              node1: id,
              node2: ele.id,
            })
          }
        })
        return newLinks
      default:
        return links
    }
  },
  selectedArray: (links, id) => {
    let selectedArray = []
    links.map(link => {
      if (link.node1 === id || link.node2 === id) {
        selectedArray.push(link.node1 === id ? link.node2 : link.node1)
      }
    })
    return selectedArray
  }
}
export {
  View,
  GetGraphData,
  LogicLinks,
}