import React, { Component } from 'react';
import Edge from './edge'
import withContext from '../../../common/context/withContext'

class Main extends Component {
  componentDidMount() {
    this.list()
  }
  componentWillUnmount() {}
  static getDerivedStateFromProps(props, state) {
    if (props.removeItemId !== null) {
      const edges = state.edges
      const newEdges = edges.filter(ele => {
        if (ele.sn === props.removeItemId) {
          ele.disable = false
        }
        return true
      })
      state.edges = newEdges
      props.setValue({removeItemId: null}, () => {
        state.edges = newEdges
        return state
      })
    }
    if (props.addItemId !== null) {
      const edges = state.edges
      const newEdges = edges.filter(ele => {
        if (ele.sn === props.addItemId) {
          ele.disable = true
        }
        return true
      })
      state.edges = newEdges
      props.setValue({addItemId: null}, () => {
        state.edges = newEdges
        return state
      })
    }
    return null
  }
  list = () => {
    const edges = JSON.parse(JSON.stringify(this.props.edges))
    this.setState({
      edges: edges,
    })
  }
  state = {
    edges: [],
  }
  render () {
    return(
      <div style={{
        top: 0,
        left: 0,
        bottom: 0,
        width: 200,
        borderRight: '1px solid #e8e8e8',
        background: '#f0f2f5',
        position: 'absolute', 
        textAlign: 'center',
      }}>
        <span style={{marginTop: '10px',display: 'inline-block'}}>Edge List</span>
        <div
          style={{
            display: 'flex',
            position: 'absolute', 
            bottom: 0,
            left: 0,
            right: 0,
            top: 40,
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
          }}        
        >
          {
            this.state.edges.map((edge, index)=> {
              if (edge.work_mode === this.props.basic.mode) {
                return <Edge key={index} data={edge}/>
              } else {
                return null
              }
            })
          }
        </div>
      </div>
    )
  }
}

export default withContext(Main);