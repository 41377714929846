const formatDate = (now, type) => {
  var year = now.getFullYear()
  var month = now.getMonth() + 1
  var date = now.getDate()
  var hour = now.getHours()
  var minute = now.getMinutes()
  var second = now.getSeconds()
  if (type === 'UTC') {
    year = now.getUTCFullYear()
    month = now.getUTCMonth() + 1
    date = now.getUTCDate()
    hour = now.getUTCHours()
    minute = now.getUTCMinutes()
    second = now.getUTCSeconds()
  }
  if (hour < 10) {
    var ihour = '0' + hour
  } else {
    ihour = hour
  }
  if (minute < 10) {
    var iminute = '0' + minute
  } else {
    iminute = minute
  }
  if (second < 10) {
    var isecond = '0' + second
  } else {
    isecond = second
  }
  if (type === 'date') {
    return year + '/' + month + '/' + date
  } else if (type === 'day') {
    return ihour + ':' + iminute + ':' + isecond
  } else {
    return year + '/' + month + '/' + date + '  ' + ihour + ':' + iminute + ':' + isecond
  }
}
const bandwidthSwitch = (bw) => {
  if (bw === 0) {
    return '0 b/s'
  } else {
    var k = 1000
    var sizes = ['b/s', 'Kb/s', 'Mb/s', 'Gb/s', 'Tb/s', 'Pb/s', 'Eb/s', 'Zb/s', 'Yb/s']
    var q = Math.floor(Math.log(bw) / Math.log(k))
    if (q < 0) {
      var size = bw + ' ' + 'B/s'
    } else {
      size = (bw / Math.pow(k, q)).toPrecision(4) + ' ' + sizes[q]
    }
    return size
  }
}
const timeSwitch = (t, type) => {
  if (t < 9999999999) {
    var t = t * 1000
  }
  var newDate = new Date(t)
  var Time = formatDate(newDate, 'time')
  if (type === 'UTC') {
    Time = formatDate(newDate, 'UTC')
  }
  return Time
}
const dateSwitch = (t) => {
  if (t < 9999999999) {
    var t = t * 1000
  }
  var newDate = new Date(t)
  var Time = formatDate(newDate, 'date')
  return Time
}
const daySwitch = (t) => {
  if (t < 9999999999) {
    var t = t * 1000
  }
  var newDate = new Date(t)
  var Time = formatDate(newDate, 'day')
  return Time
}
const trafficSwitch = (bytes) => {
  if (bytes === '-') {
    return 'No data'
  }
  if (bytes === 0) {
    return '0 B'
  } else {
    var k = 1000
    var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    var q = Math.floor(Math.log(bytes) / Math.log(k))
    var size = (bytes / Math.pow(k, q)).toPrecision(4) + ' ' + sizes[q]
    if (!sizes[q]) {
      size = ''
    }
    return size
  }
}
const timeFormatSwitch = (t) => {
  if (t < 0) {
    t = 0
  }
  if (t === 0) {
    return '0s'
  } else {
    var hour = ''
    var minute = ''
    var second = ''
    if (t >= 3600) {
      hour = Math.floor(t / 3600) + 'h'
    }
    if (t % 3600 >= 60) {
      minute = Math.floor(t % 3600 / 60) + 'm'
    }
    if (t % 60 === 0) {
      second = ''
    } else {
      second = t % 60 + 's'
    }
    return hour + minute + second
  }
}
const timeModelSwitch = (t, type) => {
  if (t < 9999999999) {
    var t = t * 1000
  }
  let time = new Date(t)
  let s = Math.floor(new Date(t)/1000) - time.getHours()*3600 - time.getMinutes()*60 - time.getSeconds()
  switch (type) {
    case 'end':
      return s + 86399
    case 'hour':
      return s + time.getHours()*3600
    case 'minute':
      return s + time.getHours()*3600 + time.getMinutes()*60
    case 'second':
      return s + time.getHours()*3600 + time.getMinutes()*60 + time.getSeconds()
    default:
      return Math.floor(new Date(t)/1000)
  }
}
const BandwidthSwitch = (bw) => {
  if (bw === 0) {
    return '0 B/s'
  } else {
    var k = 1000
    var sizes = ['B/s', 'KB/s', 'MB/s', 'GB/s', 'TB/s', 'PB/s', 'EB/s', 'ZB/s', 'YB/s']
    var q = Math.floor(Math.log(bw) / Math.log(k))
    if (q < 0) {
      var size = bw + ' ' + 'B/s'
    } else {
      size = (bw / Math.pow(k, q)).toPrecision(4) + ' ' + sizes[q]
    }
    return size
  }
}
const GetSeconds = (t, type) => {
  if (t < 9999999999) {
    var t = t * 1000
  } 
  const Time = new Date(t)
}
const bps = (bw) => {
  if (bw === 0) {
    var value = {
      value: 0,
      unit: 0
    }
  } else {
    var k = 1000
    var q = Math.floor(Math.log(bw) / Math.log(k))
    if (q > 3) {
      q = 3
    }
    var size = (bw / Math.pow(k, q))
    value = {
      value: size,
      unit: q
    }
  }
  return value
}
const IpInfo = function(ip) {
  const ipArray = ip.split('/')
  let subnetRange = parseInt(ipArray[1])
  if (ipArray.length > 1) {
    ip = ipArray[0]
  }
  this.toBinary = () => {
    let binary = ''
    const ipArray = ip.split('.') 
    ipArray.forEach(ele => {
      let ipBinary = parseInt(ele).toString(2).padStart(8, 0)
      binary = binary + ipBinary
    })
    return binary
  }
  this.toGetNet = () => {
    const binary = this.toBinary()
    const net = binary.slice(0, subnetRange)
    return net
  }
  this.toGetNetIp = () => {
    const net = this.toGetNet()
    let ipBinary = net + '00000000000000000000000000000000'
    ipBinary = ipBinary.substring(0, 32)
    const ipList = [0, 0, 0, 0].map((ele, index) => {
      let i = index * 8
      let binary = ipBinary.substring(i, i + 8)
      return parseInt(binary, 2)
    })
    return ipList.join('.') + '/' + subnetRange
  }
}
const debounce = (fn) => {
  let timeout = null
  return function (){
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      fn.apply(this, arguments)
    }, 300)
  }
}
export default {
  bandwidthSwitch,
  timeSwitch,
  dateSwitch,
  daySwitch,
  trafficSwitch,
  timeFormatSwitch,
  timeModelSwitch,
  BandwidthSwitch,
  GetSeconds,
  bps,
  IpInfo,
  debounce,
}
