import React, { Component } from 'react';
import { Layout, Modal, Form, Table, Input, Checkbox, Switch, Select, Icon} from 'antd'
import Api from '../../global/systemApi'
import Lang from '../../global/language'

const createForm = Form.create;
const FormItem = Form.Item;
const Option = Select.Option;
class Main extends Component {
  state = {
    values: this.props.data.info
  }
  componentDidMount() {
    this.props.onRef(this)
    this.init()
  }
  componentWillUnmount() {
  }
  init = () => {
    const {values} = this.state
    const form = {
      name: {value: values.name},
      is_managed: {value: values.is_managed},
    }
    if (this.props.data.type !== 'init') {
      form.location = {value: values.location}
      form.isp_ip = {value: values.isp_ip}
      form.description = {value: values.description}
    }
    this.props.form.setFields({
      ...form,
    })
  }
  save = (callback) => {
    this.handleOk(() => {
      callback()
    })
  }
  handleOk = (callback) => {
    this.props.form.validateFields((errors, values) => {
      if (!!errors) {
        return;
      }
      const location = values.location === '' ? null : this.props.locations.find(ele => {return ele.location === values.location})
      let params = {
        id: this.props.id,
        name: values.name,
        is_managed: values.is_managed,
      }
      if (this.props.data.type !== 'init') {
        params.location = values.location
        params.geo = location ? location.geo : [0 , 0]
        params.isp_ip = values.isp_ip
        params.description = values.description
      }
      Api.setEdge(params, response => {
        this.props.refresh()
        callback()
      })
    })
  }
  render () {
    const { getFieldProps } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
    return(
      <div>
        <Form>
          <FormItem
            label={Lang.system.l143}
            {...formItemLayout}
            required
          >
            <Switch {...getFieldProps('is_managed',{ valuePropName: 'checked'})} />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={Lang.system.l138}
          >
            <Input {...getFieldProps('name',{
              rules: [
                {
                  required: true,
                  message: `Please Input Name!`
                }
              ],
            })}/>
          </FormItem>
          {this.props.data.type !== 'init' ?
            <div>
              <FormItem
                {...formItemLayout}
                label={Lang.system.l139}
              >
                <Select
                  {...getFieldProps('location')}
                    defaultValue={''}
                    showSearch
                >
                  <Option value='' key={0} >无</Option>
                  {this.props.locations.length !== 0 ? this.props.locations.map((ele, index) => {
                    return <Option value={ele.location} key={index + 1} >{ele.location}</Option> 
                  }): null}
                </Select>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={Lang.system.l258}
              >
                <Input {...getFieldProps('isp_ip',{
                  rules: [
                    { pattern: /((?:(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d))\.){3}(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d)))/, message: Lang.notice.l111}
                  ],
                })}/>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={Lang.system.l147}
              >
                <Input type="textarea" {...getFieldProps('description')}/>
              </FormItem>
            </div>
            : null 
          }
        </Form>
      </div>
    )
  }
}

Main = createForm()(Main);
export default Form.create()(Main)