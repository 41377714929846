import React, { Component } from 'react';
import {Icon} from 'antd'
import withContext from '../../../common/context/withContext'
import '../../../style/components/menu.css'

class Main extends Component {
  componentDidMount() {
  }
  componentWillUnmount() {}
  static getDerivedStateFromProps(props, state) {
    const menu = props.menu
    if (menu) {
      return {
        style: {
          display: 'block',
          left: menu.x,
          top: menu.y,
        }
      }
    } else {
      return {
        style: {
          display: 'none',
        }
      }
    }
  }
  componentDidUpdate(){
  }
  removeItem = () => {
    const type = this.props.menu.type === 'node' ? 'node' : 'edge'
    this.props.removeItem(type, this.props.menu.id)
  }
  setting = () => {
    this.props.setValue({settingId: {id: this.props.menu.id, type: this.props.menu.type}})
  }
  state = {
    style: {
    },
  }
  render () {
    return(
      <div
        style={this.state.style}
        className="menu"
      >
        <div className="cell" onClick={this.setting}>
          <Icon type="setting" className="icon"/>
          Setting
        </div>
        <div className="cell" style={{display: this.props.menu && this.props.menu.type === 'node'? 'block': 'none'}} onClick={this.removeItem}>
          <Icon type="delete" className="icon"/>
          Delete
        </div>
      </div>
    )
  }
}

export default withContext(Main);