import React, { Component } from 'react';
import { Empty, Button, Radio, Timeline, DatePicker, Icon } from 'antd';
import Api from '../../global/api';
import moment from 'moment';

const stateColor = ['#BFBFBF', '#1890FF', '#1890FF', '#f44336']
const PingStatus = ['Idle', 'Connecting', 'Connected', 'Disconnected']
class Main extends Component {
  state = {
    edges: {},
    feeds: [],
    view: {
      time: 3600,
      timeRange: [null, null],
    },
    cursor: null,
    stateList: [],
  }

  componentDidMount() {
    this.viewInit()
  }

  viewInit = () => {
    const {view} = this.state
    this.setState({
      view: {
        ...view,
        time: 3600,
      }
    },() => {
      this.setTimeRangeModel(3600)
    })
  }

  timeChange = (e) => {
    const {view} = this.state
    this.setState({
      view: {
        ...view,
        time: e.target.value,
      }
    }, () => {
      this.setTimeRangeModel(e.target.value)
    })
  }

  timeRangeChange = (value) => {
    const {view} = this.state
    let timeRangeSeconds = value[0].diff(value[1], 'days')
    if (Math.abs(timeRangeSeconds) > 31) {
      return
    }
    this.setState({
      view: {
        ...view,
        timeRange: value,
      }
    })
  }

  setTimeRangeModel = (value) => {
    let timeRange = [null, null]
    const {view} = this.state
    switch (true) {
      case value <= 24 * 60 * 60:
        timeRange = [moment().subtract(value / 3600, 'hours'), moment()]
        break;
      default:
        timeRange = [moment().subtract(value / (3600 * 24), 'days'), moment()]
        break;
    }
    this.setState({
      view: {
        ...view,
        timeRange: timeRange,
      }
    }, () => {
      this.search('init')
    })
  }

  search = async(type) => {
    const {edge1, edge2} = this.props
    let {view, cursor, noData, stateList} = this.state
    const params = {}
    params.size = 20
    // params.beg = moment(view.timeRange[0]).utc().startOf('minute').valueOf() / 1000
    // params.end = moment(view.timeRange[1]).utc().startOf('minute').valueOf() / 1000
    if (type === 'init') {
      stateList = []
    } else {
      params.cursor = cursor
    }
    const data = await this.getDataAsync(params, edge1.sn, edge2.sn)
    let seriesValue = data.series || []
    const cursorValue = data.cursor.cursor || null
    const noDataValue = data.cursor.no_data
    seriesValue.map(ele => {
      stateList.push({
        ...ele,
        color: stateColor[ele.state] || 0, 
      })
    })
    this.setState({
      cursor: cursorValue,
      noData: noDataValue,
      stateList: stateList,
    })
  }
  
  getDataAsync = async(params, src, dst) => {
    params.src = src
    params.dst = dst
    const response = await Api.get.links_state(params)
    if (response.status !== 200) { return [] }
    return response.data
  }


  disabledDate = (current) => {
    return current && current > moment().endOf('day')
  }

  componentWillUnmount() {}

  render () {
    return(
      <div style={{width: '100%',}}>
        {/* <div style={{marginBottom: 20,}}>
          <div style={{display: 'inline-block', marginTop: 10,marginRight: 20}}>
            <Radio.Group value={this.state.view.time} style={{marginRight: 20,}} onChange={this.timeChange}>
              <Radio.Button value={3600}>1 Hour</Radio.Button>
              <Radio.Button value={3600 * 6}>6 Hours</Radio.Button>
              <Radio.Button value={3600 * 12}>12 Hours</Radio.Button>
              <Radio.Button value={3600 * 24}>1 Day</Radio.Button>
              <Radio.Button value={3600 * 24 * 7}>7 Days</Radio.Button>
              <Radio.Button value={3600 * 24 * 14}>14 Days</Radio.Button>
              <Radio.Button value={3600 * 24 * 31}>31 Days</Radio.Button>
            </Radio.Group>
          </div>
          <div style={{display:'inline-block',marginTop: 10}}>
            <span style={{marginRight:'10px'}}>
              From:
            </span>
            <DatePicker.RangePicker
              value={this.state.view.timeRange}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              placeholder={['Start Time', 'End Time']}
              onChange={this.timeRangeChange}
              onOk={this.setTimeRange}
              disabledDate={this.disabledDate}
              renderExtraFooter={() => <span>* You can request the monitoring data for the 31 days.</span>}
            />
          </div>
        </div> */}
        {this.state.stateList.length === 0 ? 
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          :<div style={{display:'flex',flexDirection:'row',justifyContent:'left',flexWrap:'wrap',padding:'24px 24px 0 24px',borderRadius:'4px',background:'#fff'}}>
            <Timeline 
              pending={
                this.state.noData 
                ? ''
                : <Button type="primary" style={{ marginTop: 16 }} onClick={this.search}>
                More Data
                </Button> 
              }
              pendingDot = {
                <Icon type="loading-3-quarters" />
              }
              style={{padding: '0 20px'}}>
              {
                this.state.stateList.map((ele, index) => {
                  return <Timeline.Item 
                    color={ele.color}
                    key={index}
                  >
                    <p style={{color: stateColor[ele.state],fontWeight: 600}}>
                      {PingStatus[ele.state]}
                    </p>
                    <p>
                      {`${moment(ele.time * 1000).format('YYYY-MM-DD HH:mm')}`} 
                    </p>
                    <p>
                      {`${ele.topology_name}`}
                    </p>
                  </Timeline.Item>
                })
              }
            </Timeline>
            {/* {this.state.noData ? 
              null
              : <Button type="primary" style={{ marginTop: 16 }} onClick={() => this.search}>
                More Data
              </Button> 
            } */}
          </div>
        }
      </div>          
    )
  }
}

export default Main;