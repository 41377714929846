import React, { Component } from 'react';
import Box from '../box';
import Api from '../../../global/api'
import Method from '../../../global/method'
import { Progress, Icon } from 'antd'

const Time = [
  1 * 60 * 60 * 1000,
  24 * 60 * 60 * 1000,
  7 * 24 * 60 * 60 * 1000,
  30 * 24 * 60 * 60 * 1000,
]
class Main extends Component {
  state = {
    data: [],
    title: '',
    color: '',
    max: 1,
    setting: this.props.setting,
  }
  componentDidMount() {
    this.init()
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  init = (time) => {
    this.setState({
      data: [],
    },()=>{
      const type = this.props.type
      let state = {
        title: ''
      }
      this.getData = null
      switch (type) {
        case 'upload':
          state.title = 'Top 10 Upload'
          this.getData = () => {
            let beg = Method.timeModelSwitch(Date.now() - 30 * 1000)
            let end = Method.timeModelSwitch(Date.now())
            Api.get.bandwidth({
              page_size: 10,
              page_no: 1,
              beg: beg,
              end: end,
              dir: 'output',
            }, response => {
              this.setState({
                color: '#61bc7a',
                max: response.bandwidth && response.bandwidth[0].bandwidth ? response.bandwidth[0].bandwidth : 1,
                data: response.bandwidth ? response.bandwidth : []
              })
            })
          }
          break;
        case 'download':
          state.title = 'Top 10 Download'
          this.getData = () => {
            let beg = Method.timeModelSwitch(Date.now() - 30 * 1000)
            let end = Method.timeModelSwitch(Date.now())
            Api.get.bandwidth({
              page_size: 10,
              page_no: 1,
              beg: beg,
              end: end,
              dir: 'input',
            }, response => {
              this.setState({
                color: '#508cc8',
                max: response.bandwidth && response.bandwidth[0].bandwidth ? response.bandwidth[0].bandwidth : 1,
                data: response.bandwidth ? response.bandwidth : []
              })
            })
          }
          break;
        case 'talks':
          state.title = 'Top 10 Talks'
          this.getData = () => {
            let beg = Method.timeModelSwitch(Date.now() - Time[this.state.setting.time])
            let end = Method.timeModelSwitch(Date.now())
            Api.get.traffics({
              page_size: 10,
              page_no: 1,
              beg: beg,
              end: end,
            }, response => {
              this.setState({
                // color: '#508cc8',
                color: '#7cb5ec',
                max: response.traffic && response.traffic[0].traffic ? response.traffic[0].traffic : 1,
                data: response.traffic ? response.traffic : []
              })
            })
          }
          break;
        default:
          break;
      }
      this.setState({
        ...state
      })
      this.getData()
    })
    this.intervalGetData()
  }
  intervalGetData = () => {
    this.interval = setTimeout(() => {
      this.getData()
    }, 1000 * 60)
  }
  set = (value) => {
    if (this.props.type === 'talks') {
      this.setState({
        setting: value,
      }, () => {
        this.init()
      })
    }
  }
  render () {
    const getName = (name, ip, sn) => {
      return name || ip || sn.substring(0, 6)
    }
    return(
      <Box 
        title={this.state.title}
        show={this.state.data.length !== 0}
        operation={this.props.type === 'talks' ? {time: {defaultValue: this.state.setting.time}} : null}
        subtitle={this.props.type !== 'talks' ? '30 seconds' : this.state.setting.time}
        set = {this.props.type !== 'talks' ? null : this.set}
        type={this.props.type}
      >
        {this.state.data.map((ele, index) => {
          let name = this.props.type === 'talks' ? <div
            style={{
              display: 'flex',
              width: 'calc(100% - 100px)',
              height: '100%',
            }}
          >
            <div style={{
              width: 'calc(50% - 10px)',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              paddingLeft: '5px',
              cursor: 'default'
            }}
              title={this.props.edges[ele.src_sn] && this.props.edges[ele.src_sn].conn_addr ? `IP address: ${this.props.edges[ele.src_sn].conn_addr}` : ''} 
            >
              {this.props.edges[ele.src_sn] ? 
                getName(this.props.edges[ele.src_sn].name,this.props.edges[ele.src_sn].conn_addr,ele.src_sn)
                + (this.props.edges[ele.src_sn].location ? ` (${this.props.edges[ele.src_sn].location})` : '') : ''}
            </div>
            <div 
              style={{
                width: '20px',
                textAlign: 'center',
              }}
            ><Icon type="swap-right" /></div>
            <div
              style={{
                display: 'flex',
                width: 'calc(50% - 10px)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                paddingLeft: '5px',
              }}
              title={this.props.edges[ele.dst_sn] && this.props.edges[ele.dst_sn].conn_addr ? `IP address: ${this.props.edges[ele.dst_sn].conn_addr}` : ''}
            >
              
              {this.props.edges[ele.dst_sn] ? 
                getName(this.props.edges[ele.dst_sn].name, this.props.edges[ele.dst_sn].conn_addr, ele.dst_sn)
                + (this.props.edges[ele.dst_sn].location ? ` (${this.props.edges[ele.dst_sn].location})` : ''): ''}
            </div>
          </div> : 
            <div
             style={{
                width: 160,
                height: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                paddingLeft: '5px',
                cursor: 'default',
              }}
              title={this.props.edges[ele.edge_sn] && this.props.edges[ele.edge_sn].conn_addr ? `IP address: ${this.props.edges[ele.edge_sn].conn_addr}` : ''}
            >
              {this.props.edges[ele.edge_sn] ? 
                getName(this.props.edges[ele.edge_sn].name,this.props.edges[ele.edge_sn].conn_addr,ele.edge_sn)
                + (this.props.edges[ele.edge_sn].location ? ` (${this.props.edges[ele.edge_sn].location})` : '') : ''}
            </div>
          let data = this.props.type === 'talks' ? Method.trafficSwitch(ele.traffic) : Method.bandwidthSwitch(ele.bandwidth) 
          let percent = this.props.type === 'talks' ? ele.traffic / this.state.max * 100 : ele.bandwidth / this.state.max * 100
          return <div key={index} style={{
            display: 'flex',
            height: 35,
            width: '100%',
            padding: '0 5px',
            flexWrap: 'wrap',
          }}>
            <div style={{
              width: '100%',
              height: 15,
            }}>
              <Progress type="line" strokeColor={this.state.color} percent={percent} showInfo={false} strokeWidth={10} /> 
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: 20,
                justifyContent: 'space-between', 
                alignItems: 'center',
                flexWrap: 'nowrap',
              }}
            >
              {name} 
              <div style={{width:85, textAlign:'right',paddingRight:'5px'}}>
                {data}
              </div>
            </div>
          </div>
        }
        )}
      </Box>
    )
  }
}

export default Main;