import React, { Component } from 'react';
import Box from '../box'
import echarts from 'echarts'
import Api from '../../../global/api'
import { Tooltip, Icon } from 'antd'
import Global from '../../../global/method'

const Color = ['#BFBFBF', '#1890FF', '#1890FF', '#f44336']
const worldMap = require('echarts/map/json/world.json');
const chinaMap = require('echarts/map/json/china.json');
const IconSwap = require('../../../assets/images/swap.svg')
const IconSwapLeft = require('../../../assets/images/swap-left.svg')
const IconSwapRight = require('../../../assets/images/swap-right.svg')
class Main extends Component {
  state = {
    data: [],
    edges: null,
    init: false,
    noLocationPoints: [],
    links: [],
    points: [],
    linksState: {},
  }
  componentDidMount() {
    this.drawChart('worldMap',)
  }
  componentWillUnmount() {
    clearTimeout(this.interval)
  }
  componentDidUpdate(prevProps) {
    if (Object.keys(this.props.edges).length !== 0 && !this.state.init) {
      this.init()
    }
  }
  init = () => {
    this.setState({
      init: true,
    }, () => {
      this.getData()
    })
  }
  getData = () => {
    let map = 'chinaMap'
    const isChinaMap = (geo) => {
      if (geo[0] === 0 && geo[1] === 0) {
        return true
      }
      if (73.33 < geo[0] && geo[0] < 135.05 && geo[1] > 3.51 && geo[1] < 53.33) {
        return true
      }
      return false
    } 
    Api.get.topologies(response => {
      let data = []
      let points = []
      let noLocationPoints = []
      response.networks.map(topology => {
        topology.links.map((link, index) => {
          let serverEdge = this.props.edges[link.server_sn]
          let clientEdge = this.props.edges[link.client_sn]
          if (!isChinaMap(serverEdge.geo)) {
            map = 'worldMap'
          }
          if (!isChinaMap(clientEdge.geo)) {
            map = 'worldMap'
          }
          if ((serverEdge.geo[0] !== 0 || serverEdge.geo[1] !== 0) && (clientEdge.geo[0] !== 0 || clientEdge.geo[1] !== 0)) {
            data.push({
              id: link.id,
              server_sn: link.server_sn,
              client_sn: link.client_sn,
              name: topology.name,
              kind: link.kind,
              coords: [
                serverEdge.geo,
                clientEdge.geo,
              ],
              lineStyle: {
                color: Color[link.state],
              },
            })
          }
          if (serverEdge.geo[0] !== 0 || serverEdge.geo[1] !== 0) {
            points.push(
              {
                name: serverEdge.location,
                value: [...serverEdge.geo, {name: serverEdge.name,type: 1}],
                symbolSize: 8,
              },
            )
          } else {
            noLocationPoints.push({
              name: serverEdge.name,
              type: 1,
            })
          }
          if (clientEdge.geo[0] !== 0 || clientEdge.geo[1] !== 0) {
            points.push(
              {
                name: clientEdge.location,
                value: [...clientEdge.geo, {name: clientEdge.name,type: 0}],
              }
            )
          } else {
            noLocationPoints.push({
              name: clientEdge.name,
              type: 0,
            })
          }
        })
      })
      this.setState({
        noLocationPoints: noLocationPoints, 
        links: data,
        points: points,
      }, () => {
        this.drawChart(map)
        this.getLinksStateInterval()
      })
    })
  }
  charts = () => {
    let main = document.getElementById('chart-map')
    let existInstance = echarts.getInstanceByDom(main)
    if (existInstance !== undefined) {
      return existInstance
    }
    let myChart = echarts.init(main)
    return myChart 
  }
  drawChart = (map) => {
    var myChart = this.charts()
    myChart.hideLoading()
    echarts.registerMap('worldMap', worldMap, {
    })
    echarts.registerMap('chinaMap', chinaMap, {
    })
    const links = this.state.links
    const points = this.state.points
    const option = {
      tooltip: {
        trigger: 'item',
      },
      geo: {
        map: points.length === 0 ? 'worldMap' : map,
        roam: false,
        zoom: 1.25,
        zlevel: 1,
        itemStyle: {
          normal: {
            areaColor: '#b3c3be80',
            borderColor: '#fff'
          },
          emphasis: {
            areaColor: '#fa8c15'
          }
        },
      },
      series: [
        {
          name: 'line',
          zlevel: 2,
          effect: {
            show: false,
            period: 6,
            trailLength: 0,
            symbolColor: '#fff',
            symbolSize: 3,
          },
          lineStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0, color: '#58B3CC'
              }, {
                offset: 1, color: '#F58158'
              }], false),
              width: 4,
              opacity: 0.6,
              curveness: 0.1,
            }
          },
          type: 'lines',
          data: links,
          tooltip: {
            formatter: (value) => {
              return `${this.tooltipTable(value.data)}`
            }
          },
        },
        {
          zlevel: 3,
          type: 'effectScatter',
          coordinateSystem: 'geo',
          data: points,
          symbolSize: 5,
          rippleEffect: {
            period: 5, //动画时间，值越小速度越快
            brushType: "stroke", //波纹绘制方式 stroke, fill
            scale: 3 //波纹圆环最大限制，值越大波纹越大
          },
          label: {
            normal: {
              show: true,
              position: "right", //显示位置
              offset: [5, 0], //偏移设置
              formatter: "{b}" //圆环显示文字
            },
            emphasis: {
              show: true
            }
          },
          itemStyle: {
            color: '#2290ff',
          },
          tooltip: {
            formatter: (value) => {
              return `<div>
                Edge: ${value.data.value[2].name}</br>
                Type: ${value.data.value[2].type === 0 ? 'Spoke' : 'Hub'} 
              </div>`
            }
          },
        }
      ]
    }
    myChart.setOption(option)
  }
  updateLinks = () => {
    var myChart = this.charts()
    myChart.setOption({
      series: [{
        name: 'line',
        data: this.state.links,
      }]
    })
  }
  getLinksStateInterval = (time) => {
    if (!time) {
      time = 1
    }
    clearInterval(this.interval)
    this.interval = setTimeout(()=>{
      this.interval = null
      this.getLinksState()
      this.getLinksStateInterval(5000)
    }, time)
  }
  getLinksState = () => {
    Api.get.state(response => {
      let linksState = {}
      let data = response || []
      data.forEach(ele => {
        linksState[ele.link_id] = ele
      })
      const links = this.state.links.map(link => {
        let newLink = linksState[link.id]
        if (newLink) {
          link.state = newLink.state
          link.lineStyle = {
            color: Color[newLink.state],
          }
          link.server_stats = newLink.server_stats || null
          link.client_stats = newLink.client_stats || null
        }
        return link
      })
      this.setState({
        links: links,
      }, () => {
        this.updateLinks() 
      })
    })
  }
  tooltipTable = (data) => {
    const server = this.tooltipData(data.server_stats)
    const client = this.tooltipData(data.client_stats)
    return`
      <div style="width:360px">
        ${this.tooltipTitle(data)}
        ${
          data.server_stats ? 
          `<table style="width:100%">
            <thead>
              <tr>
                <th style="padding-left:10px"></th>
                <th>
                  <img src="${IconSwapRight}" />
                </th>
                <th>
                  <img src="${IconSwapLeft}" />
                </th>
              </tr>
            </thead>
            <tbody>
              ${data.kind === 1 ? ''
                : `<tr>
                  <td>Bitrate:</td>
                  <td>${server.bitrate}</td>
                  <td>${client.bitrate}</td>
                </tr>`
              }
              <tr>
                <td>RTT:</td>
                <td>${server.rtt}</td>
                <td>${client.rtt}</td>
              </tr>
              <tr>
                <td>Packet Loss:</td>
                <td>${server.loss_rate}</td>
                <td>${client.loss_rate}</td>
              </tr>
              ${data.kind === 1 ? ''
                : `<tr>
                  <td>Jitter:</td>
                  <td>${server.jitter}</td>
                  <td>${client.jitter}</td>
                </tr>`
              }
              ${data.kind === 1 ? ''
                : `<tr>
                  <td>Max Delay:</td>
                  <td>${server.max_delay}</td>
                  <td>${client.max_delay}</td>
                </tr>`
              }
            </tbody> 
          </table>`
          : `
            <div style="width:100%;color:rgba(255, 255, 255, 0.5);font-size:14px;padding-left:5px">No Data</div>
          `
        } 
      </div>
    `
  }
  tooltipData = (data) => {
    if (!data) {
      return null
    }
    return {
      bitrate: Global.bandwidthSwitch(data.bitrate),
      rtt: (data.rtt / 1000).toFixed(2) + ' ms',
      loss_rate: (data.loss_rate * 100).toFixed(2) + ' %',
      jitter: (data.jitter / 1000).toFixed(2) + ' ms',
      max_delay: (data.max_delay / 1000).toFixed(2) + ' ms',
    }
  } 
  tooltipTitle = (data) => {
    const server = this.props.edges[data.server_sn]
    const client = this.props.edges[data.client_sn]
    let Server = `<span>
      ${server.location}
      <span style="color:rgba(255, 255, 255, 0.6);font-size:16px;">${server.name}</span>
    </span>`
    let Client = `<span>
      ${client.location}
      <span style="color:rgba(255, 255, 255, 0.6);font-size:16px;">${client.name}</span>
    </span>`
    return`
      <div style="width:100%;font-size:16px;text-align:center;margin-bottom:5px">
        ${data.name}
      </div>
      <div style="width:100%;font-size:16px;overflow:hidden">
          <div style="width:calc(50% - 15px);text-align:right;float:left;">
            ${Client}
          </div>
          <span
            style="display:inline-block;width:30px;text-align:center;float:left;"
          >
            <img src="${IconSwap}" alt=""/>
          </span>
          <div style="width:calc(50% - 15px);text-align:left;float:left;">
            ${Server}
          </div>
      </div>
    `
  }
  render () {
    return(
      <Box title="Topology" show={true}>
        <div
          id="chart-map"
          style={{
            position: 'absolute',
            zIndex: 2,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }} 
        >
        </div>
        <div style={{
            zIndex: 3,
            display: 'flex',
            position: 'absolute',
            width: 325,
            height: 35,
            bottom: 0,
            left: 5,
            alignItems: 'center',
            overflow: 'hidden',
          }}>
            {this.state.noLocationPoints.map((ele, index) => {
              return  <Tooltip key={index} title={<div>Name: {ele.name}<br/>Type: {ele.type === 1 ? 'Hub' : 'Spoke'}</div>}>
                <div style={{zIndex: 3,width:'10px',height: '10px',borderRadius: '10px',background: '#1890FF',marginRight: '5px',cursor: 'pointer'}}></div>
              </Tooltip>
            })}
        </div>
      </Box>
    )
  }
}

export default Main;