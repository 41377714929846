import React, { Component } from 'react';
import { Layout, Button, Table, Popconfirm } from 'antd'
import Api from '../../../global/api'
import SetAppRules from '../../../components/setAppRules'

class Main extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    apps: [],
    version: null,
  }
  apps_predefined = async () => {
    const data = await Api.get.apps_predefined(null)
    this.setState({
      apps: data.items || null,
    })
  }
  componentDidMount() {
    this.apps_predefined()
  }
  componentWillUnmount() {}
  render () {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      // {
      //   title: 'Operation',
      //   dataIndex: 'operation',
      //   width: 160,
      //   render: (text, record) => {
      //     return <div>
      //       <a href="javascript:;" style={{marginRight: '20px',}} onClick={() => this.setApp(record)}>Edit</a>
      //       <Popconfirm
      //         placement="topRight"
      //         title="Are you sure delete this application?"
      //         onConfirm={() => this.delete(record.id)}
      //         okText="Yes"
      //         cancelText="No"
      //       >
      //         <a href="javascript:;">Delete</a>
      //       </Popconfirm>
      //     </div>
      //   }
      // }
    ]
    return(
      <Layout>
        <div style={{padding: "20px 10px 10px 10px"}}>
          {/* <Button onClick={() => this.setApp('new')}>Create Application</Button> */}
        </div>
          <Table 
            style = {{
              margin: '10px',
              background: '#fff',
            }}
            // bordered={true}
            columns={columns} 
            dataSource={this.state.apps} 
            pagination={false}
          />
        {/* {this.state.app ? <SetAppRules save={this.save} cancel={this.cancel} data={this.state.app}/>: null} */}
      </Layout>
    )
  }
}

export default Main;