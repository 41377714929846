import React from 'react';
import { Input, Form, Icon, Select, Button, InputNumber, Switch } from 'antd'
import Api from '../../global/systemApi'
import Lang from '../../global/language'
import Global from '../../global/method'

const Component = React.Component
const createForm = Form.create;
const FormItem = Form.Item;
const Option = Select.Option;

class Bandwidth extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    this.props.onRef(this)
    this.bandwidth()
  }
  bandwidth = () => {
    Api.bandwidth(this.props.id, response => {
      this.props.form.setFields({
        bandwidth: {value: Global.bps(response.bandwidth).value},
        unit: {value: Global.bps(response.bandwidth).unit},
      })
    })
  }
  setBandwidth = (callback) => {
    let params = {
      id: this.props.id,
      bandwidth: this.props.form.getFieldValue('bandwidth') * Math.pow(1000, this.props.form.getFieldValue('unit'))
    }
    Api.setBandwidth(params, response => {
      callback()
    })
  }
  save = (callback) => {
    this.setBandwidth(()=>{
      callback()
    })
  }
  state = {
    value: ''
  }
  render() {
    const { getFieldProps, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14 },
    };
    return (
      <div>
        <Form >
          <FormItem
            label={Lang.system.l248}
            {...formItemLayout}
          >
            <Input style={{ width: 120, marginRight: '10px'}} {...getFieldProps('bandwidth')}/>
            <Select style={{ width: 100 }}
              {...getFieldProps('unit')}
            >
              <Option value={0}>bps</Option>
              <Option value={1}>kbps</Option>
              <Option value={2}>Mbps</Option>
              <Option value={3}>Gbps</Option>
            </Select>
          </FormItem>
        </Form>
      </div>
    )
  }
}
Bandwidth = createForm()(Bandwidth);
export default Form.create()(Bandwidth)
