import React, { Component } from 'react';
import { Layout, Button, Table, Popconfirm, Modal } from 'antd'
import Api from '../../../global/api'
import SetAppRules from '../../../components/setAppRules'

const { confirm } = Modal
class Main extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    apps: null,
    app: null,
    version: null,
  }
  cancel = () => {
    this.setState({
      app: null,
    })
  }
  save = (data) => {
    if (data === 'success') {
      this.apps_custom()
      this.setState({
        app: null,
      })
    }
  }
  apps_custom = async () => {
    const data = await Api.get.apps_custom(null)
    this.setState({
      apps: data.items,
    })
  }
  componentDidMount() {
    this.apps_custom()
  }
  componentWillUnmount() {}
  setApp = (type, edit) => {
    if (type === 'new') {
      this.setState({
        app: {
          edit: true,
          id: null,
        }
      })
    } else {
      const app = type
      this.setState({
        app: {
          edit: true,
          id: app.id,
        }
      })
    }
  }
  deleteAsync = async (id, isForce) => {
    isForce = !!isForce
    await Api.delete.apps_custom(id, isForce, response => {
      if (!response.errno) {
        this.apps_custom()
        return true
      }
      if (response.errno === 400008) {
        this.showConfirm(id)
      }
      return false
    })
  }
  showConfirm = (id) => {
    Modal.destroyAll()
    const _this = this
    confirm({
      title: 'This application is being used.Are you sure to delete this application?',
      // content: 'When clicked the OK button, this dialog will be closed after 1 second',
      onOk() {
        new Promise((resolve, reject) => {
          const callback = _this.deleteAsync(id, true)
          return callback ? resolve : reject
        })
      },
      onCancel() {},
      okText: 'Yes',
      cancelText: 'No',
    })
  }
  render () {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        width: 160,
        render: (text, record) => {
          return <div>
            <a href="javascript:;" style={{marginRight: '20px',}} onClick={() => this.setApp(record)}>Edit</a>
            <Popconfirm
              placement="topRight"
              title="Are you sure to delete this application?"
              onConfirm={() => this.deleteAsync(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <a href="javascript:;">Delete</a>
            </Popconfirm>
          </div>
        }
      }
    ]
    return(
      <Layout>
        <div style={{padding: "20px 10px 10px 10px"}}>
          <Button onClick={() => this.setApp('new')}>Create Application</Button>
        </div>
          <Table 
            style = {{
              margin: '10px',
              background: '#fff',
            }}
            // bordered={true}
            columns={columns} 
            dataSource={this.state.apps} 
            pagination={false}
          />
        {this.state.app ? <SetAppRules save={this.save} cancel={this.cancel} data={this.state.app}/>: null}
      </Layout>
    )
  }
}

export default Main;