import React from 'react';
import { Input, Form, Icon, Select, Button, InputNumber, Switch, Radio, Alert } from 'antd'
import Api from '../../global/systemApi'
import Lang from '../../global/language'

const Component = React.Component
const createForm = Form.create;
const FormItem = Form.Item;
const RadioGroup = Radio.Group

class Workmode extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.onRef(this)
    this.props.form.setFields({
      work_mode: {value: this.props.data.work_mode}
    })
  }
  setWorkmode = (callback) => {
    let params = {
      work_mode: this.props.form.getFieldValue('work_mode'),
    }
    Api.setWorkmode(this.props.id, params, response => {
      callback()
    })
  }
  save = (callback) => {
    this.setWorkmode(() => {
      callback()
    })
  }
  state = {
    value: ''
  }
  render() {
    const { getFieldProps, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 14 },
    };
    return (
      <div>
        <Alert message="Changing work mode will make Edge be disconnected for a while!" type="warning" showIcon />
        <Form >
          <FormItem
            {...formItemLayout}
            label='Work Mode'
          >
            <RadioGroup {...getFieldProps('work_mode')}>
              <Radio value={0}>VPN Mode</Radio>
              <Radio value={1}>Non-VPN Mode</Radio>
            </RadioGroup>
          </FormItem>
        </Form>
      </div>
    )
  }
}
Workmode = createForm()(Workmode);
export default Form.create()(Workmode)
