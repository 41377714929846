import React, { Component } from 'react';
import withContext from '../../../common/context/withContext'
import {Icon} from 'antd'

class Main extends Component {
  componentDidMount() {
    this.list()
  }
  componentWillUnmount() {}
  list = () => {}
  render () {
    return(
      <div style={{
        top: 0,
        left: 200,
        height: 46,
        right: 0,
        display: 'flex',
        padding: '0 20px',
        borderBottom: '1px solid #e8e8e8',
        position: 'absolute', 
      }}>
        <span style={{
          cursor: 'pointer',
          fontSize: '16px',
        }}>
          {/* <Icon type="retweet" style={{fontSize: '18px', fontWeight: 600,}} /> Format */}
        </span>
      </div>
    )
  }
}

export default withContext(Main);