import React, { Component } from 'react';
import { Layout, Select, Radio, Tabs, Empty } from 'antd';
import Charts from '../containers/statistics/charts'
import State from '../containers/statistics/state'
import Api from '../global/api';

const Option = Select.Option;

class Main extends Component {
  state = {
    edges: {},
    edges1List: [],
    edges2List: [],
    edge1: '',
    edge2: '',
    activeKey: 'charts',
  }

  componentDidMount() {
    this.edges()
  }
  onRef1 = (ref) => {
    this.child1 = ref
  }
  edges = () => {
    Api.get.edges(response => {
      let edges = {}
      let edge1 = ''
      let edges1List = []
      response.edges.map((edge, index) => {
        if (index === 0) {
          edge1 = edge.sn
        }
        edges[edge.sn] = {
          ...edge
        }
        edges1List.push({
          ...edge,
        })
      }) 
      if (this.props.location.state) {
        edge1 = this.props.location.state.sn1
      }
      this.setState({
        edges: edges,
        edges1List: edges1List,
        edge1: edge1,
      }, () => {
        this.getEdge2List(edge1)
      })
    })
  }

  getEdge2List = async(value) => {
    const response = await Api.get.links_peer_edges({sn: value})
    if (response.status !== 200) { return }
    const data = response.data
    if (!data) { 
      this.setState(
        {
          edges2List: [],
          edge2: '',
        }
      )
      return 
    }
    const {edges} = this.state
    const edges2List = []
    let edge2 = ''
    data.map(ele => {
      let edge = edges[ele]
      edges2List.push({
        ...edge,
      })
    }) 
    if (this.props.location.state) {
      edge2 = this.props.location.state.sn2
    } else {
      edge2 = edges2List[0].sn
    }
    this.setState({
      edges2List: edges2List,
      edge2: edge2,
    })
  }

  edgeChange = (value, type) => {
    if (type === 'edge1') {
      this.getEdge2List(value)
    }
    this.setState({
      [type]: value,
    })
  }

  search = () => {
  }

  changeTabs = (value) => {
    this.setState({
      activeKey: value,
    })
  }

  componentWillUnmount() {}

  render () {
    return(
      <Layout style={{padding:'0 10px 20px 10px', width:'100%',}}>
        <div style={{paddingLeft: 10, paddingTop: 20,paddingBottom: 20}}>
          <div>
            <span style={{paddingRight: 10}}>Edge A:</span>
            <Select value={this.state.edge1} style={{width: 180,marginRight: 20}} onChange={e => this.edgeChange(e, 'edge1')}>
              {this.state.edges1List.map((ele, index) => <Option key={index} value={ele.sn}>
                {ele.name} ({ele.sn})
              </Option>)} 
            </Select>
            <span style={{paddingRight: 10}}>Edge B:</span>
            <Select value={this.state.edge2} onChange={e => this.edgeChange(e, 'edge2')} style={{width: 180,marginRight: 20}} >
              {this.state.edges2List.map((ele, index) => <Option key={index} value={ele.sn}>
                {ele.name} ({ele.sn})
              </Option>)} 
            </Select>
          </div>
        </div>
        <Tabs style={{padding: '0 10px'}} onChange={this.changeTabs} type="card" activeKey={this.state.activeKey}  style={{margin: 0}}>
          <Tabs.TabPane tab="Metrics" key="charts">
            <div style={{background:'#fff',overflow:'hidden',marginTop:'-16px',padding:'12px'}}>
              {this.state.edge2 && this.state.activeKey === 'charts' ?
                <Charts edge1={this.state.edges[this.state.edge1]} edge2={this.state.edges[this.state.edge2]}/>
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              }
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Timeline" key="feed">
            <div style={{background:'#fff',overflow:'hidden',marginTop:'-16px',padding:'12px'}}>
              {this.state.edge2 && this.state.activeKey === 'feed' ?
                <State edge1={this.state.edges[this.state.edge1]} edge2={this.state.edges[this.state.edge2]}/>
                :<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              }
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Layout>
    )
  }
}

export default Main;