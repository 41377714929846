import React, { Component } from 'react';
// import Tooltip from '../../toolTip'
import { Icon, Tooltip} from 'antd'
import Box from '../box';
import Line from './line'
import Api from '../../../global/api'
import Method from '../../../global/method'

const PingStatus = ['Good', 'Ordinary', 'Bad', '3', 'Unreachable', 'No data']
class Main extends Component {
  state = {
    links: [],
    tooltip: {
      visible: false,
      target: null,
      offset: [0, 0],
      data: {},
    }
  }
  componentDidMount() {
    this.cancel = false
    this.init()
  }
  componentWillUnmount() {
    this.cancel = true
    clearInterval(this.interval)
  }
  init = () => {
    this.diagnosisData('day', () => {
      if (!this.cancel) {
        this.diagnosisData('hour')
        this.intervalDiagnosis()
      }
    })
  }
  setTooltip = (data) => {
    if (!data.target) {
      const dataOld = data
      data = this.state.tooltip
      data.visible = dataOld.visible
    }
    data.offset = [data.target.target.offsetLeft + 8, data.target.target.offsetTop + 2]
    this.setState({
      tooltip: data
    })
  }
  setClientXY = (e) => {
    this.clientXY = [e.clientX, e.clientY]
  }
  diagnosisData = (type, callback) => {
    if (this.state.links[0] && new Date(this.state.links[0].linkDay[0]).getHours() + 2 === new Date(Date.now())) {
      this.init()
      return
    }
    let params = {}
    const hour = Method.timeModelSwitch(Date.now(), 'hour')
    let data = []
    let length = 0
    switch (type) {
      case 'day':
        params ={
          beg: hour - 24 * 3600,
          end: hour,
          timegroup: '1h',
        }
        length = 24 
        data = Array.from({length}, (v, k)=> {
          let value = params.end - (k + 1) * 3600
          return [value, null]
        })
        break;
      case 'hour':
        params = {
          beg: hour,
          end: hour + 1 * 3600,
          timegroup: '10m',
        }
        length = 6 
        data = Array.from({length}, (v, k)=> {
          let value = params.end - (k + 1) * 10 * 60
          return [value, null]
        })
        break;
      case 'interval':
        const minute = new Date(Date.now()).getMinutes() % 10 * 60
        // const seconds = new Date(Date.now()).getSeconds() % 5
        params = {
          beg: Method.timeModelSwitch(Date.now(), 'minute') - minute,
          end: Method.timeModelSwitch(Date.now(), 'minute') - minute + 10 * 60,
          timegroup: '10m',
        }
        break;
      default:
        break;
    }
    Api.get.diagnosis(params, response => {
      const Links = this.state.links
      let links = [] 
      if (type === 'day') {
        response.map(
          link => {
            let linkDay = data.map(ele => {
              link.series = link.series ? link.series : []
              let value = link.series.find(e => {
                return e[0] === ele[0]
              })
              if (value) {
                return value
              }
              return ele
            })
            const linkHourIndex = Links.findIndex(ele => {
              return ele.src_sn + ele.dst_sn === link.src_sn + link.dst_sn
            })
            const linkHour = linkHourIndex !== -1 ? Links[linkHourIndex].linkHour : []
            link = {
              link_id: link.link_id,
              src_sn: link.src_sn,
              dst_sn: link.dst_sn,
              linkDay: linkDay, 
              linkHour: linkHour,
            }
            links.push(link)
          }
        )
        this.setState({
          links: links,
        })
      }
      if (type === 'hour') {
        links = Links.map(link => {
          link.linkHour = data
          return link
        })
        response.map(link => {
          let index = Links.findIndex(ele => {
            return ele.src_sn + ele.dst_sn === link.src_sn + link.dst_sn
          })
          if (index === -1) {
            this.init()
            return
          }
          let linkHour = data.map(ele => {
            link.series = link.series ? link.series : []
            let value = link.series.find(e => {
              return e[0] === ele[0]
            }) 
            if (value) {
              return value
            }
            return ele
          })
          links = Links
          links[index].linkHour = linkHour
        })
        this.setState({
          links: links
        })
      }
      if (type === 'interval') {
        response.map(link => {
          let index = Links.findIndex(ele => {
            return ele.src_sn + ele.dst_sn === link.src_sn + link.dst_sn
          })
          if (index === -1 || Date.now() - params.end * 1000 >= 0) {
            this.init()
            return
          }
          links = Links
          let linkHour = links[index].linkHour.map(ele => {
            link.series = link.series ? link.series : []
            let value = link.series.find(e => {
              return e[0] === ele[0]
            })
            if (value) {
              return value
            }
            return ele
          })
          links[index].linkHour = linkHour
        })
        if (links.length !== 0) {
          this.setState({
            links: links
          })
        }
      }
      if (callback) {
        callback(true)
      }
    })
  }
  intervalDiagnosis = (times) => {
    times = times ? times : 10000
    if (this.interval) {
      clearTimeout(this.interval)
    }
    this.interval = setTimeout(()=> {
      this.interval = null
      this.diagnosisData('interval')
      this.intervalDiagnosis()
    }, times)
  }
  render () {
    const getName = (sn) => {
      const value = this.props.edges[sn]
      if (Object.keys(this.props.edges).length === 0) {
        return ''
      }
      return (value.name || value.ip || value.sn.substring(0, 6)) + (value.location ? ` (${value.location})` : '')
    }
    return(
      <Box title="SmartTrace" show={this.state.links.length !== 0}>
        <Tooltip 
          visible={this.state.tooltip.visible}
          mouseEnterDelay={0.8}
          align={{offset: [this.state.tooltip.offset[0], this.state.tooltip.offset[1]]}}
          placement="top"
          title={
            <table>
              <tbody>
                <tr>
                  <td>End:</td>
                  <td>{this.state.tooltip.data.endTime}</td>
                </tr>
                <tr>
                  <td>Start:</td>
                  <td>{this.state.tooltip.data.startTime}</td>
                </tr>
                <tr>
                  <td>Status:</td>
                  <td>
                    {PingStatus[this.state.tooltip.data.status]}
                    <span style={{display: this.state.tooltip.data.status === 5 ? 'none' : 'inline',color: 'rgba(255, 255, 255, 0.5)',fontSize:'14px',paddingLeft:'5px'}}>code:{this.state.tooltip.data.status}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          }
          // getPopupContainer={()=>document.getElementById('smarttrace')}
        >
        </Tooltip>
        {this.state.links.map((ele, index) => {
          return (
            <div style={{display:'flex', justifyContent:'flex-start', alignContent:'center',height: 16,margin:'0 10px',}} key={index}>
              <div title={getName(ele.src_sn) + ' - ' + getName(ele.dst_sn)} 
                style={{
                  display:'flex',
                  lineHeight: '16px',
                  justifyContent:'flex-start',
                  alignContent:'center',
                  height: 16,
                  overflow:'hidden',
                  width: 'calc(100% - 512px)',
                  cursor: 'default',
                }}
              >
                <div title={this.props.edges[ele.src_sn] && this.props.edges[ele.src_sn].conn_addr ? `IP address: ${this.props.edges[ele.src_sn].conn_addr}` : ''} style={{width: 'calc(50% - 10px)',overflow: 'hidden',}}>
                  {getName(ele.src_sn)}
                </div>
                <div 
                  style={{
                    width: '20px',
                    textAlign: 'center',
                  }}
                ><Icon type="swap-right" /></div>
                <div 
                  style={{width: 'calc(50% - 10px)',overflow: 'hidden',}}
                  title={this.props.edges[ele.dst_sn] && this.props.edges[ele.dst_sn].conn_addr ? `IP address: ${this.props.edges[ele.dst_sn].conn_addr}` : ''}
                >
                  {getName(ele.dst_sn)}
                </div>
              </div>
              <div style={{width: 16}}></div>
              <Line type='1h' tooltipEl={this.setTooltip} data={ele.linkHour} style={{width: 96}}/>
              <div style={{width: 16}}></div>
              <Line type='24h' tooltipEl={this.setTooltip} data={ele.linkDay} style={{width: 384}}/>
            </div>
          )
        })}
      </Box>
    )
  }
}

export default Main;