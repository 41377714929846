import '../../style/system.css';
import React from 'react';
import { Modal, Form, Table, Input, Checkbox, Switch, Button, Select } from 'antd'
import Api from '../../global/systemApi'
import Lang from '../../global/language'
const Component = React.Component
const createForm = Form.create;
const FormItem = Form.Item;
const Option = Select.Option;

const role = [Lang.system.l113, Lang.system.l114, Lang.system.l115]
const modelData = {
  title: '',
  data: ''
}

//dd

//
class Account extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.accountsList()
  }
  columns = [{
    title: Lang.system.l133,
    dataIndex: 'name',
    key: 'name'
  }, {
    title: Lang.system.l134,
    dataIndex: 'role',
    key: 'role'
  }, {
    title: Lang.system.l112,
    dataIndex: 'start',
    key: 'start',
    render: (text, record, index) => {
      return (
        this.state.list[index].is_super ? null :
        <Checkbox  onClick={()=>this.startAccount(index)} checked={record.start}></Checkbox>
      )
    }
  },
  {
    title: Lang.system.l111,
    key: 'operation',
    render: (text, record, index) => {
      return (
        this.state.list[index].is_super ? null :
          <span>
            <span className="alter" onClick={() => this.showModal('password', index)}>{Lang.system.l121}</span>
            <span className="ant-divider"></span>
            <span className="warning" onClick={() => this.showModal('role', index)}>{Lang.system.l135}</span>
            <span className="ant-divider"></span>
            <span className="warning" onClick={() => this.delAccount(index)}>{Lang.system.l109}</span>
          </span>

      )
    }
  }
  ]
  state = {
    ModalText: 'Content of the modal',
    model: '',
    visible: false,
    confirmLoading: false,
    list: [],
    account: {
      id: '',
      role: '',
      username: '',
      is_super: '',
      password: '',
      enable: ''
    },
  }
  accountsList = () => {
    Api.accounts(response => {
      let list = []
      for (let i = 0; i < response.accounts.length; i++) {
        list.push({
          id: response.accounts[i].id,
          name: response.accounts[i].username,
          role: role[response.accounts[i].role],
          start: response.accounts[i].enable,
          is_super: response.accounts[i].is_super,
        })
      }
      this.setState({list: list})
    })
  }
  delAccount = (index) => {
    let id = this.state.list[index].id
    Api.delAccount(id, response => {
      this.accountsList()
    })
  }
  getInitialState = () => {
    return {
      ModalText: '对话框的内容',
      visible: false,
    };
  }
  startAccount = (index) => {
    let id = this.state.list[index].id
    let params = {
      enable: !this.state.list[index].start
    }
    Api.setAccount(id, params, response => {
      this.accountsList()
    })
  }
  showModal = (model, index) => {
    let data = this.state.account
    if (model === 'new') {
      modelData.title = Lang.system.l136
      data.id = ''
      this.props.form.setFields({
        username: {value: ''},
        password: {value: ''},
        password2: {value: ''},
      })
    } else if ( model === 'password' ) {
      modelData.title = Lang.system.l121
      data.id = this.state.list[index].id
      this.props.form.setFields({
        newPassword: {value: ''},
        newPassword2: {value: ''},
      })
    } else {
      modelData.title = Lang.system.l135
      data.id = this.state.list[index].id
      this.props.form.setFields({
        role1: {value: this.state.list[index].role},
      })
    }
    this.setState({
      visible: true,
      model: modelData.title,
      account: data
    });
  }
  handleOk = () => {
    this.props.form.validateFields((errors, values) => {
      if (this.state.model === Lang.system.l136) {
        if (errors.username || errors.password || errors.password2) {
          return
        }
        let params = {
          enable: true,
          username: values.username,
          password: values.password2,
          role: values.role,
          is_super: false
        }
        Api.newAccount(params, response => {
          this.accountsList()
          this.handleCancel()
        })
      }
      if (this.state.model === Lang.system.l121) {
        if (errors.newPassword || errors.newPassword2) {
          return
        }
        let id = this.state.account.id
        let params = {
          password: values.newPassword2,
        }
        Api.setAccount(id, params, response => {
          this.accountsList()
          this.handleCancel()
        })
      }
      if (this.state.model === Lang.system.l135) {
        let id = this.state.account.id
        let params = {
          role: values.role1,
        }
        Api.setAccount(id, params, response => {
          this.accountsList()
          this.handleCancel()
        })
      }
    })
  }
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  }
  checkPass = (rule, value, callback) => {
    const { getFieldValue } = this.props.form;
    if (value && value !== getFieldValue('password')) {
      callback(Lang.notice.l108);
    } else {
      callback();
    }
  }
  checkPass2 = (rule, value, callback) => {
    const { getFieldValue } = this.props.form;
    if (value && value !== getFieldValue('newPassword')) {
      callback(Lang.notice.l108);
    } else {
      callback();
    }
  }
  render() {
    const { getFieldProps } = this.props.form;
    const usernameProps = getFieldProps('username', {
      rules: [
        { required: true, message: Lang.notice.l100 }
      ],
    });
    const passwordProps = getFieldProps('password', {
      rules: [
        { required: true, message: Lang.system.l106 }
      ],
    });
    const password2Props = getFieldProps('password2', {
      rules: [
        { required: true, message: Lang.notice.l103 },
        {
          validator: this.checkPass,
        }
      ],
    });
    const newPasswordProps = getFieldProps('newPassword', {
      rules: [
        { required: true, message: Lang.notice.l107 }
      ],
    });
    const newPassword2Props = getFieldProps('newPassword2', {
      rules: [
        { required: true, message: Lang.notice.l104 },
        {
          validator: this.checkPass2,
        }
      ],
    });
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 12 },
    };
    return (
      <div className="tunnel">
        <div className="box normal">
          <div className="title">{Lang.system.l130}</div>
          <div className="main">
            <div className="line-right">
              <Button type="primary" onClick={()=>this.showModal('new')}>{Lang.system.l108}</Button>
            </div>
            <Table dataSource={this.state.list} columns={this.columns} locale={{emptyText: Lang.system.l256}} size="small"/>
          </div>
        </div>
        <Modal title={modelData.title}
          visible={this.state.visible}
          onOk={this.handleOk}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.handleCancel}
          cancelText={Lang.system.l107} 
          okText={Lang.system.l106} 
        >
        {this.state.model === Lang.system.l136?
          <Form>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l116}
            >
              <Input {...usernameProps}/>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l134}
            >
            <Select style={{ width: 200 }}
              {...getFieldProps('role',{
                initialValue: 0,
              })}
            >
              <Option value={0}>{Lang.system.l113}</Option>
              <Option value={1}>{Lang.system.l114}</Option>
              <Option value={2}>{Lang.system.l115}</Option>
            </Select>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l117}
            >
              <Input type="password" {...passwordProps}/>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l151}
            >
              <Input type="password" {...password2Props}/>
            </FormItem>
          </Form> : null
        }
        {this.state.model === Lang.system.l121?
          <Form>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l119}
            >
              <Input type="password" {...newPasswordProps}/>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l120}
            >
              <Input type="password" {...newPassword2Props}/>
            </FormItem>
          </Form> : null
        }
        {this.state.model === Lang.system.l135?
          <Form>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l134}
            >
            <Select
              {...getFieldProps('role1')}
               defaultValue={0}
            >
              <Option value={0}>{Lang.system.l113}</Option>
              <Option value={1}>{Lang.system.l114}</Option>
              <Option value={2}>{Lang.system.l115}</Option>
            </Select>
            </FormItem>
          </Form> : null
        }
        </Modal>
      </div>
    )
  }
}
Account = createForm()(Account);
export default Form.create()(Account)
