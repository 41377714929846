import '../../style/system.css';
import React from 'react';
import { Input, Form, Icon, Select, Button, InputNumber, Switch } from 'antd'
import Api from '../../global/api'
import Lang from '../../global/language'

const Component = React.Component
const createForm = Form.create;
const FormItem = Form.Item;
const Option = Select.Option;
class Netif extends Component {
  state = {
    host: null,
    protocol: null,
  }
  componentDidMount() {
    this.init()
  }
  init = () => {
    this.gateway()
  }
  gateway = () => {
    Api.get.gateway(response => {
      const getData = (url)=> {
        const urlArray = url.split('://')
        let host = ''
        let protocol = 'http'
        if (urlArray.length > 1) {
          protocol = urlArray[0]
          host = urlArray[1]
        }
        return {
          host: host,
          protocol: protocol,
        }
      }
      const url1 = response.controller_url || ''
      const url2 = response.analytics_url || ''
      this.props.form.setFields({
        host1: {value: getData(url1).host},
        protocol1: {value: getData(url1).protocol},
        host2: {value: getData(url2).host},
        protocol2: {value: getData(url2).protocol},
      }) 
    })
  }
  setNet = (type) => {
    const protocol = type === 'controller' ? 'protocol1' : 'protocol2' 
    const host = type === 'controller' ? 'host1' : 'host2' 
    const url = type === 'controller' ? 'controller_url' : 'analytics_url'
    this.props.form.validateFields([protocol, host],(errors, values) => {
      if (!!errors) {
        return;
      }
      Api.put.gateway({
        [url]: values[protocol] + '://' + values[host]
      }, response => {})
    })
  }
  render() {
    const { getFieldProps, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 12 },
    };
    const host1 = getFieldProps('host1', {
      rules: [
        { required: true, message: 'Please input URL' },
      ],
    })
    const host2 = getFieldProps('host2', {
      rules: [
        { required: true, message: 'Please input URL' },
      ],
    })

    const selectBefore1 = (
      <Select style={{ width: 90 }} {...getFieldProps('protocol1')}>
        <Option value="http">http://</Option>
        <Option value="https">https://</Option>
      </Select>
    )
    const selectBefore2 = (
      <Select style={{ width: 90 }} {...getFieldProps('protocol2')}>
        <Option value="http">http://</Option>
        <Option value="https">https://</Option>
      </Select>
    )
    const { Search } = Input;
    return (
      <div className="strategy">
        <div className="box normal">
          <div className="title">App Gateway</div>
          <div className="main">
            <Form>
              <FormItem
                label="Gateway Controller URL"
                {...formItemLayout}
              >
                <Search
                  enterButton={Lang.system.l246}
                  onSearch={()=>this.setNet('controller')}
                  addonBefore={selectBefore1}
                  {...host1}
                />
              </FormItem>
              <FormItem
                label="Analytics URL"
                {...formItemLayout}
              >
                <Search
                  enterButton={Lang.system.l246}
                  onSearch={()=>this.setNet('analytics')}
                  addonBefore={selectBefore2}
                  {...host2}
                />
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}
Netif = createForm()(Netif);
export default Form.create()(Netif)
