import '../style/system.css';
import React, { Component } from 'react';
import { Layout, Modal, Form, Table, Input, Checkbox, Switch, Select, Icon} from 'antd'
import Api from '../global/systemApi'
import Api1 from '../global/api'
import Global from '../global/method'
import Lang from '../global/language'
import Edge from '../containers/edge'

const createForm = Form.create;
const FormItem = Form.Item;
const Option = Select.Option;
class Edges extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    edge: null,
    locations: [],
    index: 0,
    visibleInfoModal: false,
    list: [],
    id: '',
  }
  columns = [{
    title: Lang.system.l137,
    dataIndex: 'sn',
  },{
    title: Lang.system.l138,
    dataIndex: 'name',
  },{
    title: 'Mode',
    dataIndex: 'work_mode',
    render: value => {
      return value === 0 ? 'VPN' : 'Non-VPN'
    }
  },{
    title: Lang.system.l139,
    dataIndex: 'location',
  },{
    title: 'IP',
    dataIndex: 'conn_addr',
  },{
    title: Lang.system.l140,
    dataIndex: 'tunnels_count',
  }, {
    title: Lang.system.l141,
    dataIndex: 'online',
    render: value => {
      const str = value ? (<Icon style={{color:"#26ae85"}} type="check" />) : ''
      return(str)
    }
  },
  {
    title: Lang.system.l142,
    dataIndex: 'online_uptime',
  }, {
    title: Lang.system.l143,
    dataIndex: 'managed',
    render: (text, record, index) => {
      return (
        <Checkbox  onClick={() => this.startEdge(record.key)} checked={record.is_managed}></Checkbox>
      )
    }
  }, {
    title: Lang.system.l111,
    dataIndex: 'operation',
    render: (text, record, index) => {
      return (
        <span>
          <a href="javascript:;" className="alter" style={{cursor: 'pointer'}} onClick={() => this.showInfoModal(record.key)}>{Lang.system.l144}</a>
          {record.is_managed ? (
          <span>
            <span className="ant-divider"></span>
            <a href="javascript:;" className="alter" style={{cursor: 'pointer'}} onClick={() => this.editEdge(record.key)}>{Lang.system.l110}</a>
          </span>
          ): ''}
        </span>
      )
    }
  }]
  edgesList = () => {
    Api.edges(response => {
      let list = []
      for (let i = 0; i < response.edges.length; i++) {
        var json = response.edges[i]
        let key = i
        list.push({
          key: key,
          no: i + 1,
          id: json.id,
          name: json.name,
          sn: json.sn,
          work_mode: json.work_mode,
          online: json.online,
          last_online_at: Global.timeSwitch(json.last_online_at),
          conn_addr: json.conn_addr,
          tunnels_count: json.tunnels_cnt,
          version: {
            sw_version: json.version.sw_version,
            hw_version: json.version.hw_version
          },
          online_uptime: Global.timeFormatSwitch(json.online_uptime),
          input_accum_traffic: Global.trafficSwitch(json.input_accum_traffic),
          output_accum_traffic: Global.trafficSwitch(json.output_accum_traffic),
          description: json.description,
          location: json.location,
          geo: json.geo,
          is_managed: json.is_managed,
          isp_ip: json.isp_ip || '',
        })
      }
      this.setState({list: list})
    })
  }
  startEdge = (index) => {
    const is_managed = this.state.list[index].is_managed
    const name = this.state.list[index].name
    if (!is_managed && !name) {
      this.editEdge(index, 'init')
      return
    }
    let params = {
      id: this.state.list[index].id,
      is_managed: !is_managed,
    }
    Api.setEdge(params, response => {
      let list = this.state.list
      list[index].is_managed = !list[index].is_managed
      this.setState({list: list})
    })
  }
  showInfoModal = (index) => {
    this.setState({
      index: index,
      visibleInfoModal: true,
    })
  }
  closeInfoModal = (index) => {
    this.setState({
      visibleInfoModal: false,
    })
  }
  editEdge = (index, type) => {
    let edge = {
      id: this.state.list[index].id,
      online: this.state.list[index].online,
      name: this.state.list[index].name || this.state.list[index].sn.substring(0, 6) + '...',
      type: type || 'edit',
      info: {
        name: this.state.list[index].name,
        is_managed: type === 'init' || this.state.list[index].is_managed,
        work_mode: this.state.list[index].work_mode,
        location: this.state.list[index].location,
        isp_ip: this.state.list[index].isp_ip,
        description: this.state.list[index].description,
      },
    }
    this.setState({
      edge: edge,
    })
  }
  onRef = (ref) => {
    this.child = ref
  }
  showTunnels = (index) => {
    this.setState({
      tunnels: {
        show: true,
        edgeId: this.state.list[index].id,
        edgeName: this.state.list[index].name,
      }
    })
    this.child.TunnelsList(this.state.list[index].id)
  }
  locations = () => {
    Api1.get.locations(response => {
      this.setState({
        locations: response ? response : []
      })
    })
  }
  componentDidMount() {
    this.edgesList()
    this.locations()
  }
  edgeCancel = () => {
    this.setState({
      edge: null,
    })
  }
  render() {
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 12 },
    };
    return (
      <Layout>
        <div style={{padding: "20px 10px 10px 20px",fontSize: "18px",}}>
          <span>{Lang.system.l143}</span>
        </div>
        <Table 
          style = {{
            margin: '10px',
            background: '#fff',
          }}
          dataSource={this.state.list.filter(ele => {
          return ele.is_managed
        })} columns={this.columns}  pagination={false}/>
        <div style={{padding: "20px 10px 10px 20px",fontSize: "18px",}}>
          <span>Unmanaged</span>
        </div>
        <Table 
          style = {{
            margin: '10px',
            background: '#fff',
          }}
          dataSource={this.state.list.filter(ele => {
          return !ele.is_managed
        })} columns={this.columns} pagination={false}/>
        {!!this.state.edge ? <Edge data={this.state.edge} cancel={this.edgeCancel} locations={this.state.locations} refresh={this.edgesList}/> : null}
        <Modal title= {Lang.system.l153}
          visible={this.state.visibleInfoModal}
          onCancel={this.closeInfoModal}
          onOk={this.closeInfoModal}
          cancelText={Lang.system.l107} 
          okText={Lang.system.l106} 
          width={600}
          footer={null}
          style={{top:'5%',}}
        >
          {this.state.list.length !== 0 ?
          <Form>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l137}
            >
              <div className="value">{this.state.list[this.state.index].sn}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l138}
            >
              <div className="value">{this.state.list[this.state.index].name}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label='Mode'
            >
              <div className="value">{this.state.list[this.state.index].work_mode === 0 ? 'VPN' : 'Non-VPN'}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l147}
            >
              <div className="value">{this.state.list[this.state.index].description}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l139}
            >
              <div className="value">{this.state.list[this.state.index].location}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l154}
            >
              <div className="value">{this.state.list[this.state.index].online ? Lang.system.l150.l0 : Lang.system.l150.l1}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l142}
            >
              <div className="value">{this.state.list[this.state.index].online_uptime}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l156}
            >
              <div className="value">{this.state.list[this.state.index].last_online_at}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l157}
            >
              <div className="value">{this.state.list[this.state.index].conn_addr}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l258}
            >
              <div className="value">{this.state.list[this.state.index].isp_ip}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l140}
            >
              <div className="value">{this.state.list[this.state.index].tunnels_count}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l158}
            >
              <div className="value">{this.state.list[this.state.index].version.sw_version}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l159}
            >
              <div className="value">{this.state.list[this.state.index].version.hw_version}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l164}
            >
              <div className="value">{this.state.list[this.state.index].input_accum_traffic}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l165}
            >
              <div className="value">{this.state.list[this.state.index].output_accum_traffic}</div>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label={Lang.system.l166}
            >
              <div className="value">{this.state.list[this.state.index].is_managed? Lang.system.l150.l0 : Lang.system.l150.l1}</div>
            </FormItem>
          </Form> : null }
        </Modal>
      </Layout>
    )
  }
}
Edges = createForm()(Edges);
export default Form.create()(Edges)
