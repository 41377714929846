import React, { Component } from 'react';
import {
  Switch, Form, Input, Radio, Button,
} from 'antd';
import withContext from '../../../common/context/withContext'

class Main extends Component {
  componentDidMount() {
    this.init()
  }
  componentWillUnmount() {}
  init = () => {
    if (Object.keys(this.props.setting.setting).length !== 0) {
      const {kind, tcp_optimize} = this.props.setting.setting
      let form = {
        kind: kind,
      }
      form.tcp_optimize_enable = tcp_optimize ? tcp_optimize.enable : false
      this.props.form.setFieldsValue({
        ...form,
      })
    }
  }
  save = () => {
    this.props.form.validateFields((errors, values) => {
      if (!!errors) {
        return;
      }
      let setting = this.props.setting
      setting.setting.kind = values.kind
      setting.setting.tcp_optimize = values.tcp_optimize_enable ? {
        enable: true
      } : {
        enable: false
      }
      this.props.setValue({
        setting: setting,
        saveEl: true,
      })
    })
  }
  cancel = () => {
    this.props.setValue({setting: null})
  }
  onChange = () => {}
  render () {
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input
    const RadioGroup = Radio.Group
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12, offset: 2 },
      },
    }
    const kindConfig = {
      initialValue: 0,
      rules: [{required: true, message: 'Please select a kind.'}]
    }
    return(
      <div className="item-cell link-edit">
        <Form {...formItemLayout}>
          <Form.Item
            label="Type"
          >
            {getFieldDecorator('kind', kindConfig)(
              <RadioGroup onChange={this.onChange}>
                {this.props.basic.mode === 0 ?
                  <div>
                    <Radio value={0} style={{paddingRight: '30px'}}>Auto</Radio>
                    <Radio value={1}>IPSec over UDP</Radio>
                    {this.props.license.r2tpSupport
                      ? <Radio value={2}>IPSec over R2TP</Radio> : null}
                  </div>
                  : <Radio value={3}>R2TP</Radio>
                }
              </RadioGroup>
            )}
          </Form.Item>
          <Form.Item
            label="TCP Optimization"
          >
            {getFieldDecorator('tcp_optimize_enable', {valuePropName: 'checked'})(
              <Switch />
            )}
          </Form.Item>
          {this.props.basic.mode === 0 ?
            <div>
              <Form.Item
                label="VPN Subnet"
              >
                {this.props.setting.setting.data && this.props.setting.setting.data.vpn_subnet ? this.props.setting.setting.data.vpn_subnet : 'Auto'}
              </Form.Item>
              {/* <Form.Item
                label="Shared Key"
              >
                Auto
              </Form.Item> */}
            </div>
            : null
          }
          <div
            style={{width: '100%', textAlign: 'center',}}
          >
            <Button 
              onClick={this.save}
              type="primary" htmlType="submit" style={{marginRight: 20}}>
              Save
            </Button>
            <Button 
              onClick={this.cancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}

Main = Form.create()(Main);
export default withContext(Main)