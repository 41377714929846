import ZhCN from './zh-CN.json'
import EnUS from './en-US.json'
import {language as Language} from '../config'

let languageText = EnUS
if (Language === 'zh-CN') {
  // languageText = ZhCN
  languageText = EnUS
}

export default languageText