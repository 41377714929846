import React, { Component } from 'react';
import { AlertColor } from '../../../global/config'
import Method from '../../../global/method'

class Main extends Component {
  componentDidMount() {
    this.init()
  }
  componentWillUnmount() {}
  init = () => {
  }
  render () {
    return(
      <div style={{display:'flex',justifyContent:'flex-start', alignContent:'center',}}>
        {this.props.data.map((value, index) => {
          return <div
            onMouseOut = {
            (e) => {
              this.props.tooltipEl({visible: false,})
            }} 
            onMouseOver = {
            (e) => {
              this.props.tooltipEl({
                visible: true,
                target: e,
                data: {
                  startTime: Method.timeSwitch(value[0]),
                  endTime: Method.timeSwitch(value[0] + (this.props.type === '1h' ? 10 * 60 : 60 * 60)),
                  status: value[1] === null ? 5 : value[1],
                }
              })
            }} 
            key={index} style={{cursor:'pointer',width:16,height:16,border:'2px solid #fff',background:AlertColor[value[1]],}}></div>
        })}
      </div>
    )
  }
}

export default Main;