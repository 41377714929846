const cidr = /^(?:(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/([0-9]|[1-2]\d|3[0-2])$/
// const ip = /((?:(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d))\.){3}(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d)))/
const ip = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
// const host = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/
const host = /^[^\.].*[\.](.*[^\.])$/

const Cidr = (rule, value, callback) => {
  if (value && !value.match(cidr)) {
    callback(new Error('Must be in CIDR format.'))
  } else {
    callback()
  }
}
const Port = (rule, value, callback) => {
  value = value.split('-')
  const errorMessage = new Error('Format error')
  if (parseInt(value[0]) === NaN) {
    callback(errorMessage)
    return
  } else if (parseInt(value[0]) > 65535) {
    callback(errorMessage)
    return
  }
  switch (true) {
    case value.length === 1:
      callback()
      break
    case value.length === 2:
      if (parseInt(value[1]) === NaN) {
        callback(errorMessage)
      } else if (parseInt(value[1]) > 65535 || parseInt(value[1]) <= parseInt(value[0])) {
        callback(errorMessage)
      } else {
        callback()
      }
      break
    case value.length > 2:
      callback(errorMessage)
      break
    default:
      callback()
  }
}
const Ports = (rule, value, callback) => {
  value = value.split(',')    
  const errorMessage = new Error('Format error')
  let hasError = false
  for (let index = 0; index < value.length; index++) {
    const ele = value[index];
    let isPort = true
    Port(null, ele, response => {
      let isError = response instanceof Error
      isPort = !isError
    })
    if (!isPort) {
      hasError = true
      break
    } else {
      hasError = false
    }
  }
  if (hasError) {
    callback(errorMessage)
  } else {
    callback()
  }
}
const Ip = (rule, value, callback) => {
  if (value && !value.match(ip)) {
    callback(new Error('Format error'))
  } else {
    callback()
  }
}
const HostRange = (rule, value, callback) => {
  value = value.split('-')
  const errorMessage = new Error('Format error')
  if (value[0] && !value[0].match(ip)) {
    callback(errorMessage)
    return
  }
  switch (true) {
    case value.length === 1:
      callback()
      break
    case value.length === 2:
      if (!value[1].match(ip)) {
        callback(errorMessage)
      } else {
        callback()
      }
      break
    case value.length > 2:
      callback(errorMessage)
      break
    default:
      callback()
  }
}
export {
  Cidr,
  Port,
  Ports,
  Ip,
  HostRange,
  ip as IpRegex,
  host as HostRegex,
}