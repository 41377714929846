import React from 'react';
import {Form, Input, Button} from 'antd'
import Api from '../global/api'
import Http from '../global/http'
import '../style/login.css';
import Lang from '../global/language'

const FormItem = Form.Item;
const Component = React.Component
const title = {
  width: "100%",
  height: '80px',
  lineHeight: "70px",
  fontSize: "30px",
  textAlign: "center"
}
const img = {
  display: "block",
  width: "260px",
  margin: "0 auto",
}
class Login extends Component {
  state = {
    captcha_id: "",
    captchaURL: "",
  }

  componentWillMount() {
    var lastEvent = void 0;
    window.onkeydown = function(event) {
      if (lastEvent && lastEvent.keyCode === event.keyCode) {
        return;
      }
    }
    this.getCaptcha()
  }
  keySubmit = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit()
    }
  }

  getCaptcha = (e) => {
    Api.get.captcha(response => {
      this.setState({ 
        captcha_id: response.captcha_id, 
        captchaURL: "/api/v1/captcha/image/" + response.captcha_id 
      })
    })
  }

  handleSubmit = (e) => {
    this.props.form.validateFields((errors, values) => {
      if (!!errors) {
        return;
      }
      let parm = {
        username: values.account,
        password: values.pass,
        captcha_id: this.state.captcha_id,
        captcha: values.captcha
      }
      Api.post.login(parm, response => {
        sessionStorage.setItem('token', 'Bearer ' + response.token)
        sessionStorage.setItem('name', values.account)
        Http.prototype.constructor.defaults.headers['Authorization'] = 'Bearer ' + response.token
        this.props.history.push('/controller/dashboard')
      })
      this.getCaptcha()
    });
  }
  render() {
    const { getFieldProps, } = this.props.form;
    const accountProps = getFieldProps('account', {
      rules: [
        { required: true, message: Lang.notice.l100 }
      ],
    });
    const passProps = getFieldProps('pass', {
      rules: [
        { required: true, message: Lang.notice.l106 }
      ],
    });
    const captchaProps = getFieldProps('captcha', {
      rules: [
        { required: true, message: "Captcha cannot be blank" }
      ],
    });
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 12 },
    };
    return (
      <div className='login' onKeyDown={this.keySubmit} >
        <div className="logo" style={{width: "100%",height: "85px",marginBottom:"30px"}}>
          <img style={img} src={require('../assets/images/logo.png')} alt=""/>
        </div>
        <div className="box login-box">
          <div className="main">
            <div style={title}>Controller</div>
            <Form>
              <FormItem
                {...formItemLayout}
                label={Lang.system.l116}
              >
                <Input {...accountProps}/>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={Lang.system.l117}
              >
                <Input type='password' {...passProps}/>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={Lang.system.l271}
              >
                <Input 
                  {...captchaProps}
                  style={{width:"30%"}}
                  maxLength="4"
                />
                <img 
                  src={this.state.captchaURL}
                  onClick={this.getCaptcha}
                  width="70%"
                  style={{cursor:"pointer"}}
                />
              </FormItem>
              <FormItem style={{ marginTop: 24,textAlign: 'center' }}>
                <Button type="primary" onClick={this.handleSubmit}>{Lang.system.l100}</Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}
export default Form.create()(Login)
