import React, { Component } from 'react';
import Left from './graph/edge-list'
import Top from './graph/tools'
import Graph from './graph/'

class Main extends Component {
  componentDidMount() {
  }
  componentWillUnmount() {}
  render () {
    return(
        <div style={{
          position: 'absolute',
          width: '100%',
          top: 55,
          left: 0,
          bottom: 53,
        }}>
          <Top />
          <Left />
          <Graph />
        </div>
    )
  }
}

export default Main;