import '../../style/system.css';
import React from 'react';
import { Form, Table, Icon, Tooltip } from 'antd'
import Global from '../../global/method'
import Api from '../../global/systemApi'
import Lang from '../../global/language'
// const Option = Select.Option;
// const RadioGroup = Radio.Group;
const Component = React.Component
const createForm = Form.create;

//
class Log extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.logsList(1)
    this.logsList2(1)
  }
  componentWillUnmount() {

  }
  columns = [{
    title: Lang.system.l194,
    dataIndex: 'time',
    key: 'time',
    width: 180,
  }, {
    title: Lang.system.l195,
    dataIndex: 'role',
    key: 'role'
  },
  {
    title: Lang.system.l114,
    dataIndex: 'username',
    key: 'username'
  },
  {
    title: Lang.system.l111,
    dataIndex: 'operation',
    key: 'operation'
  },
  {
    title: Lang.system.l186,
    dataIndex: 'ip',
    key: 'ip'
  },
  {
    title: Lang.system.l197,
    dataIndex: 'error',
    key: 'error',
    render: (text, record, index) => {
      return (
        <span>
          {record.error===''?<span style={{color:'#26ae85'}}>{Lang.system.l102}</span>:<span style={{color:'#c23531'}}>{Lang.system.l103}</span>}
        </span>
      )
    }
  },
  // {
  //   title: '详情',
  //   dataIndex: 'detail',
  //   key: 'detail'
  // },
  ]
  columns2 = [{
    title: Lang.system.l194,
    dataIndex: 'time',
    key: 'time',
    width: 180,
  },
  // {
  //   title: Lang.system.l198,
  //   dataIndex: 'level',
  //   key: 'level'
  // },
  {
    title: Lang.system.l199,
    dataIndex: 'edge_sn',
    key: 'edge_sn'
  },
  {
    title: 'Edge ' + Lang.system.l138,
    dataIndex: 'edge_name',
    key: 'edge_name'
  },
  {
    title: Lang.system.l290,
    dataIndex: 'event',
    key: 'event'
  },
  {
    title: Lang.system.l144,
    dataIndex: 'detail',
    key: 'detail',
    render: (text, record, index) => {
      return (
        <a href="javascript:;">
          {record.detail ? 
              <Tooltip placement="left" title={record.detail} getPopupContainer={() => 
                document.getElementsByClassName('systemLogs')[0]
              }>
                {Lang.system.l259}
              </Tooltip>
            : null
          }
        </a>
      )
    }
  },
  {
    title: Lang.system.l197,
    dataIndex: 'error',
    key: 'error',
    render: (text, record, index) => {
      return (
        <span>
          {
            record.error === '' ?
              <span style={{color:'#26ae85'}}>{Lang.system.l102}</span>
              : <span style={{color:'#c23531', cursor:'pointer'}}>
                  <Tooltip placement="left" title={record.error}>
                    {Lang.system.l103 }
                    <Icon type="warning" />
                  </Tooltip>
                </span>
          }
        </span>
      )
    }
  },
  ]
  state = {
    list: [],
    list2: [],
    pagination1: {},
    pagination2: {},
  }
  page1 = (value) => {
    this.logsList(value.current)
  }
  page2 = (value) => {
    this.logsList2(value.current)
  }
  logsList = (index) => {
    let params = {
      page_size: 10,
      page_no: index,
    }
    Api.logs(params, response => {
      let list = []
      for (let i = 0; i < response.logs.length; i++) {
        list.push({
          id: response.logs[i].operator_id,
          time: Global.timeSwitch(response.logs[i].timestamp),
          role: response.logs[i].domain,
          username: response.logs[i].operator_name,
          operation: Lang.logsOperation[response.logs[i].operation],
          ip: response.logs[i].ip,
          detail: response.logs[i].detail,
          error: response.logs[i].error,
        })
      }
      this.setState({
        list: list,
        pagination1: {
          current: index,
          total: response.total,
          pageSize: 10,
        },
      })
    })
  }
  logsList2 = (index) => {
    let params = {
      page_size: 10,
      page_no: index,
    }
    Api.logs2(params, response => {
      let list = []
      for (let i = 0; i < response.logs.length; i++) {
        list.push({
          time: Global.timeSwitch(response.logs[i].timestamp),
          level: response.logs[i].level,
          edge_name: response.logs[i].edge_name,
          edge_sn: response.logs[i].edge_sn,
          event: Lang.logsEvent[response.logs[i].event],
          error: response.logs[i].error,
          detail: response.logs[i].detail,
        })
      }
      this.setState({
        list2: list,
        pagination2: {
          current: index,
          total: response.total,
          pageSize: 10,
        }
      })
    })
  }
  render() {
    return (
      <div className="tunnel systemLogs">
        <div className="box max">
          <div className="title">{Lang.system.l201}</div>
          <div className="main">
            <Table dataSource={this.state.list} columns={this.columns} onChange={this.page1} locale={{emptyText: Lang.system.l256}} pagination={this.state.pagination1} size="small"/>
          </div>
        </div>
        <div className="box max">
          <div className="title">{Lang.system.l202}</div>
          <div className="main">
            <Table dataSource={this.state.list2} columns={this.columns2} locale={{emptyText: Lang.system.l256}} onChange={this.page2} pagination={this.state.pagination2} size="small"/>
          </div>
        </div>
      </div>
    )
  }
}
Log = createForm()(Log);
export default Form.create()(Log)
