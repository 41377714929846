import axios from './http'

const Api = {
  get: {
    captcha: (callback) => {
      axios.get('/captcha').then(response => {
        callback(response.data)
      })
    },
    topologies: (callback, id) => {
      let url = '/net_topologies'
      if (id) {
        url = url + '/' + id
      }
      axios.get(url).then(
        response => {
        callback(response.data)
      })
    },
    edges: (callback) => {
      axios.get('/edges').then(
        response => {
          callback(response.data)
        }
      )
    },
    state: (callback) => {
      axios.get('/net_links/state').then(
        response => {
          callback(response.data)
        }
      )
    },
    securityGroups: (callback) => {
      axios.get('/security_groups').then(
        response => {
          callback(response.data)
        }
      )
    },
    diagnosis: (params, callback) => {
      axios.get('/net_links/diagnosis', {params: params}).then(
        response => {
          callback(response.data)
        }
      )
    },
    bandwidth: (params, callback) => {
      axios.get('/edges/bandwidth', {params: params}).then(
        response => {
          callback(response.data)
        }
      )
    },
    traffics: (params, callback) => {
      axios.get('/net_links/traffics', {params: params}).then(
        response => {
          callback(response.data)
        }
      )
    },
    summary: (callback) => {
      axios.get('/summary').then(
        response => {
          callback(response.data)
        }
      )
    },
    usages: (params, callback) => {
      axios.get('/traffic/usages', {params: params}).then(
        response => {
          callback(response.data)
        }
      )
    },
    locations: (callback) => {
      axios.get('/locations').then(
        response => {
          callback(response.data)
        }
      )
    },
    gateway: (callback) => {
      axios.get('/app_gateway').then(
        response => {
          callback(response.data)
        }
      )
    },
    apps_predefined: async () => {
      let response = await axios.get('/apps_management/predefined').then(response => {return response.data}).catch(() => {return null})
      return response
    },
    apps_custom: async (id) => {
      id = id ? '/' + id : ''
      let response = await axios.get('/apps_management/custom' + id).then(response => {return response.data}).catch(() => {return null})
      return response
    },
    internet_access: async (id) => {
      let response = await axios.get('/net_topologies/' +id+ '/internet_access').then(response => {return response.data}).catch(() => {return null})
      return response
    },
    links_peer_edges: async (params) => {
      let response = await axios.get('/net_links/peer_edges', {params:params}).then(response => {return response}).catch(error => {return error.response})
      return response
    },
    links_indicators: async () => {
      let response = await axios.get('/net_links/stats/indicators').then(response => {return response}).catch(error => {return error.response})
      return response
    },
    links_stats: async (params) => {
      let response = await axios.get('/net_links/stats', {params:params}).then(response => {return response}).catch(error => {return error.response})
      return response
    },
    links_state: async (params) => {
      let response = await axios.get('/net_links/stats/state', {params:params}).then(response => {return response}).catch(error => {return error.response})
      return response
    },
  },
  post: {
    login: (params, callback) => {
      axios.post('/login', {
        username: params.username,
        password: params.password,
        captcha_id: params.captcha_id,
        captcha: params.captcha
      })
      .then(response => {
        const data = response.data
        callback(data)
      })
    },
    topologies: (params, callback) => {
      axios.post('/net_topologies', params).then(
        response => {
          callback({
            err: false,
            data: response.data,
          })
        }
      ).catch(
        error => {
          callback({
            err: true,
            data: error.response.data
          })
        }
      )
    },
    setTopology: (id, type, callback) => {
      axios.post('/net_topologies/' + id + '/' + type).then(
        response => {
          callback({
            err: false,
            data: response.data,
          })
        }
      ).catch(
        error => {
          callback({
            err: true,
            data: error.response.data,
          })
        }
      )
    },
    securityGroups: (params, callback) => {
      axios.post('/security_groups', params).then(
        response => {
          callback(response.data)
        }
      )
    },
    apps_custom: async (params) => {
      const response = await axios.post('/apps_management/custom', params).then(response => {return response.data}).catch(() => {return null})
      return response
    },
    internet_access: async (id) => {
      let response = await axios.post('/net_topologies/' +id+ '/internet_access/apply').then(response => {return response.data}).catch(() => {return null})
      return response
    },
  },
  put: {
    topologies: (id, params, callback) => {
      axios.put('/net_topologies/' + id, params).then(
        response => {
          callback({
            err: false,
            data: response.data,
          })
        }
      ).catch(
        error => {
          callback({
            err: true,
            data: error.response.data
          })
        }
      )
    },
    securityGroups: (id, params, callback) => {
      axios.put('/security_groups/' + id, params).then(
        response => {
          callback(response.data)
        }
      )
    },
    gateway: (params, callback) => {
      axios.put('/app_gateway', params).then(
        response => {
          callback(response.data)
        }
      )
    },
    apps_custom: async (params) => {
      const id = params.id
      const response = await axios.put('/apps_management/custom/' + id, params)
      return response.data
    },
    internet_access: async (id, params) => {
      let response = await axios.put('/net_topologies/' +id+ '/internet_access', params).then(response => {return response.data}).catch(() => {return null})
      return response
    },
  },
  delete: {
    topology: (id, callback) => {
      axios.delete('/net_topologies/' + id).then(
        response => {
          callback({
            err: false,
            data: response.data,
          })
        }
      ).catch(
        () => {
          callback({
            err: true,
            data: null
          })
        }
      )
    },
    securityGroups: (id, callback) => {
      axios.delete('/security_groups/' + id).then(
        response => {
          callback(response.data)
        }
      )
    },
    apps_custom: (id, isForce, callback) => {
      id = id || ''
      axios.delete('/apps_management/custom/' + id, {params: {force: isForce}}).then(
        response => {
          callback(response.data)
        }
      ).catch(
        error => {
          callback(error.response.data)
        }
      )
    },
  },
}
export default Api
