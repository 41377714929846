import React, { Component } from 'react';
import { Modal, Tabs, Form, Select, Input, Table } from 'antd'
import SecurityGroupTab from './securityGroupTab'
import Api from '../../global/api'

const TabPhane = Tabs.TabPane
class Main extends Component {
  componentDidMount() {
    this.init()
  }
  componentWillUnmount() {}
  handleOk = () => {
    this.props.form.validateFields((errors, values) => {
      if (!!errors) {
        return
      }
      let data = {
        name: values.name,
        description: values.description,
        inbound_rules: this.child1.getData(),
        outbound_rules: this.child2.getData(),
      }
      if (this.state.id) {
        Api.put.securityGroups(this.state.id, data, response => {
          if (!response.err) {
            this.props.save('success')
          }
        })
      } else {
        Api.post.securityGroups(data, response => {
          if (!response.err) {
            this.props.save('success')
          }
        })
      }
    })
  }
  handleCancel = () => {
    this.props.cancel()
  }
  onRef1 = (ref) => {
    this.child1 =ref
  }
  onRef2 = (ref) => {
    this.child2 =ref
  }
  init = () => {
    const data = this.props.data ? this.props.data : {
      edit: true,
      id: null,
      name: '',
      description: '',
      inbound: [],
      outbound: [],
    }
    this.setState({
      ...data
    }, () => {
      this.props.form.setFieldsValue({
        name: data.name,
        description: data.description,
      })
    })
  }
  state = {
    edit: true,
    id: '',
    name: '',
    description: '',
    inbound: '',
    outbound: '',
  }
  render () {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const { getFieldDecorator } = this.props.form;
    const { edit } = this.props.data
    return(
      <Modal
          title={this.state.id ? 'Edit Security Group' : 'Add Security Group'}
          maskClosable={false}
          width={800}
          visible={true}
          onOk={this.handleOk}
          okText="Save"
          onCancel={this.handleCancel}
          okButtonProps={{ disabled: this.state.name === '' || !edit}}
        >
          <Form {...formItemLayout}>
            {this.state.id ? <Form.Item label="ID">
              {this.state.id}
            </Form.Item> : null}
            <Form.Item label="Name">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a name!',
                  },
                ],
              })(<Input disabled={!edit} onChange={(e)=>{this.setState({name: e.target.value})}}/>)}
            </Form.Item>
            <Form.Item label="Description">
              {getFieldDecorator('description', {
                rules: [
                  {
                    required: false,
                    message: '',
                  },
                ],
              })(<Input disabled={!edit}/>)}
            </Form.Item>
          </Form>
          <Tabs type="card">
            <TabPhane key="1" tab="Inbound" forceRender={true}>
              <SecurityGroupTab data={this.state.inbound} edit={edit} type="inbound" onRef={this.onRef1} />
            </TabPhane >
            <TabPhane key="2" tab="Outbound" forceRender={true}>
              <SecurityGroupTab data={this.state.outbound} edit={edit} type="outbound" onRef={this.onRef2}/>
            </TabPhane >
          </Tabs>
        </Modal>
    )
  }
}
const MainForm = Form.create()(Main)
export default MainForm;