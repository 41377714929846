import React from 'react';
import { Input, Form, Icon, Select, Button, InputNumber, Switch } from 'antd'
import Api from '../../global/systemApi'
import Lang from '../../global/language'
import Global from '../../global/method'

const Component = React.Component
const createForm = Form.create;
const FormItem = Form.Item;
const Option = Select.Option;

class Strategy extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    this.props.onRef(this)
    this.strategy()
    this.bandwidth()
  }
  strategy = () => {
    let params = {
      app: 1,
      id: this.props.id,
    }
    Api.strategy(params, response => {
      const value = response.strategy === 0 ? 0 : 1
      this.setState({
        value: value,
        valueOld: value,
      }, () => {
        this.props.form.setFields({
          select: {value: value}
        })
      })
    })
  }
  bandwidth = () => {
    Api.bandwidth(this.props.id, response => {
      const value = response.bandwidth
      this.setState({
        bandwidth: value,
        bandwidthOld: value,
      })
      this.props.form.setFields({
        bandwidth: {value: Global.bps(value).value},
        unit: {value: Global.bps(value).unit},
      })
    }, callback => callback)
  }
  setStrategy = () => {
    let params = {
      app: 1,
      id: this.props.id,
      strategy: this.props.form.getFieldValue('select'),
      time_schedule: 0
    }
    return new Promise((resolve, reject) => {
      Api.setStrategy(params, response => response.status === 200 ? resolve() : reject())
    })
  }
  setBandwidth = () => {
    let params = {
      id: this.props.id,
      bandwidth: this.props.form.getFieldValue('bandwidth') * Math.pow(1000, this.props.form.getFieldValue('unit')),
    }
    return new Promise((resolve, reject) => {
      Api.setBandwidth(params, response => response.status === 200 ? resolve() : reject())
    })
  }
  save = (callback) => {
    const {value, valueOld, bandwidthOld} = this.state
    const bandwidth = this.props.form.getFieldValue('bandwidth') * Math.pow(1000, this.props.form.getFieldValue('unit'))
    const saveList = []
    if (value !== valueOld) {
      saveList.push(this.setStrategy)
    }
    if (value !== 0 && bandwidth !== bandwidthOld) {
      saveList.push(this.setBandwidth)
    }
    if (!!saveList.length) {
      saveList.reduce((p, f) => p.then(f), Promise.resolve()).then(() => callback())
    } else {
      callback()
    }
  }
  changeSelect = (value) => {
    this.setState({
      value: value,
    },() => {
      if (value) {
        const { bandwidth } = this.state
        this.props.form.setFields({
          bandwidth: {value: Global.bps(bandwidth).value},
          unit: {value: Global.bps(bandwidth).unit},
        })
      }
    })
  }
  state = {
    value: 0,
    bandwidth: 0,
  }
  render() {
    const { getFieldProps, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14 },
    };
    return (
      <div>
        <Form >
          <FormItem
            label={Lang.system.l148}
            {...formItemLayout}
          >
            <Select style={{ width: 200 }}
              {...getFieldProps('select', {
                onChange: this.changeSelect,
              })}
            >
              <Option value={0}>{Lang.system.l104}</Option>
              <Option value={1}>{Lang.system.l105}</Option>
              {/* <Option value={0}>{Lang.system.l149.l0}</Option>
              <Option value={1}>{Lang.system.l149.l1}</Option>
              <Option value={2}>{Lang.system.l149.l2}</Option>
              <Option value={3}>{Lang.system.l149.l3}</Option> */}
            </Select>
          </FormItem>
          {
            this.state.value === 1 ? <FormItem
              label={Lang.system.l248}
              {...formItemLayout}
            >
              <Input style={{ width: 100, marginRight: '10px'}} {...getFieldProps('bandwidth', {
                initialValue: Global.bps(this.state.bandwidth).value
              })}/>
              <Select style={{ width: 90 }}
                {...getFieldProps('unit',{
                  initialValue: Global.bps(this.state.bandwidth).unit
                })}
              >
                <Option value={0}>bps</Option>
                <Option value={1}>kbps</Option>
                <Option value={2}>Mbps</Option>
                <Option value={3}>Gbps</Option>
              </Select>
            </FormItem> : null
          }
        </Form>
      </div>
    )
  }
}
Strategy = createForm()(Strategy);
export default Form.create()(Strategy)
