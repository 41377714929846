import React, { Component } from 'react';
import { Modal, Tabs, Form, Select, Input, Table, Button, Divider, Popover} from 'antd'
import SetRule from './setRule'
import Api from '../../global/api'

const TabPhane = Tabs.TabPane
const portocolList = {
  0: 'All',
  6: 'TCP',
  17: 'UDP',
}
class Main extends Component {
  componentDidMount() {
    this.init()
  }
  componentWillUnmount() {}
  handleOk = () => {
    this.props.form.validateFields(async (errors, values) => {
      if (!!errors) {
        return
      }
      let data = {
        name: values.name,
        description: values.description,
        rules: this.state.rules,
      }
      let response = null
      if (this.state.id) {
        data.id = this.state.id
        response = await Api.put.apps_custom(data)
      } else {
        response = await Api.post.apps_custom(data)
      }
      if (response) {
        this.props.save('success')
      }
    })
  }
  handleCancel = () => {
    this.props.cancel()
  }
  ruleSetting = (index) => {
    const { rules } = this.state
    let i = index
    let data = {}
    if (index !== null) {
      data = rules[index]
    } else {
      i = rules.length
    }
    data.index = i
    this.setState({
      rule: {
        data: data,
        visible: true,
      }
    })
  }
  onRef = (ref) => {
    this.child =ref
  }
  init = async () => {
    let data = {}
    if (this.props.data && this.props.data.id) {
      data = await Api.get.apps_custom(this.props.data.id)
    } else {
      data = {
        id: null,
        name: '',
        description: '',
        rules: [],
      }
    }
    this.setState({
      ...data
    }, () => {
      this.props.form.setFieldsValue({
        name: data.name,
        description: data.description,
      })
    })
  }
  state = {
    id: '',
    name: '',
    description: '',
    rules: [],
    rule: {
      visible: false,
      data: null,
    },
  }
  saveRule = (data) => {
    const { rules } = this.state
    const index = data.index 
    delete data.index
    delete data.host
    rules[index] = data
    this.setState({
      // rules: rules,
    }, () => {
      this.closeRule()
    })
  }
  closeRule = () => {
    this.setState({
      rule: {
        visible: false,
        data: null,
      }
    })
  }
  deleteRlue = (index) => {
    const { rules } = this.state
    rules.splice(index, 1)
    this.setState({
      rules: rules,
    })
  }
  render () {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const { getFieldDecorator } = this.props.form;
    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        editable: true,
        width: 100,
      },
      {
        title: 'Hosts',
        dataIndex: 'dst_hosts',
        textWrap: 'ellipsis',
        editable: true,
        width: 200,
        render: (text, record, index) => {
          let hosts = record.dst_hosts || []
          hosts = [...hosts]
          let hostsList = [...hosts]
          let length =  hosts.length > 3 ? hosts.length - 3 : 0
          hosts.splice(3, length)
          let content = <div>{hostsList.map((ele, index) => {
            return <p key={index}>{ele}</p>
          })}</div>
          return (
            <div style={{width: '200px',overflow:'hidden'}}>
              <div title={hosts.join('; ')} style={{float: 'left',maxWidth: '165px',whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden',}}>
                {hosts.join('; ')}
              </div>
              {hostsList.length > 3 ? <Popover content={content} placement="right" title="Hosts">
                <a style={{float: 'left'}} href="javascript:;">more</a>
              </Popover> : null}
            </div>
          )
        }
      },
      {
        title: 'Ports',
        dataIndex: 'ports',
        editable: true,
        width: 100,
      },
      {
        title: 'Protocol',
        dataIndex: 'protocol',
        editable: true,
        width: 60,
        render: (text, record, index) =>{
          return(
            <span>{portocolList[text]}</span>
          )
        }
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        width: 120,
        render: (text, record, index) => {
          return (
            <div>
              <a href="javascript:;" onClick={() => this.ruleSetting(index)}>Edit</a>
              <Divider type="vertical" />
              <a href="javascript:;" onClick={() => this.deleteRlue(index)}>Delete</a>
            </div>
          )
        }
      },
    ]
    return(
      <Modal
        title={this.state.id ? 'Edit Application' : 'New Application'}
        maskClosable={false}
        width={800}
        visible={true}
        onOk={this.handleOk}
        okText="Save"
        onCancel={this.handleCancel}
        okButtonProps={{ disabled: this.state.name === ''}}
      >
        <Form {...formItemLayout}>
          {this.state.id ? <Form.Item label="ID">
            {this.state.id}
          </Form.Item> : null}
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please enter a name!',
                },
              ],
            })(<Input onChange={(e)=>{this.setState({name: e.target.value})}}/>)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
              rules: [
                {
                  required: false,
                  message: '',
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Form>
        <div style={{position:'relative',}}>
          <Button onClick={() => this.ruleSetting(null)} type="primary" style={{zIndex:3,position:'absolute',top:'2px',left: '80px'}}>Add Rule</Button>
          <Tabs type="card">
            <TabPhane key="1" tab="Rules" forceRender={true}>
              <Table
                style={{margin: '-16px 0 16px 0'}}
                columns={this.columns} 
                dataSource={this.state.rules} 
                size="middle"
                pagination={false}
                onHeaderRow={()=>{
                  return {
                    style: {
                      background: '#fff',
                    }
                  }
                }}
              >
              </Table>
            </TabPhane >
          </Tabs>
        </div>
        {this.state.rule.visible ? <SetRule data={this.state.rule.data} save={this.saveRule} cancel={this.closeRule}/> : null}
      </Modal>
    )
  }
}
const MainForm = Form.create()(Main)
export default MainForm;