import React, { Component } from 'react';
import { Statistic, Col } from 'antd'
import Box from '../box'
import Api from '../../../global/api'
import { withRouter } from 'react-router-dom'

class Main extends Component {
  state = {
    data: null,
    color1: '',
    color2: '',
  }
  componentDidMount() {
    this.init()
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  changeColor1 = (color) => {
    this.setState({
      color1: color,
    })
  }
  changeColor2 = (color) => {
    this.setState({
      color2: color,
    })
  }
  init = () => {
    this.getData()
    this.intervalSummary()
  }
  getData = () => {
    Api.get.summary(response => {
      this.setState({
        data: response
      })
    })
  }
  intervalSummary = () => {
    clearInterval(this.interval)
    this.interval = null
    this.interval = setTimeout(()=> {
      this.getData()
      this.intervalSummary()
    }, 60 * 1000)
  }
  toEdges = () => {
    this.props.history.push(`/controller/edges`)
  }
  toTopologies = () => {
    this.props.history.push(`/controller/deployment`)
  }
  render () {
    return(
      <Box title="Summary" show={this.state.data}>
        <Col span={12} >
          <div onClick={this.toEdges} style={{cursor:'pointer'}} onMouseOver={() => this.changeColor1('#fa8c16')} onMouseOut={() => this.changeColor1('')}>
            <Statistic title="Total Edges" valueStyle={{ color: this.state.color1 }}  value={this.state.data !== null ? this.state.data.edges_cnt : 0} style={{textAlign: 'center'}}/>
          </div>
        </Col>
        <Col span={12} >
          <div onClick={this.toTopologies} style={{cursor:'pointer'}} onMouseOver={() => this.changeColor2('#fa8c16')} onMouseOut={() => this.changeColor2('')}>
            <Statistic title="Topologies" valueStyle={{ color: this.state.color2 }} value={this.state.data !== null ? this.state.data.topologies_cnt : 0} style={{textAlign: 'center'}}/>
          </div>
        </Col>
      </Box>
    )
  }
}

export default withRouter(Main);