import React from 'react';
import { Router, Route, Redirect, Switch} from "react-router-dom";
import Login from './page/login'
import Entrance from './page/entrance'
import Main from './page'
import { history } from './global/units'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render = {props => sessionStorage.getItem('token') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/entrance",
          }}
        />
      )
      }
    />
  )
}
const EnterRoute = ({ component: Component, ...rest }) => {
  console.log(sessionStorage.getItem('token'))
  return (
    <Route
      {...rest}
      render = {props => sessionStorage.getItem('token') ? (
        <Redirect
          to={{
            pathname: "/controller/",
          }}
        />
      ) : (
        <Component {...props} />
      )
      }
    />
  )
}
function App() {
  return (
    <Router
      history={history}
    >
      <div className="App">
        <Switch>
          <EnterRoute path={["/controller/login"]}  component={Login} />
          <Route path={["/entrance"]}  component={Entrance} />
          <PrivateRoute path="/controller/" component={Main} />
          <Route render={
              () => {
                if (sessionStorage.getItem('token')) {
                  return(
                    <Redirect
                      to={{
                        pathname: "/controller/dashboard",
                      }}
                    />
                  )
                } else {
                  return(
                    <Redirect
                      to={{
                        pathname: "/entrance",
                      }}
                    />
                  )
                }
              }
            }/>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
