import '../../style/system.css';
import React from 'react';
import { Input, Form, Icon, Select, Button, InputNumber, Switch } from 'antd'
import Api from '../../global/systemApi'
import Lang from '../../global/language'

const Component = React.Component
const createForm = Form.create;
const FormItem = Form.Item;
const Option = Select.Option;
class Netif extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.net()
  }
  net = () => {
    Api.netif(response => {
      this.props.form.setFields({
        mode: {value: response.mode},
        ip: {value: response.ip},
        netmask: {value: response.netmask},
        gateway: {value: response.gateway},
        dns: {value: response.dns},
        dns2: {value: response.dns2},
        mtu: {value: response.mtu},
      })
      this.setState({
        name: response.name,
        carrier: response.carrier,
        state: response.state,
        mac_addr: response.mac_addr,
        ip: response.ip,
        netmask: response.netmask,
        gateway: response.gateway,
      })
    })
  }
  setNet = () => {
    this.props.form.validateFields((errors, values) => {
      if (values.mode === 'static'&&!!errors) {
        return;
      }
      let params = {
        mode: values.mode,
        dns: this.props.form.getFieldValue('dns'),
        dns2: this.props.form.getFieldValue('dns2'),
        mtu: this.props.form.getFieldValue('mtu') ? values.mtu : 0
      }
      if (values.mode === 'static') {
        params.ip = values.ip
        params.netmask = values.netmask
        params.gateway = values.gateway
      }
      Api.setNetif(params, response => {
        this.skip()
      })
    })
  }
  skip = () => {
    this.setState({
      second: 3,
      display: 'block',
    })
    this.intervalA = setInterval(() => {
      let second = this.state.second
      if (second === 1) {
        clearInterval(this.intervalA)
        if (this.props.form.getFieldValue('mode') === 'static') {
          window.location.href = '//' + this.props.form.getFieldValue('ip')
        } else {
          // window.location.reload()
        }
      }
      second = second - 1
      this.setState({
        second: second,
      })
    }, 1000);
  }
  changeMode = () => {
    this.setState({
      ip: Lang.system.l203,
      gateway: Lang.system.l203,
      netmask: Lang.system.l203,
    })
  }
  state = {
    display: 'none',
    second: 3,
    name: '',
    carrier: '',
    state: '',
    mac_addr: '',
    mode: '',
    ip: '',
    netmask: '',
    gateway: '',
    dns: '',
    dns2: '',
    mtu: '',
  }
  render() {
    const { getFieldProps, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 12 },
    };
    const ipProps = getFieldProps('ip', {
      rules: [
        { required: !this.state.dhcp, message: Lang.notice.l110 },
        { pattern: /((?:(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d))\.){3}(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d)))/, message: Lang.notice.l111}
      ],
    })
    const netmaskProps = getFieldProps('netmask', {
      rules: [
        { required: true, message: Lang.notice.l121 },
        { pattern: /((?:(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d))\.){3}(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d)))/, message: Lang.notice.l112}
      ],
    })
    const gatewayProps = getFieldProps('gateway', {
      rules: [
        { required: true, message: Lang.notice.l113 },
        { pattern: /((?:(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d))\.){3}(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d)))/, message: Lang.notice.l114}
      ],
    })
    const dnsProps = getFieldProps('dns', {
      rules: [
        { pattern: /((?:(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d))\.){3}(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d)))/, message: Lang.notice.l115}
      ],
    })
    const dns2Props = getFieldProps('dns2', {
      rules: [
        { pattern: /((?:(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d))\.){3}(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d)))/, message: Lang.notice.l115}
      ],
    })
    return (
      <div className="strategy">
        <div className="box normal">
          <div className="skip-warnings" style={{display:this.state.display}}>
            {getFieldValue('mode') === 'static'?this.state.second+Lang.prompt.l100:Lang.prompt.l101}
          </div>
          <div className="title">{Lang.system.l205}</div>
          <div className="main">
            <Form>
              <FormItem
                label={Lang.system.l181}
                {...formItemLayout}
              >
                {this.state.name}
              </FormItem>
              <FormItem
                label={Lang.system.l182}
                {...formItemLayout}
              >
                {this.state.carrier ?Lang.system.l150.l0:Lang.system.l150.l1}
              </FormItem>
              <FormItem
                label={Lang.system.l183}
                {...formItemLayout}
              >
                {this.state.mac_addr}
              </FormItem>
              <FormItem
                label={Lang.system.l184}
                {...formItemLayout}
              >
                <Select style={{ width: 200 }}
                  {...getFieldProps('mode',{
                    onChange: this.changeMode,
                  })}
                >
                  <Option value='static'>{Lang.system.l209}</Option>
                  <Option value='dhcp'>{Lang.system.l210}</Option>
                </Select>
              </FormItem>
              <div>
                <FormItem
                  label={Lang.system.l186}
                  {...formItemLayout}
                  required={getFieldValue('mode')==='static'}
                >
                  {getFieldValue('mode')==='static'?<Input {...ipProps} style={{ width: 150 }}/>:this.state.ip}
                </FormItem>
                <FormItem
                  label={Lang.system.l187}
                  {...formItemLayout}
                  required={getFieldValue('mode')==='static'}
                >
                  {getFieldValue('mode')==='static'?<Input style={{ width: 150 }} {...netmaskProps} />:this.state.netmask}
                </FormItem>
                <FormItem
                  label={Lang.system.l188}
                  {...formItemLayout}
                  required={getFieldValue('mode')==='static'}
                >
                  {getFieldValue('mode')==='static'?<Input style={{ width: 150 }} {...gatewayProps} />:this.state.gateway}
                </FormItem>
                </div>
            <FormItem
                label="MTU"
                {...formItemLayout}
              >
                <InputNumber {...getFieldProps('mtu')} min={0}/>
              </FormItem>
              <FormItem
                label={Lang.system.l189}
                {...formItemLayout}
              >
                <Input style={{ width: 150 }} {...dnsProps} />
              </FormItem>
              <FormItem
                label={Lang.system.l190}
                {...formItemLayout}
              >
                <Input style={{ width: 150 }} {...dns2Props} />
              </FormItem>
              <FormItem
                wrapperCol={{ span: 12, offset: 7 }}
                style={{ marginTop: 24 }}
              >
                <Button type="primary" onClick={this.setNet}>{Lang.system.l246}</Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}
Netif = createForm()(Netif);
export default Form.create()(Netif)
