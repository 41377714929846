import React, { Component } from 'react';
import Lang from '../../global/language'
import { Menu, Modal, Form, Table, Input, Checkbox, Switch, Select, Icon} from 'antd'
import Info from './info'
import Strategy from './strategy'
import Bandwidth from './bandwidth'
import Workmode from './workmode'

const Keys = [
  {name: 'info', title: Lang.system.l146},
  {name: 'strategy', title: Lang.system.l148},
  {name: 'bandwidth', title: Lang.system.l247},
  {name: 'workmode', title: 'Work Mode Setting'},
]
class Main extends Component {
  state = {
    info: false,
    strategy: false,
    title: '',
  }
  componentDidMount() {
    this.setState({
      info: !!this.props.data,
      title: this.props.data.type === 'init' ? 'Edge Info' : Lang.system.l146, 
    })
  }
  componentWillUnmount() {}
  handleOk = () => {
    this.child.save(() => {
      this.props.cancel()
    })
  }
  onRef = (ref) => {
    this.child = ref
  }
  changeMenu = (value) => {
    this.setState({
      info: false,
      strategy: false,
      bandwidth: false,
      workmode: false,
      [Keys[value.key].name]: true,
      title: Keys[value.key].title
    })
  }
  render () {
    return(
      <Modal
        title={
          <div style={{padding: this.props.data.type === 'init' ? '0' : '0 0 0 180px'}}>
            {this.state.title}
          </div>
        }
        className="edgeSetting"
        visible={!!this.props.data}
        width= {680}
        onCancel={this.props.cancel}
        onOk={this.handleOk}
        cancelText={Lang.system.l107} 
        okText={Lang.system.l106} 
      >
        {this.props.data.type === 'init' ?
          null :
          <Menu
            mode="inline"
            defaultSelectedKeys={['0']}
            style={{position: 'absolute',top: 2, bottom: 2, left: 2, width: 185}}
            onClick={this.changeMenu}
          >
            <Menu.ItemGroup 
              title={
              <span style={{display:'inline-block',fontSize:'16px',marginTop:'14px',}}>
                <Icon type="hdd" style={{marginRight:'5px'}}/>
                <span>{this.props.data.name}</span>
              </span>
            }>
              <Menu.Item key={0}>{Lang.system.l146}</Menu.Item>
              <Menu.Item key={1} disabled={!this.props.data.online}>{Lang.system.l148}</Menu.Item>
              {/* <Menu.Item key={2} disabled={!this.props.data.online}>{Lang.system.l247}</Menu.Item> */}
              <Menu.Item key={3} disabled={!this.props.data.online}>Work Mode Setting</Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        }
        <div style={{margin: this.props.data.type === 'init' ? '0 auto' : '0 0 0 180px', width: '450px', height: this.props.data.type === 'init' ? 'auto' : '360px'}}>
          {this.state.info ? <Info refresh={this.props.refresh} data={this.props.data} onRef={this.onRef} id={this.props.data.id} locations={this.props.locations}/> : null}
          {this.state.strategy ? <Strategy onRef={this.onRef} id={this.props.data.id} /> : null}
          {/* {this.state.bandwidth ? <Bandwidth onRef={this.onRef} id={this.props.data.id} /> : null} */}
          {this.state.workmode ? <Workmode onRef={this.onRef} id={this.props.data.id} data={this.props.data.info}/> : null}
        </div>
      </Modal>
    )
  }
}

export default Main;