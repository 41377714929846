import React, { Component } from 'react';
import withContext from '../../common/context/withContext'

import {
  Layout, Form, Input, Radio, Button,
} from 'antd';
const {Group: RadioGroup} = Radio
class Main extends Component {
  componentDidMount() {
    this.props.onRef(this)
    this.props.form.setFieldsValue({
      name: this.props.basic.name,
      description: this.props.basic.description,
      mode: this.props.basic.mode,
    })
  }
  componentWillUnmount() {
  }
  save = () => {
    this.props.form.validateFields((errors, values) => {
      if (!!errors) {
        return;
      }
      let basic = {
        name: values.name,
        kind: 1,
        mode: values.mode,
        description: values.description,
      }
      this.props.setValue({
        basic: basic,
        current: 1,
      })
    })
  }
  onChange = () => {}
  render () {
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      }
    }
    const nameConfig = {
      rules: [
        {required: true, message: 'Please enter a name.'},
        {max: 20, message: 'The maximum name length is 20 characters'},
      ]
    }
    const descriptionConfig = {
      rules: [
        {max: 40, message: 'The maximum description length is 40 characters'},
      ]
    }
    return(
      <div style={{paddingTop: 20}}>
        <Form {...formItemLayout}>
          <Form.Item
            label="Name"
          >
            {getFieldDecorator('name', nameConfig)(
              <Input/>
            )}
          </Form.Item>
          <Form.Item
            label="Mode"
          >
            {getFieldDecorator('mode',)(
              <RadioGroup onChange={this.onChange} disabled={this.props.type === 'edit'}>
                <Radio value={0}>VPN Mode</Radio>
                <Radio value={1} disabled={!this.props.license.r2tpSupport}>Non-VPN Mode</Radio>
              </RadioGroup>
            )}
          </Form.Item>
          <Form.Item
            label="Descrpition"
          >
            {getFieldDecorator('description', descriptionConfig)(
              <TextArea rows={3}/>
            )}
          </Form.Item>
        </Form>
      </div>
    )
  }
}

Main = Form.create({
  // onValuesChange (props, values) {
  //   props.onFormChange(values)
  // },
})(Main);
export default withContext(Main)