import React, { Component } from 'react';
import { Layout, Drawer, Button, Tabs, Icon, Steps} from 'antd'
import Smarttrace from '../containers/dashboard/smarttrace'
import List from '../containers/dashboard/list'
import Summary from '../containers/dashboard/summary'
import Usages from '../containers/dashboard/usages'
import Map from '../containers/dashboard/map'
import Api from '../global/api'

const block_1 = {
  width: 340,
  height: 450,
}
const block_2 = {
  width: 420,
  height: 550,
}
const block = block_2
const style = {
}
class Main extends Component {
  constructor(props) {
    super(props);
    const config = JSON.parse(localStorage.getItem('dashboard_config') || '{}')[sessionStorage.getItem('name')] || {}
    const setting = {
      usages: {
        time: config.usages ? config.usages.time : 2,
        transferType: config.usages ? config.usages.transferType : 'upload',
      },
      talks: {
        time: config.talks ? config.talks.time : 2,
      },
    }
    this.state = {
      edges: {},
      width: '',
      setting: setting,
    }
  }
  componentDidMount() {
    this.edges()
    this.setWidth()
    window.addEventListener('resize', this.setWidth)
  }
  setWidth = () => {
    const { clientWidth } = document.getElementById('dashboard')
    const num = parseInt(clientWidth / block.width)
    let width = 2 * block.width 
    switch (true) {
      case num >= 4:
        width = 4 * block.width 
        break;
      case num >= 3:
        width = 3 * block.width 
        break;
      default:
        width = 2 * block.width 
        break;
    }
    this.setState({
      width: width,
    })
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth)
  }
  edges = () => {
    Api.get.edges(response => {
      let edges = {}
      if (response.edges) {
        response.edges.map(edge => {
          edges[edge.sn] = {
            name: edge.name,
            geo: edge.geo,
            location: edge.location,
            conn_addr: edge.conn_addr,
          }
        })
      }
      this.setState({
        edges: edges,
      })
    })
  }
  render () {
    return(
      <Layout style={{paddingTop: '5px',width:'100%'}} id="dashboard">
        <div style={{width: this.state.width,overflow:'hiddren',display: 'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'flex-start',margin: '0 auto',}}>
          <div style={{width: 2 * block.width, height: block.height,...style}}>
            <Map refresh={this.edges} edges={this.state.edges}/>
          </div>
          <div style={{width: block.width, height: block.height,...style}}>
            <div style={{width: '100%',height: 2/5 * block.height,float:'left',}}>
              <Summary refresh={this.edges}/>
            </div>
            <div style={{width: '100%',height: 3/5 * block.height,float:'left',}}>
              <Usages refresh={this.edges} setting={this.state.setting.usages}/>
            </div>
          </div>
          <div style={{width: block.width, height: block.height,...style}}>
            <List type="talks" refresh={this.edges} edges={this.state.edges} setting={this.state.setting.talks}/>
          </div>
          <div style={{width: block.width, height: block.height,...style}}>
            <List type="upload" edges={this.state.edges}/>
          </div>
          <div style={{width: block.width, height: block.height,...style}}>
            <List type="download" edges={this.state.edges}/>
          </div>
          <div style={{width: 2 * block.width, height: block.height,...style}}>
            <Smarttrace edges={this.state.edges}/>
            </div>
        </div>
      </Layout>
    )
  }
}

export default Main;