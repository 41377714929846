import React, { Component } from 'react';
import echarts from 'echarts';
import moment from 'moment';
import Global from '../../../global/method';
import {Icon} from 'antd';

const indicatorsMap = {
  'bitrate': {
    name: 'Bitrate',
    valueFunc: (value) => {
      return Global.bandwidthSwitch(value).replace(' ', '\n')
    },
    unit: '',
  },
  'max_delay': {
    name: 'Max Delay',
    valueFunc: (value) => {
      return (value / 1000).toFixed(0)
    },
    unit: 'ms',
  },
  'loss_rate': {
    name: 'Packet Loss',
    valueFunc: (value) => {
      return Math.floor(value * 100)
    },
    unit: '%',
  },
  'jitter': {
    name: 'Jitter',
    valueFunc: (value) => {
      return (value / 1000).toFixed(0)
    },
    unit: 'ms',
  },
  'rtt': {
    name: 'RTT',
    valueFunc: (value) => {
      return (value / 1000).toFixed(0)
    },
    unit: 'ms',
  }
}
const aggregateMap = {
  'avg': {
    name: 'Average',
  },
  'max': {
    name: 'Maximum'
  },
  'min': {
    name: 'Minimum',
  }
}
const timegroupMap = {
  '60': {
    name: '60s',
  },
  '300': {
    name: '300s',
  },
  '600': {
    name: '600s',
  },
  '900': {
    name: '900s',
  },
  '1800': {
    name: '1800s',
  },
  '7200': {
    name: '7200s',
  }
}
class Main extends Component {
  componentDidMount() {
    this.drawInit()
    if (window.attachEvent) {
      window.attachEvent('onresize', this.onWindowResize)
    } else {
      window.addEventListener('resize', this.onWindowResize)
    }
  }
  initChart = () => {
    let id = 'statistics-chart' + this.props.index
    let main = document.getElementById(id)
    let existInstance = echarts.getInstanceByDom(main)
    if (existInstance !== undefined) {
      return existInstance
    } else {
      let myChart = echarts.init(main)
      return myChart
    }
  }
  draw = () => {
    const myChart = this.initChart()
    const data = this.getData()
    const dataX = data.x
    const dataY1 = data.y1.data
    const dataY2 = data.y2.data
    // console.log(dataY1)
    myChart.setOption({
      xAxis: {
        data: dataX,
      },
      series: [{
        name: 'A⇀B',
        data: dataY1,
      },{
        name: 'B⇀A',
        data: dataY2,
      }]
    })
  }
  drawInit = () => {
    const myChart = this.initChart()
    myChart.hideLoading()
    myChart.setOption({
      grid: {
        top: 35,
        bottom: 25,
        right: 30,
        left: 50,
      },
      color: ['#52c41a', '#508bc8'],
      legend: {
        show: true,
        // data: [
        //   {
        //     name: 'A⇀B',
        //     icon: 'roundRect',
        //   },
        //   {
        //     name: 'B⇀A',
        //     icon: 'roundRect',
        //   },
        // ]
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        formatter: (value, ticket) => {
          return `<table>
            <thead>
              <div style="padding:0 0 0 1px">
                ${moment(value[0].axisValue * 1000).format('MM-DD HH:mm')}
              </div>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div
                    style="display:inline-block;width:10px;height:10px;border-radius:10px;background:${value[0].color};margin-right:2px"
                  ></div>
                  ${value[0].data !== null ? (indicatorsMap[this.props.dataList.indicator].valueFunc(value[0].data) + ' ' + indicatorsMap[this.props.dataList.indicator].unit) : 'No Data'}
                </td>
              </tr>
              ${
                value[1]
                ? `<tr>
                    <td>
                      <div
                        style="display:inline-block;width:10px;height:10px;border-radius:10px;background:${value[1].color};margin-right:2px"
                      ></div>
                      ${value[1].data !== null ? (indicatorsMap[this.props.dataList.indicator].valueFunc(value[1].data) + ' ' + indicatorsMap[this.props.dataList.indicator].unit) : 'No Data'}
                    </td>
                  </tr>`
                : ''
              }
              
            </tbody>
          </tabel>`
        },
      },
      xAxis: {
        axisLabel: {
          formatter: (value) => {
            value = value * 1000
            if (this.props.timegroup > 300) {
              value = moment(value).format('MM-DD')
            } else {
              value = moment(value).format('HH:mm')
            }
            return value
          }
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
        },
        boundaryGap: false,
        type: 'category',
        data: [],
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        name: indicatorsMap[this.props.dataList.indicator].unit,
        nameTextStyle: {
          lineHeight: 5,
        },
        axisLabel: {
          formatter: (value) => {
            if (value) {
              return indicatorsMap[this.props.dataList.indicator].valueFunc(value)
            } else {
              return value
            }
          }
        },
        splitLine: {
          show: true,
        },
        scale: true,
      },
      series: [{
        name: 'A⇀B',
        data: [],
        type: 'line',
        symbol: 'none',
        // lineStyle: {
        //   color: '#52c41a',
        // },
      }, {
        name: 'B⇀A',
        data: [],
        type: 'line',
        symbol: 'none',
        // lineStyle: {
        //   color: '#508bc8',
        // },
      }]
    })
    this.draw()
  }
  getData = () => {
    return {
      x: this.props.date,
      y1: {
        data: this.props.dataList.series1,
      },
      y2: {
        data: this.props.dataList.series2,
      }
    }
  }
  onWindowResize = () => {
    const chart = this.initChart()
    chart.resize()
  }
  componentWillUnmount() {
    if (window.detachEvent) {
      window.detachEvent('onresize', this.onWindowResize)
    } else {
      window.removeEventListener('resize', this.onWindowResize)
    }
  }
  render () {
    return(
      <div style={{position:'relative', width:'100%', height:'100%',background:'#fff',borderRadius: '4px',overflow:'hidden',border: '1px solid #e8e8e8'}}>
        <div style={{padding: '0 20px', display: 'flex', justifyContent: 'space-between', lineHeight: '35px',background:'#e8e8e8'}}>
          <a>
            {indicatorsMap[this.props.dataList.indicator].name}
          </a>
          <div style={{fontSize: '12px',}}>
            <span>Period: {timegroupMap[this.props.timegroup].name}</span>
            &nbsp;
            &nbsp;
            <span>Method: {aggregateMap[this.props.dataList.aggregate].name}</span>
          </div>
        </div>
        <div id={'statistics-chart' + this.props.index} style={{position:'absolute', top: 40, right:0, bottom: 15, left:0,}}></div>
        {/* <div style={{position:'absolute', bottom:0, width:'100%', height:50,}}>
          <div style={{width:'100%',color:'#52c41a',overflow:'hidden'}}>
            <div style={{display:'inline-block',width:'calc(50% - 15px)',textAlign:'right'}}>
              {this.props.dataList.edge1}
            </div>
            <div style={{display:'inline-block',width: '30px',textAlign:'center',}}>
              <Icon type="swap-right"/>
            </div>
            <div style={{display:'inline-block',width:'calc(50% - 15px)',}}>
              {this.props.dataList.edge2}
            </div>
          </div> 
          <div style={{width:'100%',color:'#508bc8'}}>
            <div style={{display:'inline-block',width:'calc(50% - 15px)',textAlign:'right'}}>
              {this.props.dataList.edge2}
            </div>
            <div style={{display:'inline-block',width: '30px',textAlign:'center',}}>
              <Icon type="swap-right" />
            </div>
            <div style={{display:'inline-block',width:'calc(50% - 15px)',}}>
              {this.props.dataList.edge1}
            </div>
          </div> 
        </div> */}
      </div>
    )
  }
}

export default Main;