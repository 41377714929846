import '../../style/system.css';
import React from 'react';
import { Input, Form, Icon, Select, Button, InputNumber, Switch } from 'antd'
import Api from '../../global/systemApi'
import Global from '../../global/method'
import Lang from '../../global/language'
import State from './info/state'

const Component = React.Component
const createForm = Form.create;
const FormItem = Form.Item;
const Option = Select.Option;
class Info extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.net()
    this.getTime()
    this.getVersion()
  }
  componentWillUnmount() {
    clearInterval(this.intervalA)
  }
  setUptime = (value) => {
    this.setState({
      uptime: value,
    })
  }
  intervalTime = () => {
    clearInterval(this.intervalA)
    let time = this.state.time
    this.intervalA = setInterval(() => {
      time = time + 1
      this.setState({
        time: time,
      })
    }, 1000);
  }
  getTime = () => {
    Api.time(response => {
      let time = response.time - response.time_offset
      this.setState({
        time: time,
      })
      this.intervalTime()
    })
  }
  getVersion = () => {
    Api.version(response => {
      this.setState({version: {
        sw_version: response.sw_version,
        hw_version: response.hw_version,
      }})
    })
  }
  net = () => {
    Api.netif(response => {
      let netif = {
        mode: response.mode,
        ip: response.ip,
        netmask: response.netmask,
        gateway: response.gateway,
        dns: response.dns,
        dns2: response.dns2,
        mtu: response.mtu,
        name: response.name,
        carrier: response.carrier,
        state: response.state,
        mac_addr: response.mac_addr,
      }
      this.setState({
        netif: netif,
      })
    })
  }
  state = {
    time: '',
    uptime: '',
    netif: '',
    version: {
      sw_version: '',
      hw_version: '',
    }
  }
  render() {
    const { getFieldProps, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 12 },
    };
    return (
      <div className="strategy">
        <div className="box normal">
          <div className="title">{Lang.system.l176}</div>
          <div className="main">
            <Form>
              <FormItem
                label={Lang.system.l178}
                {...formItemLayout}
              >
                {this.state.version.sw_version}
              </FormItem>
            </Form>
          </div>
        </div>
        <div className="box normal">
          <div className="title">{Lang.system.l177}</div>
          <div className="main">
            <Form>
              <FormItem
                label={Lang.system.l179}
                {...formItemLayout}
              >
                {Global.timeSwitch(this.state.time, 'UTC')}
              </FormItem>
            </Form>
          </div>
        </div>
        <div className="box normal">
          <div className="title">{Lang.system.l180}</div>
          <div className="main">
            <Form>
              <FormItem
                label={Lang.system.l181}
                {...formItemLayout}
              >
                {this.state.netif.name}
              </FormItem>
              <FormItem
                label={Lang.system.l182}
                {...formItemLayout}
              >
                {this.state.netif.carrier ?Lang.system.l150.l0:Lang.system.l150.l1}
              </FormItem>
              <FormItem
                label={Lang.system.l183}
                {...formItemLayout}
              >
                {this.state.netif.mac_addr}
              </FormItem>
              <FormItem
                label={Lang.system.l184}
                {...formItemLayout}
              >
                {this.state.netif.mode === 'static' ? Lang.system.l185 : 'DHCP'}
              </FormItem>
              <FormItem
                label={Lang.system.l186}
                {...formItemLayout}
              >
                {this.state.netif.ip}
              </FormItem>
              <FormItem
                label={Lang.system.l187}
                {...formItemLayout}
              >
                {this.state.netif.netmask}
              </FormItem>
              <FormItem
                label={Lang.system.l188}
                {...formItemLayout}
              >
                {this.state.netif.gateway}
              </FormItem>
            <FormItem
                label="MTU"
                {...formItemLayout}
              >
                {this.state.netif.mtu}
              </FormItem>
              <FormItem
                label={Lang.system.l189}
                {...formItemLayout}
              >
                {this.state.netif.dns}
              </FormItem>
              <FormItem
                label={Lang.system.l190}
                {...formItemLayout}
              >
                {this.state.netif.dns2}
              </FormItem>
            </Form>
          </div>
        </div>
        <div className="box normal">
          <div className="title">{Lang.system.l252}</div>
          <div className="main">
            <State callbackParent={this.setUptime}/>
          </div>
        </div>
      </div>
    )
  }
}
Info = createForm()(Info);
export default Form.create()(Info)
