import '../style/deployment.css'
import React, { Component } from 'react';
import { Layout, Drawer, Button, Alert, Icon, Steps} from 'antd'
import Basic from '../containers/deployment/form-deployment-basic'
import Topology from '../containers/deployment/form-deployment-graph'
import Context from '../common/context/'
import Node from '../containers/deployment/graph/node-edit'
import Link from '../containers/deployment/graph/link-edit'
import Api from '../global/api'
import SystemApi from '../global/systemApi'
import Organization from '../containers/deployment/topology-list-cell'
import InternetSetting from '../containers/deployment/internet-setting'
import Lang from '../global/language'
import Method from '../global/method'

const Step = Steps.Step
class Main extends Component {
  constructor(props) {
    super(props);
    this.init()
  }
  setWidth = (type) => {
    const { clientWidth } = document.getElementById('topology')
    const width = parseInt(clientWidth / 2) < 450 ? '100%' : '50%'
    this.setState({
      width: width,
    })
    if (type === 'init') {
      this.setState({
        linksState: {},
        isRefresh: true,
      })
    }
  }
  init () {
    this.state = {
      license: {},
      linksState: {},
      saveLoading: false,
      applyLoading: false,
      topologies: [],
      topology: null,
      paramsOld: null,
      alert: {
        show: false,
        message: '',
      },
      save: false,
      addNode: {
        id: null,
      },
      edges: [],
      hub: null,
      saveEl: false,
      nodes: [],
      edges: [],
      menu: null,
      removeItemId: null,
      addItemId: null,
      settingId: null,
      setting: null,
      visible: false,
      childrenVisible: false,
      type: 'new',
      current: 0,
      basic: {
        name: '',
        kind: 1,
        description: '',
      },
      onRefGraph: this.onRef1,
      width: 520,
      isUpdate: false,
      isRefresh: false,
      logicLinks: null,
      internetSetting: {
        show: false,
      },
      setAddNode: (value) => {
        let state = null
        if (!value) {
          state = {
            addNode: {
              id: null,
              name: null,
            },
          }
        } else {
          state = {
            addNode: {
              sn: value.sn,
              id: value.id,
              name: value.name,
              data: value.data,
            },
          }
        }
        this.setState(state)
      },
      setValue: (object, callback) => {
        this.setState({
          ...object
        }, () => {
          if (callback) {
            callback(true)
          }
        })
      },
      updateButtonStatus: (updateType) => this.updateButtonStatus(updateType),
      buttonType: null,
    }
  }
  showDrawer = (index) => {
    clearTimeout(this.interval)
    this.interval = null
    if (index === 'new') {
      this.setState({
        visible: true,
        type: 'new',
        topology: null, 
        paramsOld: null,
        current: 0,
        hub: null,
        saveLoading: false,
        applyLoading: false,
        basic: {
          name: '',
          kind: 1,
          mode: 0,
          description: '',
        }, 
        buttonType: null,
        logicLinks: [],
      })
    } else {
      const data = JSON.parse(JSON.stringify(this.state.topologies[index]))
      this.paramsOld = data
      this.setState({
        visible: true,
        type: 'edit',
        hub: null,
        current: 0,
        topology: data,
        basic: {
          name: data.name,
          kind: 1,
          mode: data.mode,
          description: data.description,
        },
        buttonType: 'apply',
        logicLinks: data.logic_links || [],
      })
    }
  }
  internetSetting = (index) => {
    const topology = this.state.topologies[index]
    this.setState({
      internetSetting: {
        show: true,
        topology: topology,
      }
    })
  }
  closeInternetSetting = () => {
    this.setState({
      internetSetting: {
        show: false,
      }
    })
  }
  onClose = () => {
    this.setState({
      visible: false,
    }, () => {
      this.initTopologies('init')
    })
  }
  alertMessage = (type, message) => {
    this.setState({
      alert: {
        type: type,
        show: true,
        message: message,
      }
    }, () => {
      setTimeout(()=>{
        this.setState({
          alert: {
            type: type,
            show: false,
            message: '',
          }
        })
      }, 3000)
    })
  }
  onChildrenClose = () => {
    this.setState({
      setting: null,
    })
  }
  onRef = (ref) => {
    this.child = ref
  }
  onRef1 = (ref) => {
    this.child1 = ref
  }
  next = () => {
    this.child.save()
  }
  pre = () => {
    this.setState({
      current: 0,
      saveLoading: false,
      applyLoading: false,
      buttonType: 'apply',
      addNode: {
        id: null,
      },
      hub: null,
    })
  }
  apply = () => {
    this.setState({
      applyLoading: true,
    })
    Api.post.setTopology(this.state.topology.id, 'enable', response => {
      this.setState({
        applyLoading: false,
      })
      if (!response.err) {
        this.alertMessage('success', 'Apply success')
      } else {
        let lang = 'Apply error'
        if (response.data.errno === 400007) {
          lang = 'Apply busy.Please try again later'
        }
        this.alertMessage('error', lang)
      }
    })
  }
  getParams = () => {
    const data  = this.child1.getParams()
    if (data === null) {
      return null
    }
    const params = {
      ...this.state.basic,
      ...data,
    }
    return params
  }
  updateButtonStatus = (type) => {
    if (this.state.type === 'new') {
      let type = this.state.hub ? 'save' : null
      this.setState({
        buttonType: type,
      })
      return
    }
    const paramsOld = JSON.parse(JSON.stringify(this.paramsOld || {}))
    const params = this.getParams() || {}
    if (params.nodes && params.nodes.length < 2) {
      this.setState({
        buttonType: null,
      })
      return
    }
    let isDiff = (() => {
      if (paramsOld.name !== params.name || paramsOld.description !== params.description) {
        return true
      }
      const linksOldObject = {}
      const nodesOldObject = {}
      paramsOld.links.forEach(ele => {
        linksOldObject[ele.id] = ele
      })
      paramsOld.nodes.forEach(ele => {
        nodesOldObject[ele.edge_sn] = ele
      })
      if (this.state.basic.mode === 1) {
        paramsOld.logic_links = []
        params.logic_links = []
      }
      switch (true) {
        case paramsOld.description !== params.description:
        case paramsOld.name !== params.name:
        case paramsOld.links.length !== params.links.length:
        case paramsOld.nodes.length !== params.nodes.length:
        case paramsOld.logic_links.length !== params.logic_links.length:
          return true
        case paramsOld.nodes.length === params.nodes.length:
          for (let index = 0; index < params.nodes.length; index++) {
            let node = params.nodes[index];
            let nodeOld = nodesOldObject[node.edge_sn]
            if (!nodeOld) {
              return true
            }
            switch (true) {
              case node.kind !== nodeOld.kind:
                return true
              case node.dns_server !== nodeOld.dns_server:
                return true
              case node.sg_id !== nodeOld.sg_id:
                return true
              case node.local_subnets[0].addr !== nodeOld.local_subnets[0].addr:
                return true
              case node.local_subnets[0].addr2 !== nodeOld.local_subnets[0].addr2:
                return true
              case JSON.stringify(node.snat) !== JSON.stringify(nodeOld.snat):
                if (node.snat && nodeOld.snat) {
                  if (node.snat.enable !== nodeOld.snat.enable) {
                    return true
                  } else if (node.snat.enable && node.snat.to_source !== nodeOld.snat.to_source) {
                    return true
                  }
                } else {
                  return true
                }
              // case JSON.stringify(node.tunnel_sources) !== JSON.stringify(nodeOld.tunnel_sources):
              //   return true
              case JSON.stringify((node.extra_routes || [])) !== JSON.stringify((nodeOld.extra_routes || [])):
                if (!nodeOld.extra_routes || !node.extra_routes) {
                  return true
                }
                if (nodeOld.extra_routes.length !== node.extra_routes.length) {
                  return true
                } else {
                  for (let index = 0; index < node.extra_routes.length; index++) {
                    const nodeRoute = node.extra_routes[index]
                    const nodeOldRoute = nodeOld.extra_routes[index]
                    if (nodeRoute.dst !== nodeOldRoute.dst || nodeRoute.via !== nodeOldRoute.via) {
                      return true
                    }
                  }
                }
              case JSON.stringify(node.bypass_deploy) !== JSON.stringify(nodeOld.bypass_deploy):
                if (!nodeOld.bypass_deploy) {
                  if (node.bypass_deploy.enable) {
                    return true
                  }
                } else {
                  if (nodeOld.bypass_deploy.enable || node.bypass_deploy.enable) {
                    return true
                  }
                }
            }
          }
        case paramsOld.links.length === params.links.length:
          for (let index = 0; index < params.links.length; index++) {
            let link = params.links[index];
            let linkOld = linksOldObject[link.id]
            if (!linkOld) {
              return true
            }
            if (link.kind !== linkOld.kind) {
              return true
            }
            if (JSON.stringify(link.tcp_optimize) !== JSON.stringify(linkOld.tcp_optimize)) {
              return true
            }
          }
        case paramsOld.logic_links.length === params.logic_links.length:
          if (type && type.type === 'logicLinks') {
            let spokeOld = []
            let spoke = []
            const id = type.id
            paramsOld.logic_links.forEach(ele => {
              if (ele.node1 === id || ele.node2 === id) {
                let spokeId = ele.node1 === id ? ele.node2 : ele.node1
                spokeOld.push(spokeId)
              }
            })
            params.logic_links.forEach(ele => {
              if (ele.node1 === id || ele.node2 === id) {
                let spokeId = ele.node1 === id ? ele.node2 : ele.node1
                if (spokeOld.indexOf(spokeId) === -1) {
                  return true
                }
                spoke.push(spokeId)
              }
            })
            if (spokeOld.length !== spoke.length) {
              return true
            }
          }
        default:
          return false
      }
    })()
    let mode = this.state.hub ? (isDiff ? 'save' : 'apply') : null
    this.setState({
      buttonType: mode,
    })
  }
  save = () => {
    const params = this.getParams()
    if (params === null) {
      this.alertMessage('warning', 'Edge config can not be blank')
      return
    }
    this.setState({
      saveLoading: true,
    })
    let saveFunc = null
    if (this.state.type === 'new') {
      saveFunc = new Promise((resolve, reject) => Api.post.topologies(params, response => {
        this.setState({
          saveLoading: false,
        })
        if (!response.err) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }))
    } else {
      saveFunc = new Promise((resolve, reject) => Api.put.topologies(this.state.topology.id, params, response => {
        this.setState({
          saveLoading: false,
        })
        if (!response.err) {
          resolve(response.data)
        } else {
          reject(response.data)
        }
      }))
    }
    saveFunc.then(response => {
      this.alertMessage('success', 'Save success')
      this.paramsOld = response
      this.setState({
        topology: response,
        buttonType: 'apply',
        logicLinks: response.logic_links,
      }, () => {
        this.child1.draw()
        this.setState({
          type: '',
        })
      })
    }).catch(error => {
      if (error.errno) {
        const errorLang = Lang.error['l'+ error.errno]
        if (errorLang) {
          this.alertMessage('error', errorLang)
        } else {
          this.alertMessage('error', 'Save error')
        }
      } else {
        this.alertMessage('error', 'Save error')
      }
    })
  }
  onChange = () => {}
  componentDidMount() {
    this.initTopologies()
    this.setWidth()
    this.license()
    window.removeEventListener('resize', this.resizeListener)
    window.addEventListener('resize', this.resizeListener = Method.debounce(
      () => this.setWidth('init')
    ), false)
  }
  license = () => {
    SystemApi.license(response => {
      this.setState({
        license: {
          r2tpSupport: response.r2tp_support,
        }
      })
    })
  }
  initTopologies = (type) => {
    Api.get.edges(response => {
      let edges = []
      response.edges.map(edge => {
        if (edge.is_managed) {
          edges.push({
            ...edge,
            disable: false,
            local_subnets: edge.lans,
          })
        }
      }) 
      let isUpdate = type === 'init'
      this.setState({
        linksState: {},
        edges: edges,
        isUpdate: isUpdate,
      }, () => {
        this.getTopologies(isUpdate, () => {
          this.getLinksStateInterval()
        })
      })
    })
  }
  delTopology = (index, callback) => {
    const id = index 
    Api.delete.topology(id, response => {
      if (!response.err) {
        callback()
        this.initTopologies('init')
      }
    })
  }
  componentWillUnmount() {
    clearTimeout(this.interval)
    window.removeEventListener('resize', this.resizeListener)
  }
  getTopologies = (isUpdate, callback) => {
    Api.get.topologies(response => {
      if (response.networks.length === 0) {
        // this.showDrawer('new')
      }
      let edges = this.state.edges
      response.networks.map(response => {
        response.nodes.map(node => {
          let index = edges.findIndex(ele => {
            return ele.sn === node.edge_sn
          })
          if (index !== -1) {
            edges[index].disable = true
          } else {
            if (node.local_subnets) {
              edges[index].local_subnets = node.local_subnets
            }
          }
        })
      })
      this.setState({
        topologies: response.networks,
        edges: edges,
        isUpdate: isUpdate,
        linksState: {},
      }, () => {
        callback()
      })
    })
  }
  getLinksStateInterval = (time) => {
    if (!time) {
      time = 1
    }
    clearInterval(this.interval)
    this.interval = setTimeout(()=>{
      this.interval = null
      this.getLinksState()
      this.getLinksStateInterval(5000)
    }, time)
  }
  getLinksState = () => {
    Api.get.state(response => {
      let linksState = {}
      let data = response || []
      data.forEach(ele => {
        linksState[ele.link_id] = ele
      })
      this.setState({
        linksState: linksState,
      })
    })
  }
  handleBasicChange = (value) => {
    let basic = this.state.basic
    basic[Object.keys(value)[0]] = Object.values(value)[0]
    this.setState({
      basic: basic,
    })
  }
  DrawerTitle = () => {
    let title = this.state.type === 'new' ? 'Add Topology' : 'Edit Topology'
    return(
      <span>
        <span style={{padding: '0 8px 0 0',height: 55}}>{title}</span>
        <span style={{display: 'inline-block',}}>></span>
        <span style={{display: 'inline-block',width: 300,float: 'right',margin: '0 490px 0 0'}}>
          <Steps current={this.state.current} size="small">
            <Step key={0} title="Basic Settings" />
            <Step key={1} title="Topology Settings" />
          </Steps>
        </span>
      </span>
    )
  }
  updateComplete = (callback) => {
    this.setState({
      isUpdate: false,
    },() => {
      callback()
    })
  }
  refreshComplete = (callback) => {
    this.setState({
      isRefresh: false,
    },() => {
      callback()
    })
  }
  onResize = () => {
    if (this.hasTimeOut) {
      clearTimeout(this.hasTimeOut)
    }
    this.hasTimeOut = setTimeout(()=> {
      this.init()
    }, 300)
  }
  render () {
    return(
      <Context.Provider value={this.state}>
      <Layout style={{display:'flex',padding:'0 10px 20px 10px',width:'100%',}} className="deployment">
        <div style={{width:'100%',padding: "20px 10px 10px 10px"}}>
          <Button onClick={() => this.showDrawer('new')}>Add Topology</Button>
        </div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',flexWrap:'wrap',}} id="topology">
          {this.state.topologies.map((topology, index) => {
            return (
              <div style={{
                position: 'relative',
                width: this.state.width,
                height: 0,
                paddingBottom: this.state.width === '100%' ? '100%' : '45%',
              }} key={topology.id}>
                <Organization
                  edges={this.state.edges}
                  isUpdate={this.state.isUpdate}
                  updateComplete={(callback) => this.updateComplete(callback)}
                  isRefresh={this.state.isRefresh}
                  refreshComplete={(callback) => this.refreshComplete(callback)}
                  organization={{data: topology, index: index}}
                  linksState={this.state.linksState}
                  editTopology={this.showDrawer} internetSetting={topology.mode === 0 ? this.internetSetting : false}
                  delTopology={(id, callback) => this.delTopology(id, callback)}
                />
              </div>
            )
          })}
        </div>
        <Drawer
          title= {this.DrawerTitle()}
          width={1000}
          maskClosable={false}
          visible={this.state.visible}
          onClose={this.onClose}
          destroyOnClose={true}
        >
          <Layout>
            {this.state.current === 0 && <Basic onRef={this.onRef}/>}
            {this.state.current === 1 && <Topology onRef={this.onRef1}/>}
          </Layout>
          <div style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }}>
            {
              this.state.current === 1 && <Button onClick={this.pre} >Previous</Button>
            }
            {
              this.state.current === 0 && <Button  onClick={this.next} type="primary">Next</Button>
            }
            {
              this.state.current === 1 && <Button style={{marginLeft: 30}} onClick={this.save} disabled={!(this.state.buttonType ==='save')} type={false ? 'dashed':'primary'} loading={this.state.saveLoading}>Save</Button>
            }
            {
              this.state.current === 1 && <Button style={{marginLeft: 30}} onClick={this.apply} disabled={!(this.state.buttonType ==='apply')} type={false ? 'dashed':'primary'} loading={this.state.applyLoading}>Apply</Button>
            }
          </div>
          <div style={{display: this.state.alert.show ? 'flex' : 'none',position: 'absolute',width:'100%', left: 0,top: 160, paddingLeft: '200px', justifyContent: 'center',transition: 'display 3s'}}>
            <Alert message={this.state.alert.message} type={this.state.alert.type} showIcon />
          </div>
        </Drawer>
        <Drawer
          maskClosable={false}
          visible={this.state.setting !== null}
          title = {this.state.setting ? this.state.setting.title : ''}
          onClose={this.onChildrenClose}
          width = {450} 
          destroyOnClose={true}
          className="graph-edit"
        >
          {this.state.setting && this.state.setting.type === 'node'? <Node /> : null}
          {this.state.setting && this.state.setting.type === 'edge'? <Link /> : null}
        </Drawer>
        {this.state.internetSetting.show ? <InternetSetting edges={this.state.edges} topology={this.state.internetSetting.topology} cancel={this.closeInternetSetting}/> : null}
      </Layout>
      </Context.Provider>
    )
  }
}

export default Main;