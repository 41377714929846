import axios from 'axios'
import {message} from 'antd'
import Lang from './language'
import { history } from './units'

if (!window.location.origin) {
  window.location.origin = window.location.protocol + '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '')
}
var rootPath = window.location.origin + '/api/v1'
// var rootPath = 'http://203.198.220.157:8800/smarttrace/api/v1'

const service = axios.create({
  baseURL: rootPath,
  timeout: 10000,
  headers: {
    'Authorization': sessionStorage.getItem('token')
  },
})
const CancelToken = axios.CancelToken;
let pending = []
const removePending = (config) => {
  for (let i in pending) {
    if (pending[i].id === config.url + '&' + config.method + '&' + JSON.stringify(config.params)) {
      pending[i].f('Abort: ' + config.url)
      pending.splice(i, 1)
    }
  }
}
service.interceptors.request.use(
  config => {
    removePending(config)
    let configUrl = config.baseURL + config.url
    config.cancelToken = new CancelToken(function executor (c){
      pending.push({
        id: configUrl + '&' + config.method + '&' + JSON.stringify(config.params),
        f: c,
      })
    })
    return Promise.resolve(config)
  },
  error => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    removePending(response.config)
    const path = response.request.responseURL.split('/')
    if (response.config.method === 'put') {
      switch (true) {
        case path.indexOf('net_topologies') !== -1 && path.indexOf('internet_access') === -1:
          break
        default:
          message.destroy()
          message.success('Success')
      }
    }
    if (response.config.method === 'delete') {
      message.success('Delete Success')
    }
    if (response.config.method === 'post') {
      switch (true) {
        case path.indexOf('refresh_token') !== -1:
          break
        case path.indexOf('license_check') !== -1:
          break
        case path.indexOf('net_topologies') !== -1 && path.indexOf('internet_access') === -1:
          break
        default:
          message.destroy()
          message.success(Lang.prompt.l104)
      }
    }
    return Promise.resolve(response)
  },
  error => {
    if (error.response) {
      const path = error.request.responseURL.split('/') 
      const errno = error.response.data.errno
      outer:
      switch (errno) {
        case 401005:
          sessionStorage.removeItem('token')
          history.push('/controller/login')
          break;
        case 401001:
          message.destroy()
          message.warning('Account or passsword error')
          break
        case 401002:
          message.destroy()
          message.warning('Captcha error')
          break
        case 500050:
        case 500051:
        case 500052:
        case 500054:
        case 500055:
          if (history.location.pathname !== '/controller/system/license') {
            history.push('/controller/system/license#input')
            break
          }
        case 400001:
          switch (true) {
            case path.indexOf('custom') !== -1:
              message.warning('Hosts invalid')
              break outer;
          }
        case 400008:
          switch (true) {
            case path.indexOf('custom') !== -1: 
              break outer
            case path.indexOf('work_mode') !== -1:
              message.destroy()
              message.warning('This Edge is being used.')
              break outer
          }
        case 500053:
        default:
          if (error.config.method === 'get') {
            message.destroy()
            message.warning(Lang.error['l' + errno])
            break
          }
          if (error.config.method === 'put' || error.config.method === 'post') {
            switch (true) {
              case path.indexOf('edges') !== -1:
                if (errno === 400007) {
                  message.warning('This Edge is being used.')
                  break
                }
              case path.indexOf('net_topologies') !== -1:
                break
              default:
                message.warning('Fail')
            }
            break
          }
          if (error.config.method === 'delete') {
            switch (true) {
              case path.indexOf('security_groups') !== -1:
                message.warning('This rule is being used.')
                break
              default:
                message.warning('Delete Fail')
            }
          }
      }
    }
    return Promise.reject(error)
  },
)
export default service