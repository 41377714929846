import React, { Component } from 'react';
import { Menu, Icon } from 'antd';
import Lang from '../global/language';
import { Router, Route, Link, withRouter, Redirect, Switch} from 'react-router-dom'
import Security from '../containers/policies/security'
import Predefined from '../containers/policies/application/predefined'
import Custom from '../containers/policies/application/custom'

class Main extends Component {
  componentDidMount() {}
  componentWillUnmount() {}
  state = {
  }
  componentDidUpdate(prevProps, prevState) {
  }
  render () {
    const {match} = this.props
    const getHostname = () => {
      let path = this.props.location.pathname
      return path
    }
    return(
      <div style={{width: '100%',height: '100%',display:'flex',}}>
        <div style={{width: 250,}}>
          <Menu
            mode="inline"
            selectedKeys={[getHostname()]}
            style={{
              width: '240px',
              height: '100%',
              paddingTop: '10px',
              background: '#fff',
            }}
          >
            <Menu.Item key={`${match.url}/security`} >
              <Link style={{display:'inline-block',fontSize:'14px',paddingLeft:'10px',}} to={`${match.url}/security`}>Security Group</Link>
            </Menu.Item>
            <Menu.ItemGroup title={
              <span style={{fontSize:'14px',paddingLeft:'15px'}}>
                <span>Application Management</span>
              </span>
            }>
              <Menu.Item key={`${match.url}/application/predefined`}>
                <Link style={{display:'inline-block',fontSize:'14px',paddingLeft:'20px',}} to={`${match.url}/application/predefined`}>Predefined</Link>
              </Menu.Item>
              <Menu.Item key={`${match.url}/application/custom`}>
                <Link style={{display:'inline-block',fontSize:'14px',paddingLeft:'20px',}} to={`${match.url}/application/custom`}>Custom</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </div>
        <div className="system" style={{height: '100%',flexGrow:1, padding:'20px',position: 'relative',}}>
          <Switch>
            <Route path={`${match.url}/security`} component={Security}/>
            <Route path={`${match.url}/application/predefined`} component={Predefined}/>
            <Route path={`${match.url}/application/custom`} component={Custom}/>
          </Switch>
        </div>
      </div>
    )
  }
}

export default Main;