import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./style/theme.less"
import './style/index.css';

// 解决浏览器标签页不共享 sessionStorage 的问题 (新版chrome失效)
(function() {
  const isNewTab = !sessionStorage.length
  if (isNewTab) {
    localStorage.setItem('getSessionStorage', Date.now())
  }
  window.addEventListener('storage', (event) => {
    if (event.key === 'getSessionStorage') {
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
			localStorage.removeItem('sessionStorage')
    } else if (event.key === 'sessionStorage' && isNewTab) {
      const data = JSON.parse(event.newValue) || {}
      Object.keys(data).map(key => {
        sessionStorage.setItem(key, data[key])
      })
    }
  })
})()
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA