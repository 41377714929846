import React, { Component } from 'react';
import { Table, Button, Input, Form, InputNumber, Select } from 'antd'
import Lang from '../../global/language'
import { Port, Cidr } from '../../common/validator';

const Option = Select.Option;
const EditableContext = React.createContext()
const RulesJson = Lang.securityRules.map((ele, index) => {
  let data = {...ele}
  data.text = ele.type
  data.type = index
  data.protocol = ele.protocol === null ? 'all' : ele.protocol
  data.port_range = ele.port_range === null ? 'all' : ele.port_range.join('-')
  return data
})
class EditableRow extends React.Component {
  state = {
    data: RulesJson[0],
    destinationType: 0,
  }
  save = () => {
    this.rowProps.form.validateFields((errors, values) => {
      if (!!errors) {
        return
      }
      let data = this.rowProps.data
      data[data.length - 1] = {
        key: data.length - 1,
        type: {
          text: RulesJson[values.type].text,
          index: values.type,
        },
        protocol: values.protocol,
        port_range: values.port_range,
        destination: values.destination,
        description: values.description,
      }
      this.rowProps.setValue('editingKey', null)
      this.rowProps.setValue('data', data)
    })
  }
  cancel = () => {
    let data = this.rowProps.data
    data.splice(this.rowProps.data.length - 1, 1)
    this.rowProps.setValue('editingKey', null)
    this.rowProps.setValue('data', data)
  }
  destinationChange = (value) => {
    this.setState({
      destinationType: value,
    }, () => {
      this.rowProps.form.setFieldsValue({
        destination: value === 0 ? '0.0.0.0/0' : '',
      })
    })
  }
  onChange = (index) => {
    const data = Lang.securityRules[index]
    data.type = index
    this.setState({
      data: data,
    }, () => {
      this.init()
    })
  }
  init = () =>{
    const {data} = this.state
    this.rowProps.form.setFieldsValue({
      type: data.type,
      protocol: data.protocol,
      port_range: data.port_range,
    })
  }
  Row = () => {
    const {data} = this.state
    return (
      <tr>
        <td>
          <Form.Item style={{ margin: 0 }}>
            {this.rowProps.form.getFieldDecorator('type', {
              rules: [
                {
                  required: true,
                  message: `Please Input Type!`
                }
              ],
              initialValue: data.type,
              })(
              <Select style={{width: 155}} size="small" onChange={this.onChange}>
                {RulesJson.map(
                  (rule, index) => {
                    if (index === 2 || index === 3) {
                      return null
                    } else {
                      return (<Option key={index} value={index}>{rule.text}</Option>)
                    }
                  }
                )}
              </Select>
            )}
          </Form.Item>
        </td>
        <td>
          <Form.Item style={{ margin: 0 }}>
            {this.rowProps.form.getFieldDecorator('protocol', {
              rules: [
                {
                  required: true,
                  message: `Please Input Protocol!`
                }
              ],
              initialValue: data.protocol,
            })(
              <Input size="small" disabled={true}/>
            )}
          </Form.Item>
        </td>
        <td>
          <Form.Item style={{ margin: 0 }}>
          {this.rowProps.form.getFieldDecorator('port_range', {
            rules: [
              {
                required: true,
                message: `Please Input Port Range!`
              },
              {
                validator: Port,
              },
            ],
            initialValue: data.port_range,
          })(
            <Input placeholder="1-65535" size="small" disabled={this.state.data.port_range === null || this.state.data.port_range.length !== 0}/>
          )}
          </Form.Item>
        </td>
        <td>
          <Form.Item style={{ margin: 0 }}>
          <Select size="small" style={{width: 120}} defaultValue={0} onChange={this.destinationChange}>
            <Option value={0}>
               All
            </Option>
            <Option value={1}>
              Customize
            </Option>
          </Select>
          {this.rowProps.form.getFieldDecorator('destination', {
            rules: [
              {
                required: true,
                message: `Please Input Destination!`
              },
              {validator: Cidr},
            ],
            initialValue: '0.0.0.0/0',
          })(
            <Input size="small" placeholder="CIDR format" style={{width: 120}} disabled={this.state.destinationType === 0}/>
          )}
          </Form.Item>
        </td>
        <td>
          <Form.Item style={{ margin: 0 }}>
          {this.rowProps.form.getFieldDecorator('description', {
            rules: [
              {
                required: false,
                message: `Please Input Description!`
              }
            ],
          })(
            <Input size="small"/>
          )}
          </Form.Item>
        </td>
        <td>
          <a href="javascript:;" style={{marginRight: '10px'}} onClick={this.save}>Save</a>
          <a href="javascript:;" onClick={this.cancel}>Cancel</a>
        </td>
      </tr>
    )
  }

  renderRow = (props) => {
    const {
      editing,
      children,
    } = this.props;
    this.rowProps = props
    const tr = editing ? this.Row() : <tr>{children}</tr>
    return tr
  };
  render() {
    return (
      <EditableContext.Consumer>{this.renderRow}</EditableContext.Consumer>
    );
  }
}
class Main extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Type',
        dataIndex: 'type',
        editable: true,
        width: 155,
        render: (text, record) => {
          return text.text
        }
      },
      {
        title: 'Protocol',
        dataIndex: 'protocol',
        width: 80,
        textWrap: 'ellipsis',
        editable: true,
      },
      {
        title: 'Port Range',
        dataIndex: 'port_range',
        editable: true,
        width: 100,
      },
      {
        title: this.props.type === 'inbound' ? 'Source' : 'Target',
        dataIndex: 'destination',
        editable: true,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        editable: true,
        width: 120,
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        width: 120,
        render: (text, record, index) => {
          return (
            this.props.edit ? <a href="javascript:;" onClick={() => this.delete(index)}>Delete</a> : null
          )
        }
      },
    ]
  }
  componentDidMount() {
    this.props.onRef(this)
    this.init()
  }
  componentWillUnmount() {}
  init = () => {
    const data = JSON.parse(JSON.stringify(this.props.data))
    const newData = data.filter((ele, index) => {
      ele.key = index
      ele.protocol = RulesJson[ele.type].protocol === null ? 'all' : RulesJson[ele.type].protocol
      ele.type = {
        text: RulesJson[ele.type].text,
        index: ele.type,
      }
      ele.port_range = ele.port_range.join('-')
      ele.destination = ele.address
      return true
    })
    this.setState({
      data: newData,
    })
  }
  state = {
    data: [],
    editingKey: null,
    form: this.props.form,
    setValue: (name, value) => {
      this.setState({
        [''+name+'']: value,
      })
    }
  }
  getData = () => {
    const data = JSON.parse(JSON.stringify(this.state.data))
    const newData = data.filter(ele => {
      if (ele.type === '') {
        return false
      } else {
        delete ele['key']
        ele.type = ele.type.index
        ele.protocol = ele.protocol.toLowerCase()
        ele.port_range = ele.port_range === 'all' ? null : ele.port_range.split('-').map(
          ele => {
            ele = parseInt(ele)
            return ele
          }
        )
        ele.address = ele.destination
        delete ele['destination']
        return true
      }
    })
    return newData
  }
  edit(key) {
    this.setState({editingKey: key,})
  }
  delete = (index) => {
    let data = this.state.data
    data.splice(index, 1)
    if (data.length && data[data.length - 1].type === '') {
      data.splice(data.length - 1, 1)
    }
    const newData = data.filter((ele, index) => {
      ele.key = index
      return true
    })
    this.setState({data: newData, editingKey: null,})
  }
  isEditing = record => record.key === this.state.editingKey
  showAddCell = () => {
    const data = this.state.data
    this.setState({
      data: [
        ...data,
        {
          key: data.length,
          type: '',
          protocol: '',
          port_range: '',
          destination: '',
          description: '',
        }
      ]
    }, () => {
      this.edit(data.length)
    })
  }
  render () {
    const components = {
      body: {
        row: EditableRow
      }
    }
    return(
      <div>
        <EditableContext.Provider value={this.state}>
          <Table
            columns={this.columns} 
            onRow={
              record=> {
                return {
                  ...record,
                  editing: this.isEditing(record)
                }
              }
            }
            dataSource={this.state.data} 
            size="middle"
            pagination={false}
            onHeaderRow={()=>{
              return {
                style: {
                  background: '#fff',
                }
              }
            }}
            components={components}
          >
          </Table>
          <div style={{
            height: '90px',
            width: '140px',
          }}>
            {this.props.edit ? <Button onClick={this.showAddCell} disabled={this.state.editingKey !== null} type="primary" style={{marginTop: '10px'}}>Add Rule</Button>: null}
          </div>
        </EditableContext.Provider>
      </div>
    )
  }
}
const MainForm = Form.create()(Main)
export default MainForm;