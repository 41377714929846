let config = {}

// root path
let rootPath = ''
if (!window.location.origin) {
  window.location.origin = window.location.protocol + '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '')
}
rootPath = window.location.origin + '/api/v1'
config['rootPath'] = rootPath

// language
let language = 'zh-CN'
if (localStorage.getItem('language')) {
  language = localStorage.getItem('language')
} else {
  language = (navigator.systemLanguage ? navigator.systemLanguage : navigator.language).substr(0, 2) === 'zh' ? 'zh-CN' : 'en-US' 
}
config['language'] = language

// token
let token = localStorage.getItem('token')
config['token'] = token

const AlertColor = {
  null: '#ebedf0',
  0: '#ebedf0',
  1: '#52c41a',
  2: '#fa8c16',
  3: '#f5222d',
  4: '#595959',
}
export {
  rootPath,
  language,
  token,
  AlertColor,
}
export default config