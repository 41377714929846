import React, { Component } from 'react';
import {
  Row, Col, Checkbox, Form, Input, Radio, Button, Select, Icon, Divider, Switch, Tooltip
} from 'antd';
import withContext from '../../../common/context/withContext'
import Api from '../../../global/api'
import SetSecurityGroup from '../../../components/setSecurityGroup'
import { Cidr, HostRange, Ip } from '../../../common/validator';
import Method from '../../../global/method';
import '../../../style/graph-edit.less'

const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
let hostId = 1
let routeId = 1
class Main extends Component {
  state = {
    bypassEnable: false,
    extraRoutesEnable: false,
    snatEnable: false,
  }
  componentDidMount() {
    hostId = 1
    routeId = 1
    this.props.basic.mode === 0 ? this.initMode0() : this.initMode1()
  }
  componentWillUnmount() {}
  state = {
    isHub: false,
    sg: [],
    show: false,
    spokeList: [],
    selectedArray: [],
    group: {
      edit: true,
      inbound: [],
      outbound: [],
    },
  }
  initMode0 = () => {
    if (Object.keys(this.props.setting.setting).length !== 0) {
      const data = this.props.setting.setting
      this.setState({
        spokeList: this.props.setting.logicLinksData.spokeList,
        isHub: this.props.hub === this.props.setting.id,
      })
      this.props.form.setFieldsValue({
        kind: data.kind,
        lan1: data.local_subnets.addr,
        lan2: data.local_subnets.addr2,
        sg_id: data.sg_id,
        dns_server: data.dns_server,
        selectedArray: this.props.setting.logicLinksData.selectedArray,
      })
    }
    this.sg()
  }
  initMode1 = () => {
    if (Object.keys(this.props.setting.setting).length !== 0) {
      const data = this.props.setting.setting
      const bypass_deploy_enable = data.bypass_deploy ? data.bypass_deploy.enable : false
      const extraRoutesEnable = data.extra_routes ? (data.extra_routes.length > 0) : false
      const snatEnable = data.snat ? data.snat.enable : false
      // let tunnel_sources = data.tunnel_sources ? data.tunnel_sources[0] : ''
      // if (data.local_subnets.addr && !tunnel_sources) {
      //   tunnel_sources = new Method.IpInfo(data.local_subnets.addr).toGetNetIp()
      // }
      this.setState({
        isHub: this.props.hub === this.props.setting.id,
        bypassEnable: bypass_deploy_enable,
        extraRoutesEnable: extraRoutesEnable,
        snatEnable: snatEnable,
        // tunnelSources: tunnel_sources,
        // tunnelSourcesInputShow: false,
      }, () => {
        const bypass_deploy = {}
        const extra_routes = {}
        const snat = {}
        if (bypass_deploy_enable) {
          hostId = data.bypass_deploy.hosts.length - 1
          // bypass_deploy.bypass_deploy_addr = data.bypass_deploy.proxy_addr
          bypass_deploy.bypass_deploy_hosts = data.bypass_deploy.hosts
          bypass_deploy.hosts = data.bypass_deploy.hosts.map((ele, index) => {
            hostId = index + 1
            return index
          })
        }
        if (snatEnable) {
          snat.snat_enable = data.snat.enable
          snat.snat_to_source = data.snat.to_source
        }
        // extra_routes
        if (!data.extra_routes) {
          data.extra_routes = []
        }
        routeId = data.extra_routes.length - 1
        extra_routes.extra_routes_enable = extraRoutesEnable
        extra_routes.extra_routes = data.extra_routes
        extra_routes.routes = data.extra_routes.map((ele, index) => {
          routeId = index + 1
          return index
        })

        this.props.form.setFieldsValue({
          kind: data.kind,
          local_subnet: data.local_subnets.addr,
          // tunnel_sources: tunnel_sources,
          bypass_deploy_enable: bypass_deploy_enable,
          ...bypass_deploy,
          ...extra_routes,
          ...snat,
        }, () => {
          if (data.bypass_deploy && data.bypass_deploy.hosts) {
            data.bypass_deploy.hosts.map((ele, index) => {
              this.props.form.setFieldsValue({
                [`bypass_deploy_hosts[${index}]`]: ele,
              })
            })
          }
          if (data.extra_routes.length > 0) {
            data.extra_routes.map((ele, index) => {
              if (!!ele) {
                this.props.form.setFieldsValue({
                  [`extra_routes[${index}].dst`]: ele.dst,
                  [`extra_routes[${index}].via`]: ele.via,
                })
              }
            })
          }
        })
      })
    }
  }
  sg = () => {
    Api.get.securityGroups(response => {
      this.setState({
        sg: response.security_groups ? response.security_groups : []
      })
    })
  }
  save = () => {
    this.props.form.validateFields((errors, values) => {
      if (!!errors) {
        return;
      }
      let setting = JSON.parse(JSON.stringify(this.props.setting))
      setting.setting.kind = values.kind
      if (this.props.basic.mode === 0) {
        setting.setting.local_subnets.addr = values.lan1
        setting.setting.local_subnets.addr2 = values.lan2
        setting.setting.sg_id = values.sg_id
        setting.setting.dns_server = values.dns_server
        setting.logicLinksData.selectedArray = values.selectedArray
      }
      if (this.props.basic.mode === 1) {
        // const {tunnelSources} = this.state
        setting.setting.local_subnets.addr = values.local_subnet
        setting.setting.bypass_deploy = setting.setting.bypass_deploy || {}
        setting.setting.bypass_deploy.enable = values.bypass_deploy_enable
        // setting.setting.tunnel_sources = [tunnelSources]
        if (values.bypass_deploy_enable) {
          setting.setting.bypass_deploy.hosts = [...values.bypass_deploy_hosts].filter(ele => !!ele)
          // setting.setting.bypass_deploy.proxy_addr = values.bypass_deploy_addr
        } else {
          setting.setting.bypass_deploy = {
            enable: false
          }
        }
        if (values.snat_enable) {
          setting.setting.snat = {
            enable: values.snat_enable,
            to_source: values.snat_to_source,
          }
        } else {
          setting.setting.snat = {
            enable: false,
          }
        }
        setting.setting.extra_routes = [...(values.extra_routes || [])].filter(ele => !!ele)
      }
      let hub =  this.state.isHub ? {hub: this.props.setting.id} : {}
      this.props.setValue({
        setting: setting,
        saveEl: true,
        ...hub
      })
    })
  }
  cancel = () => {
    this.props.setValue({setting: null})
  }
  onChange = (e) => {
    this.setState({
      isHub: !!e.target.value,
    })
  }
  groupSave = () => {
    this.sg()
    this.setState({
      show: false,
    })
  }
  groupCancel = () => {
    this.setState({
      show: false,
    })
  }
  addSecurityGroups = () => {
    const value = this.state.sg.find(ele => {
      return ele.id === '0'
    })
    this.setState({
      show: true,
      group: {
        edit: true,
        inbound: value ? value.inbound_rules : [],
        outbound: value ? value.outbound_rules : [],
      },
    })
  }
  removeHost = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('hosts');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }
    // can use data-binding to set
    form.setFieldsValue({
      hosts: keys.filter(key => key !== k),
    });
  };

  addHost = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('hosts');
    const nextKeys = keys.concat(hostId++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      hosts: nextKeys,
    });
  };
  removeRoute = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('routes');
    if (keys.length === 1) {
      return;
    }
    // We need at least one passenger
    // if (keys.length === 1) {
    //   form.setFieldsValue({
    //     routes: [],
    //   });
    //   return;
    // }
    // can use data-binding to set
    form.setFieldsValue({
      routes: keys.filter(key => key !== k),
    });
  };

  addRoute = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('routes');
    const nextKeys = keys.concat(routeId++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      routes: nextKeys,
    });
  };
  onChangeBypassEnable = (value) => {
    this.setState({
      bypassEnable: value,
    })
  }
  onChangeExtraRoutesEnable = (value) => {
    this.setState({
      extraRoutesEnable: value,
    })
  }
  onChangeSantEnable = (value) => {
    this.setState({
      snatEnable: value,
    })
  }
  checkoutLan = (rule, value, callback) => {
    const lan1 = this.props.form.getFieldValue('lan1')
    const lan2 = this.props.form.getFieldValue('lan2')
    Cidr(rule, value, response => {
      let isError = response instanceof Error
      if (isError) {
        callback(response)
      }
    })
    if (!lan1 || !lan2) {
      callback()
    }
    if (rule.field === 'lan1') {
      if (lan2 !== '') {
        this.props.form.validateFields(['lan2'], { force: true });
      }
    } else {
      if (lan1 === lan2) {
        callback(new Error('LAN address 1 and address2 should be different.'))
      }
      const lan1Net = new Method.IpInfo(lan1).toGetNet()
      const lan2Net = new Method.IpInfo(lan2).toGetNet()
      if (lan1Net !== lan2Net) {
        callback(new Error('LAN address 1 and address2 are not in same subnet.'))
      }
    }
    callback()
  }
  // setTunnelSource = (value) => {
  //   const ip = new Method.IpInfo(value).toGetNetIp()
  //   this.props.form.setFieldsValue({
  //     tunnel_sources: ip,
  //   }, () => {
  //     this.setState({
  //       tunnelSources: ip,
  //     })
  //   })
  // }
  render () {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const RadioGroup = Radio.Group
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12, offset: 2},
      },
      labelAlign: 'left',
    }
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
      },
    }
    const formItemLayoutWithOutLabel1 = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 0 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      }
    }
    const lan1 = {
      rules: [
        {required: true, message: 'Please enter a address.'},
        {validator: this.checkoutLan}
      ],
    }
    const lan2 = {
      rules: [
        {required: true, message: 'Please enter a address.'},
        {validator: this.checkoutLan}
      ],
    }
    const dns = {
      rules: [
        {required: true, message: 'Please enter a DNS.'},
        {validator: Ip}
      ],
    }
    const subnetConfig = {
      rules: [
        {required: true, message: 'Please enter a local subnet.'},
        {validator: (rule, value, callback) => {
          Cidr(rule, value, response => {
            if (response) {
              callback(response)
            } else {
              // this.setTunnelSource(value)
              callback()
            }
          })
        }}
      ],
    }
    const kindConfig = {
      initialValue: 0,
      rules: [{required: true, message: 'Please select a type.'}]
    }
    const sgConfig = {
      initialValue: '',
      rules: [{required: true, message: 'Please select a security group.'}]
    }
    // const tunnelSourcesConfig = {
    //   rules: [
    //     {validator: (rule, value, callback) => {
    //       let error = true
    //       Cidr(rule, value, response => {
    //         if (!response) {
    //           error = false
    //         }
    //       })
    //       Ip(rule, value, response => {
    //          if (!response) {
    //           error = false
    //         }           
    //       })
    //       if (error || !value) {
    //         callback(new Error('Must be in CIDR format or IP format'))
    //       } else {
    //         this.setState({
    //           tunnelSources: value,
    //         })
    //         callback()
    //       }
    //     }}
    //   ]
    // }
    const Img = <img style={{width: 20, marginRight: 5}} src={require('../../../assets/images/spoke-blue.svg')} alt=""/> 
    let formItems = null
    if (this.state.bypassEnable) {
      getFieldDecorator('hosts', { initialValue: [0] });
      const hosts = getFieldValue('hosts');
      formItems = hosts.map((k, index) => (
        <Form.Item
          {...formItemLayoutWithOutLabel}
          // label={index === 0 ? 'Hosts' : ''}
          key={k}
        >
          {getFieldDecorator(`bypass_deploy_hosts[${k}]`, {
            validateTrigger: ['onBlur'],
            initialValue: '',
            rules: [
              {
                required: true,
                message: "Please input host.",
              },
              {
                validator: HostRange,
              }
            ],
          })(<Input placeholder="eg: 1.0.0.1 or 1.0.0.1-1.0.0.2"/>)}
          {hosts.length > 1 ? (
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              onClick={() => this.removeHost(k)}
            />
          ) : null}
        </Form.Item>
      ))
    }
    let formItems1 = null
    if (this.state.extraRoutesEnable) {
      getFieldDecorator('routes', { initialValue: [0] });
      const routes = getFieldValue('routes');
      formItems1 = routes.map((k, index) => (
        <Form.Item 
          {...formItemLayoutWithOutLabel1}
          key={index}
          style={{marginBottom: 0,}}
        >
          <Form.Item
            style={{ display: 'inline-block', width: 'calc(50%)', padding: 0}}
          >
            {getFieldDecorator(`extra_routes[${k}].dst`, {
              validateTrigger: ['onBlur'],
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: "Please input host.",
                },
                {
                  validator: Cidr,
                }
              ],
            })(<Input placeholder="eg: 1.0.0.0/1 (CIDR format)" />)}
          </Form.Item>
          <span style={{ display: 'inline-block', width: '40px', textAlign: 'center' }}>via</span>
          <Form.Item
            style={{ display: 'inline-block', width: 'calc(50% - 40px)', padding: 0}}
          >
            {getFieldDecorator(`extra_routes[${k}].via`, {
              validateTrigger: ['onBlur'],
              initialValue: '',
              rules: [
                {
                  validator: Ip,
                }
              ],
            })(<Input placeholder="eg: 1.0.0.1"/>)}
          </Form.Item>
          {
            routes.length > 1 ?
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              onClick={() => this.removeRoute(k)}
            />
            : null
          }
        </Form.Item>
      ))
    }
    return(
      <div>
        {this.state.show ? <SetSecurityGroup save={this.groupSave} cancel={this.groupCancel} data={this.state.group}/> : null}
        <Form {...formItemLayout}>
          <Form.Item
            label="Type"
            style={{marginBottom: 12, padding: '12px 12px 0 12px'}}
          >
            {getFieldDecorator('kind', kindConfig)(
              <RadioGroup onChange={this.onChange} disabled={!!this.props.hub&&this.props.hub !== this.props.setting.id}>
                <Radio value={0}>Spoke</Radio>
                <Radio value={1}>Hub</Radio>
              </RadioGroup>
            )}
          </Form.Item>
          {this.props.basic.mode === 0 ? 
            <div className="item-cell">
              <Form.Item
                label={
                  <span>
                    LAN Address 1&nbsp;
                    <Tooltip title="Address of local network gateway.">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('lan1', lan1)(
                  <Input placeholder="eg: 1.0.0.0/1 (CIDR format)"/>
                )}
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    LAN Address 2&nbsp;
                    <Tooltip title="Address of internet virtual interface.">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('lan2', lan2)(
                  <Input placeholder="eg: 1.0.0.1/1 (CIDR format)"/>
                )}
              </Form.Item>
              <Form.Item
                label="DNS Address"
              >
                {getFieldDecorator('dns_server', dns)(
                  <Input />
                )}
              </Form.Item>
              <Form.Item
                label="Security Group" 
              >
                {getFieldDecorator('sg_id', sgConfig)(
                  <Select
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ padding: '8px', cursor: 'pointer' }} onClick={this.addSecurityGroups}>
                          <Icon type="plus" /> Add security group
                        </div>
                      </div>
                    )}
                  >
                    {this.state.sg.length !== 0 ? this.state.sg.map((ele, index) => {
                      return <Option key={index} value={ele.id} title={ele.name}>{ele.name} ({ele.id})</Option>
                    }):null}
                  </Select>
                )}
              </Form.Item>
              {this.state.isHub
                ? null
                : <Form.Item
                  label="Logic Links" 
                >
                  {getFieldDecorator('selectedArray',{initialValue:[]})(
                    <CheckboxGroup
                      style={{width:'100%',paddingTop:'10px', textAlign: 'left',}} 
                    >
                      <Row>
                        {this.state.spokeList.map((ele, index) => {
                          if (ele.id === this.props.setting.id) {
                            return null
                          } else {
                            return <Col span={22} key={index}>
                              <Checkbox value={ele.id}>
                                {Img}{ele.name}
                              </Checkbox>
                            </Col>
                          }
                        })}
                      </Row>
                    </CheckboxGroup>
                    // <Select
                    //   mode="multiple"
                    //   allowClear
                    // >
                    //   {this.state.spokeList.map((ele, index) => {
                    //     if (ele.id === this.props.setting.id) {
                    //       return null
                    //     } else {
                    //       return <Option key={index} value={ele.id} title={ele.name}>{ele.name}</Option>
                    //     }
                    //   })}
                    // </Select>
                  )}
                </Form.Item>
              }
            </div>
            : <div>
                <div className="item-cell">
                  <Form.Item
                    label="LAN Address"
                  >
                    {getFieldDecorator('local_subnet', subnetConfig)(
                      <Input placeholder="eg: 1.0.0.1/1 (CIDR format)"/>
                    )}
                  </Form.Item>
                  {/* <Form.Item
                    label="Tunnel Source"
                    className="tunnel-sources"
                  >
                    <div className="tunnel-sources-string" style={{display: this.state.tunnelSourcesInputShow ? 'none' : null}}>
                      {this.state.tunnelSources}
                    </div>
                    <div className="tunnel-sources-input" style={{display: this.state.tunnelSourcesInputShow ? 'block' : null}}>
                      {getFieldDecorator('tunnel_sources', tunnelSourcesConfig)(
                        <Input 
                          placeholder="CIDR format or IP format" 
                          onFocus={()=>this.setState({tunnelSourcesInputShow: true})}
                          onBlur={()=>{
                            const error = this.props.form.getFieldError('tunnel_sources')
                            if (error) {
                              this.props.form.setFieldsValue({'tunnel_sources': this.state.tunnelSources})
                            }
                            this.setState({tunnelSourcesInputShow: false})}}
                        />
                      )}
                    </div>
                  </Form.Item> */}
                </div>
                <div className="item-cell-dark">
                  <Form.Item
                    label="Extra Routes"
                  >
                    {getFieldDecorator('extra_routes_enable', {valuePropName: 'checked'})(
                      <Switch onChange={value => this.onChangeExtraRoutesEnable(value)}/>
                    )}
                  </Form.Item>
                  {this.state.extraRoutesEnable ?
                    <div>
                      <Form.Item
                        label="Destination"
                      >
                        <div style={{width:'100%',textAlign:'left',paddingLeft:'40px'}}>
                          Next Hop:
                        </div>
                      </Form.Item>
                      {formItems1}
                      <div style={{textAlign:'right', width:'100%', paddingBottom:'12px',}}>
                        <Button type="dashed" style={{width: '100%'}} onClick={this.addRoute}>
                          <Icon type="plus" /> Add Extra Route
                        </Button>
                      </div>
                    </div>
                  :null}
                </div>
              {this.state.isHub ? null:
                <div className="item-cell-dark">
                  <Form.Item
                    label={`SNAT`}
                  >
                    {getFieldDecorator('snat_enable', {valuePropName: 'checked'})(
                      <Switch onChange={value => this.onChangeSantEnable(value)}/>
                    )}
                  </Form.Item>
                  {this.state.snatEnable ? <div>
                    <Form.Item
                      label="To-Source"
                    >
                      {getFieldDecorator('snat_to_source', {
                        validateTrigger: ['onBlur'],
                        initialValue: '',
                        rules: [
                          {
                            required: true,
                            message: "Please enter a address.",
                          },
                          {
                            validator: Ip,
                          }
                        ],
                      })(
                        <Input placeholder="eg: 1.0.0.1"/>
                      )}
                    </Form.Item>
                  </div> : null}
                </div>
              }
              <div className="item-cell-dark">
                <Form.Item
                  label={`Out-of-Path Deployments`}
                >
                  {getFieldDecorator('bypass_deploy_enable', {valuePropName: 'checked'})(
                    <Switch onChange={value => this.onChangeBypassEnable(value)}/>
                  )}
                </Form.Item>
                {this.state.bypassEnable ? <div>
                  {formItems}
                  <div style={{textAlign:'right', width:'100%', paddingBottom:'12px',}}>
                    <Button type="dashed" style={{ width: '100%' }} onClick={this.addHost}>
                      <Icon type="plus" /> Add Host
                    </Button>
                  </div>
                </div> : null}
              </div>
            </div>
          }
          <div
            style={{width:'100%',textAlign: 'center',}}
          >
            <Button 
              onClick={this.save}
              type="primary" htmlType="submit" style={{marginRight: 20}}>
              Save
            </Button>
            <Button 
              onClick={this.cancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}

Main = Form.create()(Main);
export default withContext(Main)