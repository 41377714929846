import React, { Component } from 'react';
import echarts from 'echarts'
import Api from '../../../global/systemApi'
// import walden from '../../../style/walden.json'
import Lang from '../../../global/language'

class Chart extends Component {
  componentDidMount(){
    if (window.attachEvent) {
      window.attachEvent('onresize', this.onWindowResize)
    } else {
      window.addEventListener('resize', this.onWindowResize)
    }
    this.drawChart1()
    this.drawChart2()
    this.getData(1)
  }
  componentWillUnmount() {
    if (window.detachEvent) {
      window.detachEvent('onresize', this.onWindowResize)
    } else {
      window.removeEventListener('resize', this.onWindowResize)
    }
    clearInterval(this.intervalA)
  }
  onWindowResize = () => {
    var myChart1 = this.initChart1()
    myChart1.resize()
    var myChart2 = this.initChart2()
    myChart2.resize()
  }
  state = {
    cpu: [],
    mem: [],
  }
  initChart1 = () => {
    let main = document.getElementById('chart1')
    let existInstance = echarts.getInstanceByDom(main)
    if (existInstance !== undefined) {
      return existInstance
    } else {
      let myChart = echarts.init(main, 'walden')
      return myChart
    }
  }
  initChart2 = () => {
    let main = document.getElementById('chart2')
    let existInstance = echarts.getInstanceByDom(main)
    if (existInstance !== undefined) {
      return existInstance
    } else {
      let myChart = echarts.init(main, 'walden')
      return myChart
    }
  }
  getData = (mode) => {
    Api.state(response=>{
      this.props.callbackParent(response.uptime)
      var cpu = this.state.cpu
      var mem = this.state.mem
      if (mode === 1) {
        const length = 61
        cpu = Array.from({length}, (v,k)=>{
          return null
        })
        mem = Array.from({length}, (v,k)=>{
          return null
        })
        console.log(cpu, window.cpu);
      }
      cpu.push(response.cpu_usage)
      mem.push(response.mem_usage)
      if (cpu.length > 61) {
        cpu.splice(0,1)
      }
      if (mem.length > 61) {
        mem.splice(0,1)
      }
      this.setState({
        cpu: cpu,
        mem: mem,
      })
      cpu = null
      mem = null
    })
    if (mode === 1) {
      clearInterval(this.intervalA)
      this.interval()
    } else {
      this.drawChartInterval()
    }
  }
  drawChart1 = () => {
    var myChart = this.initChart1()
    myChart.hideLoading()
    myChart.setOption({
      legend: {
        data:['CPU']
      },
      xAxis: {
        axisLabel: {
          interval: 4,
        },
        boundaryGap: false,
        min: 0,
        max: 60,
        type: 'category',
        data: this.state.cpu.map((item, index) => {
          return index
        })
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: 100,
      },
      series: [{
          name: 'CPU',
          data: this.state.cpu,
          type: 'line'
      }]
    })
  }
  drawChart2 = () => {
    var myChart = this.initChart2()
    myChart.hideLoading()
    myChart.setOption({
      legend: {
        data:[Lang.system.l254]
      },
      xAxis: {
        axisLabel: {
          interval: 4,
        },
        boundaryGap: false,
        type: 'category',
        data: this.state.mem.map((value, index) => {
          return index
        })
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: 100,
      },
      series: [{
          name: Lang.system.l254,
          data: this.state.mem,
          type: 'line'
      }]
    })
  }
  interval = () => {
    if (!this.intervalA) {
      this.intervalA = setInterval(()=>{
        this.getData()
        clearInterval(this.intervalA)
        this.intervalA = null
        this.interval()
      }, 1000)
    }
  }
  drawChartInterval = () => {
    var myChart1 = this.initChart1()
    var myChart2 = this.initChart2()
    myChart1.setOption({
      xAxis: [{
        data: this.state.cpu.map((value, index) => {
          let i = this.state.cpu.length - index - 1
          return i
        })
      }],
      series: [{
        animation: false,
        name: 'CPU',
        data: this.state.cpu,
        type: 'line'
      }]
    })
    myChart2.setOption({
      xAxis: [{
        data: this.state.mem.map((value, index) => {
          let i = this.state.cpu.length - index - 1
          return i
        })
      }],
      series: [{
        animation: false,
        name: Lang.system.l254,
        data: this.state.mem,
        type: 'line'
      }]
    })
  }
  render () {
    return (
      <div>
        <div style={{width:"100%",height: "300px"}}>
          <div id="chart1" style={{zIndex:2,width:"100%",height: "100%"}}>
          </div>
          <div style={{position:'absolute',zIndex:3,right:80,top:40}}>{this.state.cpu[60]!==undefined?(this.state.cpu[60]+'%'):''}</div>
          <div style={{position:'absolute',marginLeft:'80px',marginTop:'20px'}}>{this.state.cpu.length > 0?'': Lang.prompt.l111}</div>
        </div>
        <div style={{width:"100%",height: "300px"}}>
          <div id="chart2" style={{width:"100%",height: "100%"}}>
          </div>
          <div style={{position:'absolute',zIndex:3,right:80,top:340}}>{this.state.mem[60]!==undefined?(this.state.mem[60]+'%'):''}</div>
          <div style={{position:'absolute',marginLeft:'80px',marginTop:'20px'}}>{this.state.mem.length > 0?'': Lang.prompt.l111}</div>
        </div>
      </div>
    )
  }
}
export default Chart
